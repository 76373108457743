import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Image } from "semantic-ui-react";
import {
  getAuthState,
  getAuthClaims,
  getAuthOptions,
  FormBuilder,
  Form,
  ErrorMessage,
} from "@redriver/cinnamon";
import { SystemRoutes } from "constants/routes";
import { requestLogin } from "./actions";
import { EmailVerificationResender } from "../EmailVerification";

const loginErrors = {
  915004: "Incorrect email address or password",
  915005: "Account is inactive",
};

const Login = ({ loggedIn, emailVerified, useEmailVerification }) => {
  if (loggedIn) {
    if (emailVerified || !useEmailVerification) {
      return <p>You have been successfully signed in</p>;
    }

    return (
      <div>
        <p>Your email address has not been verified</p>
        <EmailVerificationResender />
      </div>
    );
  }

  return (
    <FormBuilder
      submitAction={requestLogin}
      renderSubmitting={false}
      renderError={false}
      renderForm={(
        formProps,
        { submitting, slowSubmitting, error },
        events
      ) => (
        <Form {...formProps} className="public-form">
          <span className="welcome-text">Welcome back</span>
          <h1 className="big-header">Log in</h1>
          <Form.Email
            field="email"
            placeholder="Email"
            disabled={submitting && slowSubmitting}
            autoFocus
            required
            fluid
            icon="at"
          />
          <Form.Password
            field="password"
            placeholder="Password"
            disabled={submitting && slowSubmitting}
            required
            fluid
            toggleVisibility
          />

          <Button
            className="confirm-btn"
            onClick={events.onSubmit}
            disabled={submitting}
            loading={submitting && slowSubmitting}
            fluid
          >
            Log In
          </Button>
          <span>
            Forgot Password?{" "}
            <Link to={SystemRoutes.ForgottenPassword}>Reset it</Link>
          </span>

          <ErrorMessage
            header="Sign In Error"
            error={error}
            overrideMessages={loginErrors}
            omitCodes
          />
        </Form>
      )}
    />
  );
};

const mapStateToProps = (state) => {
  const { loggedIn } = getAuthState(state);
  const { emailVerified } = getAuthClaims(state);
  const { useEmailVerification } = getAuthOptions();
  return {
    loggedIn,
    emailVerified,
    useEmailVerification,
  };
};

export default connect(mapStateToProps)(Login);
