import React from "react";
import { Table } from "@redriver/cinnamon";
import { useHistory } from "react-router-dom";
import { SiteRoutes } from "constants/routes";
import ActionsModal from "components/modals/ActionsModal";
import { updateSiteStatus, deleteSite } from "./actions";
import { GroupStatus } from "constants/enums";
import classNames from "classnames";
import EditSite from "./EditSite";
import { TableActionMenu } from "components/misc";

const SiteActions = ({
  site,
  onRefresh,
  hideView = false,
  className,
  itemIndex,
  visibleRowCount,
  ...props
}) => {
  const history = useHistory();

  return (
    <TableActionMenu
      className={className}
      itemIndex={itemIndex}
      visibleRowCount={visibleRowCount}
      {...props}
    >
      {!hideView && (
        <Table.MenuItem onClick={() => history.push(SiteRoutes.Site(site.id))}>
          Go To Site
        </Table.MenuItem>
      )}

      <ActionsModal
        as={Table.MenuItem}
        submitAction={updateSiteStatus}
        id={site.id}
        name={site.name}
        onRefresh={onRefresh}
        actionName={site.isActive ? "deactivate" : "activate"}
        header={site.isActive ? "Deactivate Site" : "Activate Site"}
        submitParams={{
          status: site.isActive ? GroupStatus.Inactive : GroupStatus.Active,
          selected: [site.id],
        }}
      >
        {site.isActive ? "Deactivate Site" : "Activate Site"}
      </ActionsModal>

      <EditSite as={Table.MenuItem} onUpdated={onRefresh} site={site}>
        Edit Site Name
      </EditSite>

      <ActionsModal
        as={Table.MenuItem}
        submitAction={deleteSite}
        id={site.id}
        name={site.name}
        actionName="delete"
        onRefresh={() => onRefresh({ isDeleted: true })}
        header="Delete Site"
        submitParams={{
          selected: [site.id],
        }}
      >
        Delete
      </ActionsModal>
    </TableActionMenu>
  );
};

export default SiteActions;
