import React from "react";
import Colours from "constants/colours";

const GroupsIcon = ({ white = false }) => {
  const colour = white ? Colours.White : Colours.PrimaryBlue;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="11"
      viewBox="0 0 11 11"
    >
      <g id="Connect_1_" transform="translate(0 -0.5)">
        <g id="Group_29" data-name="Group 29" transform="translate(0 0.5)">
          <g id="Group_26" data-name="Group 26" transform="translate(3.889)">
            <path
              id="Path_1260"
              data-name="Path 1260"
              d="M182.611,3.729a1.614,1.614,0,1,1,1.611-1.614A1.615,1.615,0,0,1,182.611,3.729Z"
              transform="translate(-181 -0.5)"
              fill={colour}
            />
          </g>
          <g id="Group_27" data-name="Group 27" transform="translate(0 6.801)">
            <path
              id="Path_1261"
              data-name="Path 1261"
              d="M.215,318.878a1.612,1.612,0,1,1,2.2.591,1.618,1.618,0,0,1-2.2-.591Z"
              transform="translate(0 -316.455)"
              fill={colour}
            />
          </g>
          <g
            id="Group_28"
            data-name="Group 28"
            transform="translate(7.777 6.801)"
          >
            <path
              id="Path_1262"
              data-name="Path 1262"
              d="M362.793,319.468a1.614,1.614,0,1,1,2.2-.591A1.616,1.616,0,0,1,362.793,319.468Z"
              transform="translate(-361.987 -316.455)"
              fill={colour}
            />
          </g>
        </g>
        <g
          id="Group_30"
          data-name="Group 30"
          transform="translate(0.673 2.604)"
        >
          <path
            id="Path_1263"
            data-name="Path 1263"
            d="M32,102.041l-.643-.035a4.863,4.863,0,0,1,2.178-3.783l.354.54A4.217,4.217,0,0,0,32,102.041Z"
            transform="translate(-31.352 -98.223)"
            fill={colour}
          />
        </g>
        <g
          id="Group_31"
          data-name="Group 31"
          transform="translate(7.794 2.604)"
        >
          <path
            id="Path_1264"
            data-name="Path 1264"
            d="M364.676,102.042a4.217,4.217,0,0,0-1.889-3.278l.354-.54a4.863,4.863,0,0,1,2.178,3.783Z"
            transform="translate(-362.787 -98.224)"
            fill={colour}
          />
        </g>
        <g
          id="Group_32"
          data-name="Group 32"
          transform="translate(3.321 10.405)"
        >
          <path
            id="Path_1265"
            data-name="Path 1265"
            d="M156.753,461.721a4.779,4.779,0,0,1-2.179-.519l.291-.576a4.208,4.208,0,0,0,3.777,0l.291.576A4.779,4.779,0,0,1,156.753,461.721Z"
            transform="translate(-154.574 -460.626)"
            fill={colour}
          />
        </g>
      </g>
    </svg>
  );
};

export default GroupsIcon;
