import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "LOOKUP_DATA";

const ActionTypes = {
  GetGroups: `${NAMESPACE}/GET_GROUPS`,
};

// userId is optional
export const getGroups = ({ search, userId }) =>
  apiGet(ActionTypes.GetGroups, "groups/lookup", { search, userId });
