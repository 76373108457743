import React from "react";
import {
  ListBuilder,
  Table,
  Format,
  ActionBar,
  ActionMenu,
} from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { getGroups, updateGroupStatus, deleteGroup } from "./actions";
import { GroupStatus, Sort } from "constants/enums";
import { SortHeader, RunOnChange } from "components/misc";
import { MaplesLabel } from "components/containers";
import { TablePagination } from "components/pagination";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import GroupActions from "./GroupActions";
import FilterGroups from "./FilterGroups";
import AddGroup from "./AddGroup";
import BulkActionsModal from "components/modals/BulkActionsModal";
import { useHistory } from "react-router-dom";
import { GroupRoutes } from "constants/routes";

const ListGroups = () => {
  const history = useHistory();
  return (
    <ListBuilder
      withTable
      loadAction={getGroups}
      initialFilters={{ sortName: "name", sortDirection: Sort.Asc }}
      initialPagination={{ pageSize: 25, pageNumber: 1 }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      renderList={(tableProps, state, events) => {
        const totalSelected = state.selection.allSelected
          ? state.total - (state.selection.deselected?.length || 0)
          : state.selection.selected?.length || 0;
        const visibleRowCount = tableProps?.data?.length;
        return (
          <div>
            <RunOnChange
              value={state.total}
              func={() => events.onChangeSelection({})}
            />
            <ActionBar>
              <ActionBar.Item>
                <FilterGroups
                  value={state.filters}
                  onChange={events.onChangeFilters}
                />
              </ActionBar.Item>
              <ActionBar.Item fluid align="right">
                <ActionMenu
                  openOnHover={false}
                  disabled={totalSelected == 0}
                  trigger={<Button compact> Actions ({totalSelected}) </Button>}
                >
                  <BulkActionsModal
                    actionName="activate"
                    header="Activate"
                    submitParams={{
                      status: GroupStatus.Active,
                      selected: state.selection.selected,
                      deselected: state.selection.deselected,
                      allSelected: state.selection.allSelected,
                      search: state.filters.search,
                    }}
                    onRefresh={events.onRefresh}
                    submitAction={updateGroupStatus}
                    totalSelected={totalSelected}
                    as={ActionMenu.Item}
                    entityName="group"
                  >
                    Activate
                  </BulkActionsModal>
                  <BulkActionsModal
                    actionName="deactivate"
                    header="Deactivate"
                    submitParams={{
                      status: GroupStatus.Inactive,
                      selected: state.selection.selected,
                      deselected: state.selection.deselected,
                      allSelected: state.selection.allSelected,
                      search: state.filters.search,
                    }}
                    onRefresh={events.onRefresh}
                    submitAction={updateGroupStatus}
                    totalSelected={totalSelected}
                    as={ActionMenu.Item}
                    entityName="group"
                  >
                    Deactivate
                  </BulkActionsModal>
                  <BulkActionsModal
                    actionName="delete"
                    header="Delete"
                    submitParams={{
                      selected: state.selection.selected,
                      deselected: state.selection.deselected,
                      allSelected: state.selection.allSelected,
                      search: state.filters.search,
                    }}
                    onRefresh={events.onRefresh}
                    submitAction={deleteGroup}
                    totalSelected={totalSelected}
                    as={ActionMenu.Item}
                    entityName="group"
                  >
                    Delete
                  </BulkActionsModal>
                </ActionMenu>

                <PermissionCheck action={Actions.Create} target={Targets.Group}>
                  <AddGroup
                    compact
                    content="Add Group"
                    onAdded={events.onRefresh}
                  />
                </PermissionCheck>
              </ActionBar.Item>
            </ActionBar>
            <Table
              {...tableProps}
              dataKey="id"
              emptyMessage="No groups found"
              selectable
              onRowClick={(group) => history.push(GroupRoutes.Group(group.id))}
            >
              <Table.SelectableColumn
                value={state.selection}
                onChange={events.onChangeSelection}
                totalItems={state.total}
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Name"
                    field="name"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                field="name"
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Status"
                    field="status"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                render={(group) =>
                  group.isActive ? (
                    <MaplesLabel type="primary">Active</MaplesLabel>
                  ) : (
                    <MaplesLabel type="default">Inactive</MaplesLabel>
                  )
                }
              />

              <Table.Column
                header={
                  <SortHeader
                    title="Sites"
                    field="sites"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                field="sitesCount"
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Users"
                    field="users"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                field="userCount"
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Created"
                    field="created"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                render={(group) => (
                  <Format.DateTime
                    value={group.createdUtc}
                    format="DD MMM YYYY HH:mm"
                  />
                )}
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Modified"
                    field="modified"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                render={(group) =>
                  group.modifiedUtc ? (
                    <Format.DateTime
                      value={group.modifiedUtc}
                      format="DD MMM YYYY HH:mm"
                    />
                  ) : (
                    "-"
                  )
                }
              />
              <Table.Column
                width="5%"
                align="right"
                render={(group, colState) => (
                  <GroupActions
                    group={group}
                    onRefresh={events.onRefresh}
                    itemIndex={colState.itemIndex}
                    visibleRowCount={visibleRowCount}
                  />
                )}
              />
            </Table>
            <TablePagination
              value={state.pagination}
              onChange={events.onChangePagination}
              totalItems={state.total}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>
        );
      }}
    />
  );
};

export default ListGroups;
