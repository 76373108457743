export const AppRoutes = {
  Root: "/",
  Users: "/users",
  Roles: "/roles",
  Sites: "/sites",
  Groups: "/groups",
  Bin: "/bin",
  Workspace: "/workspace",
  Styles: "/styles",
  SharedFile: (token = ":token?") => `/shared/${token}`,
  FileRedirect: (id = ":id") => `/workspace-link/file/${id}`,
  DirectoryRedirect: (id = ":id") => `/workspace-link/directory/${id}`,
  SiteRedirect: (id = ":id") => `/workspace-link/site/${id}`,
  Organisations: "/organisations",
};

export const SystemRoutes = {
  Login: "/login",
  Logout: "/logout",
  SetupUser: "/setup-user/:token?",
  VerifyEmail: "/verify-email/:token?",
  ForgottenPassword: "/forgotten-password",
  ResetPassword: "/reset-password/:token?",
  Account: "/account",
  ChangePassword: "/change-password",
};

export const UserRoutes = {
  User: (id = ":id") => `${AppRoutes.Users}/${id}`,
};

export const SiteRoutes = {
  Site: (id = ":id") => `${AppRoutes.Sites}/${id}`,
};

export const GroupRoutes = {
  Group: (id = ":id") => `${AppRoutes.Groups}/${id}`,
};

export const WorkspaceRoutes = {
  WorkspaceItem: (id = ":id") => `${AppRoutes.Workspace}/${id}`,
};
