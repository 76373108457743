import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { resendVerification } from "./actions";

const ResendVerification = ({ as: As = Button, user, onResend, ...props }) => (
  <ModalBuilder
    submitAction={resendVerification}
    submitParams={user.id}
    onSubmitted={onResend}
    renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
    renderModal={(modalProps) => (
      <Modal.Confirmation {...modalProps} header="Resend Verification">
        <p>
          Are you sure you wish to resend a verification email to{" "}
          {user.firstName} {user.lastName}?
        </p>
        <p>
          This will be sent to: <strong>{user.email}</strong>
        </p>
      </Modal.Confirmation>
    )}
  />
);

export default ResendVerification;
