import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal, Form, useFormState } from "@redriver/cinnamon";
import { getGroup, updateGroup } from "./actions";
import { checkValidGroupName } from "./actions";

const EditGroup = ({ group, as: As = Button, onUpdated, ...props }) => {
  const dispatch = useDispatch();
  const formState = useFormState();

  const [checkingName, setCheckingName] = useState(false);
  const [isValidName, setIsValidName] = useState(true);
  const [formValid, setFormValid] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const setNameIsValidFromResponse = useCallback(
    (response) => {
      setIsValidName(
        !!response &&
          (!formState.errors.name || formState.errors.name.length == 0)
      );
    },
    [formState.errors]
  );

  return (
    <ModalBuilder
      withForm
      loadAction={getGroup}
      loadParams={group.id}
      submitAction={updateGroup}
      submitParams={group.id}
      onSubmitted={onUpdated}
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps) => (
        <Modal
          {...modalProps}
          header="Edit Group Name"
          submitLabel="Save"
          size="tiny"
        >
          <Form
            {...formProps}
            onValidated={(isValid) => setFormValid(isValid)}
            showErrors={showErrors}
          >
            {(fields, errors) => (
              <React.Fragment>
                <Form.Input
                  label="Name"
                  field="name"
                  fluid
                  required
                  showErrors={showErrors || (!isValidName && !!fields.name)}
                  customValidator={() => {
                    if (
                      formProps.value.name &&
                      formProps.value.name != group.name
                    ) {
                      setCheckingName(true);
                      dispatch(
                        checkValidGroupName(formProps.value.name, group.id)
                      )
                        .then((result) => {
                          if (result.success && result.response != null) {
                            setNameIsValidFromResponse(result.response);
                          }
                        })
                        .finally(() => setCheckingName(false));
                    }
                  }}
                  customErrors={
                    !checkingName && !isValidName && !!fields.name
                      ? ["Duplicate group name, please choose another"]
                      : errors.name
                  }
                />
              </React.Fragment>
            )}
          </Form>
        </Modal>
      )}
    />
  );
};

export default EditGroup;
