import React from "react";
import Dropzone from "react-dropzone";
import classNames from "classnames";
import UploadIcon from "assets/icons/upload-folder.svg";

const UploadTarget = ({ children, className, onDrop, canUpload }) => {
  return canUpload ? (
    <Dropzone
      onDrop={(files) => {
        if (onDrop) onDrop(files);
      }}
    >
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
      }) => {
        return (
          <div
            {...getRootProps({
              className: classNames(
                "dropzone-target",
                {
                  active: isDragActive,
                  accept: isDragAccept,
                  reject: isDragReject,
                },
                className
              ),
            })}
          >
            {children}
            <div className="overlay">
              <div className="overlay-content">
                <img src={UploadIcon} />
                <p>Drag &amp; Drop Your Files Here</p>
              </div>
            </div>
          </div>
        );
      }}
    </Dropzone>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
};

export default UploadTarget;
