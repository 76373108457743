import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { AppRoutes, SiteRoutes } from "constants/routes";
import SitesListPage from "./SitesListPage";
import SiteDetailsPage from "./SiteDetailsPage";

const SitesRouter = () => (
  <Switch>
    <Route exact path={AppRoutes.Sites} component={SitesListPage} />
    <Route path={SiteRoutes.Site()} component={SiteDetailsPage} />
    <Route render={() => <Redirect to={AppRoutes.Sites} />} />
  </Switch>
);

export default SitesRouter;
