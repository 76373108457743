import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { sendPasswordReset } from "./actions";

const SendPasswordReset = ({
  as: As = Button,
  user,
  submitParams,
  onSend,
  ...props
}) => (
  <ModalBuilder
    submitAction={sendPasswordReset}
    submitParams={{
      ...submitParams,
    }}
    onSubmitted={onSend}
    renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
    renderModal={(modalProps) => (
      <Modal.Confirmation {...modalProps} header="Send Password Reset">
        <p>
          Are you sure you wish to send a password reset email to{" "}
          {user.firstName} {user.lastName}?
        </p>
        <p>
          This will be sent to: <strong>{user.email}</strong>
        </p>
      </Modal.Confirmation>
    )}
  />
);

export default SendPasswordReset;
