import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { AppRoutes, UserRoutes } from "constants/routes";
import UserListPage from "./UserListPage";
import UserDetailsPage from "./UserDetailsPage";

const UserRouter = () => (
  <Switch>
    <Route exact path={AppRoutes.Users} component={UserListPage} />
    <Route exact path={UserRoutes.User()} component={UserDetailsPage} />
    <Route render={() => <Redirect to={AppRoutes.Users} />} />
  </Switch>
);

export default UserRouter;
