import React from "react";
import filesize from "filesize";
import classNames from "classnames";

// uses https://github.com/avoidwork/filesize.js for formatting display
const DisplayDataSize = ({ value, className, ...props }) => {
  const displayValue = filesize(value || 0, { round: 0 });
  return (
    <span className={classNames("data-size", className)} {...props}>
      {displayValue}
    </span>
  );
};

export default DisplayDataSize;
