import React, { useState } from "react";
import { FormBuilder, Form, ModalBuilder, Modal } from "@redriver/cinnamon";
import { updateNotificationSetting } from "./actions";
import { Popup } from "semantic-ui-react";
import BellInactiveIcon from "assets/icons/bell-inactive.svg";
import BellActiveIcon from "assets/icons/bell-active.svg";
import {
  NotificationFrequency,
  NotificationFrequencyLabels,
} from "constants/enums";
import classNames from "classnames";

const frequencyOptions = Object.values(NotificationFrequency).map((v) => ({
  text: NotificationFrequencyLabels[v],
  value: v,
}));

const NotificationLevelPicker = ({ item, onRefresh, hidden }) => {
  const [formData, setFormData] = useState({
    frequency: NotificationFrequency.Never,
  });
  const [open, setOpen] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);

  return (
    <React.Fragment>
      <Popup
        trigger={
          <img
            src={
              item.notificationFrequency == NotificationFrequency.Never ||
              !item.notificationFrequency
                ? BellInactiveIcon
                : BellActiveIcon
            }
            className={classNames("pointer", hidden ? "hidden" : null)}
          />
        }
        flowing
        basic
        on={[]}
        onClick={() => setOpen(true)}
        onOpen={() => {
          setFormData({
            frequency:
              item.notificationFrequency || NotificationFrequency.Never,
          });
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      >
        <header>Notification Settings</header>
        <FormBuilder
          value={formData}
          onChange={setFormData}
          renderForm={(formProps) => (
            <Form {...formProps}>
              <Form.RadioGroup field="frequency" options={frequencyOptions} />
              <Form.Trigger
                field="frequency"
                action={({ fields }) => {
                  if (open) {
                    setConfirmVisible(true);
                    setOpen(false);
                  }
                }}
              />
            </Form>
          )}
        />
      </Popup>
      <ModalBuilder
        visible={confirmVisible}
        submitAction={updateNotificationSetting}
        submitParams={{
          frequency: formData.frequency,
          directoryId: item.id,
        }}
        onSubmitted={() => {
          setConfirmVisible(false);
          if (onRefresh) onRefresh();
        }}
        onCancel={() => {
          setConfirmVisible(false);
          setOpen(false);
        }}
        renderModal={(modalProps) => (
          <Modal.Confirmation
            {...modalProps}
            header="Update notification frequency"
          >
            <p>
              {formData && formData.frequency != NotificationFrequency.Never
                ? `Are you sure you wish to update the notification's frequency to ${
                    NotificationFrequencyLabels[formData.frequency]
                  }? This only affects files directly within this folder.`
                : "Are you sure you do not wish to have any notifications for files within this folder?"}
            </p>
          </Modal.Confirmation>
        )}
      />
    </React.Fragment>
  );
};

export default NotificationLevelPicker;
