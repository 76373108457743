import React from "react";
import { ModalBuilder, Modal, Format } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { getFileSharedLinks } from "./actions";
import { CopyBox } from "components/misc";
import { AppRoutes } from "constants/routes";

const ViewSharedLinks = ({ file, as: As = Button, ...props }) => {
  return (
    <ModalBuilder
      loadAction={getFileSharedLinks}
      loadParams={file.id}
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, data) => (
        <Modal.Confirmation
          className="share-modal displaying-link"
          header={`Active shared links for ${file.name}`}
          size="small"
          cancelLabel="Close"
          {...modalProps}
        >
          <section className="shared-links">
            {!Array.isArray(data) ||
              (data.length == 0 && <p>No active shared links.</p>)}
            {Array.isArray(data) &&
              data.length > 0 &&
              data.map((x) => {
                const shareUrl = `${process.env.BASE_URL}${AppRoutes.SharedFile(
                  x.token
                ).replace(/^\//, "")}`;
                return (
                  <div key={x.token} className="shared-link">
                    <p className="expires-text">
                      Expires:{" "}
                      <Format.DateTime
                        value={x.expiryUtc}
                        format="hh:mma DD/MM/YYYY"
                      />
                    </p>
                    <CopyBox content={shareUrl} label="Copy Link" />
                  </div>
                );
              })}
          </section>
        </Modal.Confirmation>
      )}
    />
  );
};

export default ViewSharedLinks;
