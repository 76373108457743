import React from "react";
import { MaplesPage } from "components/pages";
import { ListSites } from "features/Sites/ListSites";
import { ListOrganisations } from "features/Organisations/ListOrganisations";

const OrganisationsListPage = () => {
  return (
    <MaplesPage headerItems={["Organisations"]}>
      <ListOrganisations />
    </MaplesPage>
  );
};

export default OrganisationsListPage;
