import React from "react";

// manipulation function to prevent build minification obscuring
// raw config value when using deployment string substitution
const getEnvironmentBanner = value => {
  if (typeof value === "string") {
    return value.trim();
  }
  return null;
};

const EnvironmentBanner = () => {
  const banner = getEnvironmentBanner(process.env.ENVIRONMENT_BANNER);
  if (!banner) return null;
  return <div className="environment-banner">{banner}</div>;
};

export default EnvironmentBanner;
