import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "SITES/USERS";

const ActionTypes = {
  GetSiteUsers: `${NAMESPACE}/GET_SITE_USERS`,
};

export const getSiteUsers = (
  { filters, pagination },
  { siteId, directoryRef }
) => {
  const { pageSize, pageNumber } = pagination;
  const { search, sortName, sortDirection } = filters;
  return apiGet(ActionTypes.GetSiteUsers, `sites/${siteId}/users`, {
    pageSize,
    pageNumber,
    search,
    sortName,
    sortDirection,
    directoryRef,
  });
};
