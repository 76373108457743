import React, { useRef, useEffect, useCallback, useState } from "react";
import { Breadcrumb, ScreenCheck, ActionMenu } from "@redriver/cinnamon";
import DividerArrow from "assets/icons/small-right-arrow.svg";
import ProfileControl from "./ProfileControl";

// accept items as strings or objects with a "to" link
const PageHeader = ({ items = [] }) => {
  const outerRef = useRef(null);
  const innerRef = useRef(null);
  const isMounted = useRef(false);
  const [condensed, setCondensed] = useState(false);
  const [limit, setLimit] = useState(0);

  // need to store when mounted to avoid problem in callback with updating local state when component is unmounted
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const outerWidth = limit || outerRef.current?.clientWidth || 0;
    const innerWidth = innerRef.current?.clientWidth || 0;

    // compare the widths of the inner and outer wrappers - if inner is greater than outer then we know its overflowing and that we need to condense/collpase the breadcrumbs.
    // the inner wrapper is positioned absolute to allow it's width to keep increasing
    // will stay collapsed if already collapsed
    setCondensed(condensed || innerWidth > outerWidth);
  }, [outerRef, limit, items]);

  useEffect(() => {
    let observer = null;
    const target = outerRef.current;
    // use this to watch when the outer wrapper changes its size
    if (target && !!window.ResizeObserver) {
      // subscribe to changes for dom node
      observer = new ResizeObserver(() => {
        // set width in state so we can trigger the useEffect
        if (isMounted.current) setLimit(target.clientWidth);
      }).observe(target);
    }

    return () => {
      if (observer) {
        // unbind callback
        observer.unobserve(target);
      }
    };
  }, [outerRef]);

  return (
    <header className="page-header-bar">
      <div className="breadcrumb-wrapper" ref={outerRef}>
        <div className="breadcrumb-inner-wrapper" ref={innerRef}>
          <Breadcrumb divider={<img src={DividerArrow} />}>
            {items
              .filter((x) => !!x)
              .map((x, i) => {
                const contentType = typeof x;
                const isMiddleItem = i != 0 && i != items.length - 1;

                if (condensed && isMiddleItem) {
                  const isCentral = i == 1 && items.length > 2;
                  return isCentral ? (
                    <Breadcrumb.Item
                      key="condensed"
                      className="collapsed-options "
                    >
                      <ActionMenu
                        direction="right"
                        openOnHover={false}
                        trigger={<div>...</div>}
                      >
                        {items
                          .filter(
                            (_, idx) => idx != 0 && idx != items.length - 1
                          )
                          .map((item, idx) => (
                            <ActionMenu.Item
                              key={idx}
                              link={typeof item === "object" ? item.to : null}
                            >
                              {typeof item === "object" ? item.content : item}
                            </ActionMenu.Item>
                          ))}
                      </ActionMenu>
                    </Breadcrumb.Item>
                  ) : null;
                }

                switch (contentType) {
                  case "object":
                    return (
                      <Breadcrumb.Item key={i} link={x.to}>
                        {isMiddleItem ? trimString(x.content) : x.content}
                      </Breadcrumb.Item>
                    );
                  default:
                    return (
                      <Breadcrumb.Item key={i}>
                        {isMiddleItem ? trimString(x) : x}
                      </Breadcrumb.Item>
                    );
                }
              })
              .filter((x) => x != null)}
          </Breadcrumb>
        </div>
      </div>
      <ScreenCheck size="desktop">
        <ProfileControl />
      </ScreenCheck>
    </header>
  );
};

function trimString(content, charCount = 12) {
  if (typeof content == "string") {
    return (
      content.substring(0, charCount) +
      (content.length > charCount ? "..." : "")
    );
  }
  return content;
}

export default PageHeader;
