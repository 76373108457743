import React from "react";
import { Format } from "@redriver/cinnamon";
import { Table as STable, Checkbox } from "semantic-ui-react";
import { removeWorkspaceDirectory } from "features/System/WorkspaceMenu/actions";
import { FileIcon, DirectoryIcon } from "components/icons";
import { stripFileExtension, getDirectorySlug } from "modules/helpers";
import { Link } from "react-router-dom";
import { DisplayDataSize } from "components/containers";
import WorkspaceItemActions from "./WorkspaceItemActions";
import { FileLink } from "components/buttons";
import NotificationLevelPicker from "./NotificationLevelPicker";
import { Draggable } from "react-beautiful-dnd";
import { calcSelectionState } from "./WorkspaceContentHelpers";

const WorkspaceContentRow = ({
  item,
  index,
  path,
  skipRoot,
  rootSource,
  reordering,
  state,
  events,
  tableProps,
}) => {
  const targetPaths = path.filter((_, i) => (skipRoot ? i != 0 : true));
  const target =
    rootSource +
    (targetPaths.length > 0 ? "/" : "") +
    targetPaths.join("/") +
    "/" +
    getDirectorySlug(item.reference, item.name);

  const totalRowCount = tableProps?.data?.results?.length ?? 10;
  return (
    <Draggable
      isDragDisabled={!reordering}
      key={item.id}
      draggableId={item.id}
      index={index}
    >
      {(provided, snapshot) => (
        <tr
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <STable.Cell onClick={(e) => e.stopPropagation()}>
            <Checkbox
              onChange={(_, { checked }) => {
                const next = calcSelectionState(
                  state.selection,
                  checked,
                  tableProps.data.totalResults,
                  item
                );

                events.onChangeSelection(next);
              }}
              checked={
                (state.selection.selected || []).some(
                  (x) => x === item.dataKey
                ) ||
                (!!state.selection.allSelected &&
                  (state.selection.deselected || []).every(
                    (x) => x != item.dataKey
                  ))
              }
            />
          </STable.Cell>

          <STable.Cell>
            {item.directory ? (
              <DirectoryIcon />
            ) : (
              <FileIcon fileName={item.name} />
            )}
          </STable.Cell>
          <STable.Cell>
            {!reordering ? (
              item.directory ? (
                <Link to={target}>{item.name}</Link>
              ) : (
                <FileLink file={item}>{stripFileExtension(item.name)}</FileLink>
              )
            ) : item.directory ? (
              item.name
            ) : (
              stripFileExtension(item.name)
            )}
          </STable.Cell>
          <STable.Cell>
            <DisplayDataSize value={item.size} />
          </STable.Cell>
          <STable.Cell>
            {item.accessedUtc ? (
              <Format.DateTime
                value={item.accessedUtc}
                format="DD MMM YYYY HH:mm"
              />
            ) : (
              "-"
            )}
          </STable.Cell>
          <STable.Cell>{item.createdBy}</STable.Cell>
          <STable.Cell className="right aligned">
            <NotificationLevelPicker
              hidden={!item.directory || reordering}
              item={item}
              onRefresh={events.onRefresh}
            />
          </STable.Cell>
          <STable.Cell className="right aligned">
            <WorkspaceItemActions
              hidden={!item.canEdit || reordering}
              item={item}
              onRefresh={events.onRefresh}
              directoryId={tableProps.data.id}
              siteRootDirectoryId={tableProps.data.siteRootDirectoryId}
              onDeleted={() =>
                item.directory
                  ? dispatch(
                      removeWorkspaceDirectory(tableProps.data.id, item.id)
                    )
                  : null
              }
              itemIndex={index}
              visibleRowCount={totalRowCount}
            />
          </STable.Cell>
        </tr>
      )}
    </Draggable>
  );
};

export default WorkspaceContentRow;
