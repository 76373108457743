import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { getAuthState } from "@redriver/cinnamon";
import { Logout } from "features/System";
import { AppRoutes } from "constants/routes";
import { SystemPageContent, PublicPageContent } from "components/pages";

const LogoutPage = ({ loggedIn, location }) => {
  const nextRoute =
    location.state && location.state.nextPath
      ? location.state.nextPath
      : AppRoutes.Root;

  return (
    <PublicPageContent>
      <Logout />
      {!loggedIn && (
        <div style={{ marginTop: 20 }}>
          <Button fluid as={Link} to={nextRoute}>
            Continue
          </Button>
        </div>
      )}
    </PublicPageContent>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: getAuthState(state).loggedIn,
});

export default connect(mapStateToProps)(LogoutPage);
