import React from "react";
import { FormBuilder, Form } from "@redriver/cinnamon";

const FilterWorkspaceContent = ({ value, onChange, disabled }) => (
  <FormBuilder
    value={value}
    onChange={onChange}
    renderForm={(formProps) => (
      <Form {...formProps}>
        <Form.Input
          field="search"
          placeholder="Search"
          icon="search"
          disabled={disabled}
        />
      </Form>
    )}
  />
);

export default FilterWorkspaceContent;
