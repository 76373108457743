import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const MaplesLabel = ({ className, type = "default", children }) => {
  return (
    <span className={classNames("maples-label", type, className)}>
      {children}
    </span>
  );
};

MaplesLabel.propTypes = {
  type: PropTypes.oneOf(["primary", "secondary", "default"]),
};

export default MaplesLabel;
