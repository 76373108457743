import React, { useContext } from "react";
import { ContentBuilder, Format } from "@redriver/cinnamon";
import { getGroupDetails, addGroupUsers } from "./actions";
import { BreadcrumbContext } from "components/pages";
import * as Breadcrumbs from "constants/breadcrumbs";
import { AppRoutes } from "constants/routes";
import {
  MaplesLabel,
  DetailsPanel,
  DetailsField,
  MaplesTabs,
} from "components/containers";
import GroupUsersList from "./GroupUsersList";
import GroupPermissionsList from "./GroupPermissionsList";
import { GroupActions } from "features/Groups/ListGroups";
import { useHistory } from "react-router-dom";

const GroupDetails = ({ groupId }) => {
  const [_, setBreadcrumbs] = useContext(BreadcrumbContext);
  const history = useHistory();
  return (
    <ContentBuilder
      loadAction={getGroupDetails}
      loadParams={groupId}
      onLoaded={(data) => {
        setBreadcrumbs([
          { content: Breadcrumbs.Groups, to: AppRoutes.Groups },
          data?.name,
        ]);
      }}
      renderContent={(data, state, events) => {
        return (
          <React.Fragment>
            <DetailsPanel
              loading={state.loading}
              leftContent={
                <DetailsField title="Name">
                  <span style={{ marginRight: "1rem" }}>{data.name}</span>
                  {data.isActive ? (
                    <MaplesLabel type="primary">Active</MaplesLabel>
                  ) : (
                    <MaplesLabel type="default">Inactive</MaplesLabel>
                  )}
                </DetailsField>
              }
              rightContent={
                <React.Fragment>
                  <DetailsField title="Created">
                    <Format.DateTime
                      value={data.createdUtc}
                      format="DD MMM YYYY HH:mm"
                    />
                  </DetailsField>
                  <DetailsField title="Modified">
                    {data.modifiedUtc ? (
                      <Format.DateTime
                        value={data.modifiedUtc}
                        format="DD MMM YYYY HH:mm"
                      />
                    ) : (
                      "-"
                    )}
                  </DetailsField>
                  <GroupActions
                    group={data}
                    onRefresh={({ isDeleted }) => {
                      if (isDeleted) {
                        history.push(AppRoutes.Groups);
                      } else {
                        events.onRefresh();
                      }
                    }}
                    className="header-actions"
                  />
                </React.Fragment>
              }
            />

            <MaplesTabs
              className={state.loading ? "display-none" : null}
              tabs={[
                {
                  title: "Site Permissions",
                  content: <GroupPermissionsList groupId={groupId} />,
                },
                {
                  title: "Users",
                  content: <GroupUsersList groupId={groupId} />,
                },
              ]}
            />
          </React.Fragment>
        );
      }}
    />
  );
};

export default GroupDetails;
