import React from "react";
import { ContentBuilder, Format } from "@redriver/cinnamon";
import { getSharingInfo } from "./actions";
import { FileIcon } from "components/icons";
import { DisplayDataSize } from "components/containers";
import { Button } from "semantic-ui-react";
import { FileLink } from "components/buttons";
import classNames from "classnames";

const SharedFileDisplay = ({ token, className }) => {
  return (
    <section className={classNames("public-form", className)}>
      <h1 className="big-header">Shared File</h1>
      {!token && <p>No sharing token provided</p>}
      {token && (
        <ContentBuilder
          loadAction={getSharingInfo}
          loadParams={token}
          renderContent={(data, state, events) => {
            if (data && data.isValid) {
              return (
                <div>
                  <p>
                    The following file is available to download until <br />
                    <Format.DateTime
                      value={data.expiryUtc}
                      format="hh:mma DD/MM/YYYY"
                    />
                  </p>
                  <p className="shared-file-details">
                    <FileIcon fileName={data.fileName} />
                    <span className="shared-file-name" title={data.fileName}>
                      {data.fileName}
                    </span>
                    <span>
                      (<DisplayDataSize value={data.fileSize} />)
                    </span>
                  </p>
                  <p>
                    <FileLink
                      shareToken={token}
                      name={data.fileName}
                      As={Button}
                    >
                      Click here to download
                    </FileLink>
                  </p>
                </div>
              );
            } else if (data) {
              return <p>The sharing for this file is no longer active.</p>;
            }

            return <p>Invalid sharing token.</p>;
          }}
        />
      )}
    </section>
  );
};

export default SharedFileDisplay;
