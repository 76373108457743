import React from "react";
import { Segment, Header, Divider } from "semantic-ui-react";
import { PageHeader } from "@redriver/cinnamon";
import { UserDetails, PasswordChange } from "features/System";
import { AppPageContent, MaplesPage } from "components/pages";

const AccountPage = () => (
  <MaplesPage headerItems={["My Account"]}>
    <Segment padded>
      <UserDetails />
    </Segment>
  </MaplesPage>
);

export default AccountPage;
