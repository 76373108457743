import React from "react";
import { FormBuilder, Form } from "@redriver/cinnamon";
import { OrganisationTypeAhead } from "features/Organisations/OrganisationTypeAhead";
import { UserStatus } from "constants/enums";

const FilterUsers = ({ value, onChange }) => {
  const statusOptions = Object.values(UserStatus).map((v) => ({
    text: v,
    value: v,
  }));
  return (
    <FormBuilder
      value={value}
      onChange={onChange}
      renderForm={(formProps) => (
        <Form
          {...formProps}
          onChange={(f, d, a) => {
            formProps.onChange(f, d, a);
          }}
          style={{ display: "flex", top: "2px" }}
        >
          <Form.Input
            field="search"
            placeholder="Search"
            icon="search"
            style={{
              marginLeft: "1rem",
            }}
          />

          <OrganisationTypeAhead
            field="organisationId"
            placeholder="Select organisation"
            textField="organisationName"
            clearable
            style={{
              marginLeft: "1rem",
            }}
          />

          <Form.Dropdown
            field="status"
            placeholder="Select Status"
            options={statusOptions}
            multiple
            style={{
              marginLeft: "1rem",
            }}
          />
        </Form>
      )}
    />
  );
};

export default FilterUsers;
