import React, { useState } from "react";
import { Header, Icon } from "semantic-ui-react";
import { MaplesPage } from "components/pages";
import { Form, FormBuilder } from "@redriver/cinnamon";
import { MaplesLabel, MaplesTabs } from "components/containers";

const options = [
  { text: "Option One", value: 1 },
  { text: "Option Two", value: 2 },
  { text: "Option Three", value: 3 },
  { text: "Option Four", value: 4 },
];

const StylesPage = () => {
  const [formData, setFormData] = useState({
    multi: [options[0].value],
    disabled: "Read only text",
    check: true,
    disabledCheck: true,
    radio: options[0].value,
    disabledRadio: options[0].value,
    disabledToggle: true,
  });

  return (
    <MaplesPage
      headerItems={[
        "Styles",
        { to: "/users", content: "01. September 2020 Search Results" },
        { to: "/users", content: "02. September 2020 Search Results" },
        { to: "/users", content: "03. September 2020 Search Results" },
        { to: "/users", content: "04. September 2020 Search Results" },
        { to: "/users", content: "05. September 2020 Search Results" },
        { to: "/users", content: "06. September 2020 Search Results" },
        "Testing Breadcrumbs",
      ]}
    >
      <section style={{ width: "50vw", marginBottom: "1rem" }}>
        <h3>Forms</h3>

        <FormBuilder
          value={formData}
          onChange={setFormData}
          renderForm={(formProps) => (
            <Form {...formProps}>
              <Form.Group widths="equal">
                <Form.Input
                  field="input"
                  label="Basic Input"
                  fluid
                  placeholder="Enter text"
                />
                <Form.Input
                  field="required-input"
                  label="Required Input"
                  placeholder="Enter text"
                  required
                  fluid
                  showErrors
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  field="disabled"
                  label="Disabled Input"
                  fluid
                  disabled
                  placeholder="Wait"
                />
                <Form.Date
                  field="date"
                  label="Date Picker"
                  placeholder="DD/MM/YYYY"
                  fluid
                  local
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Dropdown
                  field="single"
                  label="Single Dropdown"
                  options={options}
                  fluid
                  placeholder="Please select"
                  clearable
                />
                <Form.Dropdown
                  field="multi"
                  label="Multi Dropdown"
                  placeholder="Please select"
                  options={options}
                  multiple
                  fluid
                  clearable
                  disabled
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.TypeAhead
                  field="ahead"
                  options={options}
                  placeholder="Search..."
                  label="Type Ahead"
                  fluid
                  clearable
                />
                <Form.TextArea
                  field="area"
                  label="Text Area"
                  placeholder="Enter description"
                  fluid
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Checkbox field="notCheck" label="Not checked" />
                <Form.Checkbox field="check" label="Checked" />
                <Form.Checkbox
                  field="disabledCheck"
                  label="Disabled check"
                  disabled
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.RadioGroup
                  field="radio"
                  label="Radio list"
                  options={options}
                />
                <Form.RadioGroup
                  field="disabledRadio"
                  label="Disabled radio list"
                  options={options}
                  disabled
                />
                <Form.Checkbox field="toggle" label="Toggle" toggle />
                <Form.Checkbox
                  field="disabledToggle"
                  label="Disabled toggle"
                  toggle
                  disabled
                />
              </Form.Group>
            </Form>
          )}
        />
      </section>
      <section style={{ width: "50vw", marginBottom: "1rem" }}>
        <h3>Labels</h3>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <MaplesLabel type="primary">Active</MaplesLabel>
          <MaplesLabel type="secondary">Pending</MaplesLabel>
          <MaplesLabel type="default">Inactive</MaplesLabel>
        </div>
      </section>
      <section style={{ marginBottom: "1rem" }}>
        <h3>Tabs</h3>
        <MaplesTabs
          tabs={[
            { title: "Tab One", content: <div>Content for tab one</div> },
            { title: "Tab Two", content: <div>Content for tab two</div> },
            { title: "Tab Three", content: <div>Content for tab three</div> },
          ]}
        />
      </section>
    </MaplesPage>
  );
};

export default StylesPage;
