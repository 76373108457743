import DocIcon from "assets/files/doc.png";
import UnknownIcon from "assets/files/file.png";
import ImageIcon from "assets/files/image.png";
import PdfIcon from "assets/files/pdf.png";
import PptIcon from "assets/files/ppt.png";
import XlsIcon from "assets/files/xls.png";
import ZipIcon from "assets/files/zip.svg";
import { getFileExtension } from "modules/helpers";
import React from "react";
import { Image } from "semantic-ui-react";

const getFileIcon = (fileExtension) => {
  switch (fileExtension) {
    case "doc":
    case "docx":
      return DocIcon;
    case "gif":
    case "jpg":
    case "jpeg":
    case "png":
    case "svg":
    case "tif":
      return ImageIcon;
    case "pdf":
      return PdfIcon;
    case "ppt":
    case "pptx":
      return PptIcon;
    case "xls":
    case "xlsx":
      return XlsIcon;
    case "zip":
      return ZipIcon;
    default:
      return UnknownIcon;
  }
};

const FileIcon = ({ fileName, ...props }) => {
  return (
    <Image
      src={getFileIcon(getFileExtension(fileName))}
      className="file-icon"
      {...props}
    />
  );
};

export default FileIcon;
