import {
  apiGet,
  apiPut,
  apiDelete,
  apiPost,
  apiRequest,
  AJAX_START,
  AJAX_SUCCESS,
  AJAX_ERROR,
} from "@redriver/cinnamon";
import { instance, AJAX_PROGRESS, handleError } from "modules/axios";

const NAMESPACE = "WORKSPACE/WORKSPACE_CONTENT";

export const ActionTypes = {
  GetContent: `${NAMESPACE}/GET_ITEM`,
  CreateFiles: `${NAMESPACE}/CREATE_FILES`,
  AddDirectory: `${NAMESPACE}/ADD_DIRECTORY`,
  CheckIsDirectoryNameValid: `${NAMESPACE}/CHECK_DIRECTORY_NAME_VALID`,
  DeleteContent: `${NAMESPACE}/DELETE_CONTENT`,
  UpdateDirectory: `${NAMESPACE}/UPDATE_DIRECTORY`,
  UpdateFile: `${NAMESPACE}/UPDATE_FILE`,
  MoveContent: `${NAMESPACE}/MOVE_CONTENT`,
  ValidMoveContent: `${NAMESPACE}/VALID_MOVE_CONTENT`,
  ResetWorkspaceDirectories: `${NAMESPACE}/RESET_WORKSPACE_DIRECTORIES`,
  GetWorkspaceDirectories: `${NAMESPACE}/GET_WORKSPACE_DIRECTORIES`,
  SetWorkspaceDirectories: `${NAMESPACE}/SET_WORKSPACE_DIRECTORIES`,
  ShareFile: `${NAMESPACE}/SHARE_FILE`,
  GetFileSharedLinks: `${NAMESPACE}/GET_FILE_SHARED_LINKS`,
  UpdateNotificationSetting: `${NAMESPACE}/UPDATE_NOTIFICATION_SETTING`,
  ReorderContent: `${NAMESPACE}/REORDER_CONTENT`,
  DownloadSelection: `${NAMESPACE}/DOWNLOAD_SELECTION`,
};

export const getContent = ({ filters, pagination }, path) => {
  const { pageSize, pageNumber } = pagination;
  const { search, sortName, sortDirection } = filters;
  return apiGet(
    ActionTypes.GetContent,
    "workspace/content/" + path.map((x) => x.split("-")[0]).join("/"),
    {
      pageSize,
      pageNumber,
      search,
      sortName,
      sortDirection,
    }
  );
};

export const createFiles = (
  jwt,
  data,
  directoryId,
  resolveNameClashes,
  callback
) => {
  const formData = new FormData();
  formData.append("directoryId", directoryId);
  formData.append("resolveNameClashes", resolveNameClashes);
  data.files.forEach((f, i) => {
    formData.append(`files`, f.file);
  });

  callback({ state: AJAX_START, progress: 0 });

  instance(jwt)
    .post("files", formData, {
      onUploadProgress: (progressEvent) => {
        callback({
          state: AJAX_PROGRESS,
          progress: Math.floor(
            (progressEvent.loaded / progressEvent.total) * 100 * 0.9
          ),
        });
      },
    })
    .then((response) => {
      callback({ state: AJAX_SUCCESS, progress: 100, response });
    })
    .catch((error) =>
      callback({ state: AJAX_ERROR, progress: 0, error: handleError(error) })
    );
};

export const addDirectory = (data, directoryId) =>
  apiPost(
    ActionTypes.AddDirectory,
    `directories/${directoryId}/directory`,
    data
  );

export const checkValidDirectoryName = (
  directoryId,
  name,
  currentDirectoryId = null
) =>
  apiGet(
    ActionTypes.CheckIsDirectoryNameValid,
    `directories/${directoryId}/valid-name`,
    {
      name,
      currentDirectoryId,
    },
    {
      debounce: 300,
    }
  );

export const checkValidFileName = (directoryId, name, currentFileId = null) =>
  apiGet(
    ActionTypes.CheckIsDirectoryNameValid,
    `files/valid-name`,
    {
      directoryId,
      name,
      currentFileId,
    },
    {
      debounce: 300,
    }
  );

export const deleteContent = ({ directoryId, ...request }) =>
  apiDelete(ActionTypes.DeleteContent, `directories/${directoryId}/content`, {
    ...request,
    selected: (request.selected || []).map((x) => JSON.parse(x)),
    deselected: (request.deselected || []).map((x) => JSON.parse(x)),
  });

export const moveContent = (data, { directoryId, ...request }) =>
  apiPut(ActionTypes.MoveContent, `directories/${directoryId}/content`, {
    ...data,
    ...request,
    selected: (request.selected || []).map((x) => JSON.parse(x)),
    deselected: (request.deselected || []).map((x) => JSON.parse(x)),
  });

export const validMoveContent = (data, { directoryId, ...request }) =>
  apiPost(
    ActionTypes.ValidMoveContent,
    `directories/${directoryId}/valid-move`,
    {
      ...data,
      ...request,
      selected: (request.selected || []).map((x) => JSON.parse(x)),
      deselected: (request.deselected || []).map((x) => JSON.parse(x)),
    }
  );

export const updateDirectory = (data, directoryId) =>
  apiPut(ActionTypes.UpdateDirectory, `directories/${directoryId}`, data);

export const updateFile = (data, fileId) =>
  apiPut(ActionTypes.UpdateFile, `files/${fileId}`, {
    ...data,
    name: data.name + "." + data.fileExtension,
  });

export const resetWorkspaceDirectories = () => {
  return {
    type: ActionTypes.ResetWorkspaceDirectories,
  };
};

export const getWorkspaceDirectories = (
  rootDirectoryId,
  pagination,
  reset = false
) => {
  const { pageSize, pageNumber } = pagination;
  return apiGet(
    ActionTypes.GetWorkspaceDirectories,
    "workspace/tree",
    {
      pageSize,
      pageNumber,
      rootDirectoryId,
    },
    { actionCustomData: { rootDirectoryId, reset } }
  );
};

export const setWorkspaceDirectories = (
  data,
  rootDirectoryId,
  pageNumber,
  root
) => {
  return {
    type: ActionTypes.SetWorkspaceDirectories,
    ...data,
    rootDirectoryId,
    pageNumber,
    root,
  };
};

export const shareFile = (data, fileId) => {
  const request = {
    daysDuration: data.daysDuration,
    emails: data.shareType == "Email" ? data.emails : [],
  };
  return apiPost(ActionTypes.ShareFile, `files/${fileId}/share`, request);
};

export const getFileSharedLinks = (fileId) => {
  return apiGet(ActionTypes.GetFileSharedLinks, `files/${fileId}/share/links`);
};

export const updateNotificationSetting = ({ frequency, directoryId }) => {
  return apiPut(
    ActionTypes.UpdateNotificationSetting,
    `workspace/notification-setting`,
    {
      frequency,
      directoryId,
    }
  );
};

export const reorderContent = (request, directoryId) =>
  apiPut(
    ActionTypes.ReorderContent,
    `directories/${directoryId}/content/reorder`,
    request
  );

export const downloadSelection = ({ directoryId, selection }) =>
  apiPost(
    ActionTypes.DownloadSelection,
    `directories/${directoryId}/content/download`,
    {
      ...selection,
      selected: (selection.selected || []).map((x) => JSON.parse(x)),
      deselected: (selection.deselected || []).map((x) => JSON.parse(x)),
    },
    { preventResponseHandling: true }
  );
