import { apiGet, apiPut, apiDelete, apiPost } from "@redriver/cinnamon";

const NAMESPACE = "ADMIN/USER_ACTIONS";

const ActionTypes = {
  GetUser: `${NAMESPACE}/GET_USER`,
  UpdateUser: `${NAMESPACE}/UPDATE_USER`,
  ResendVerification: `${NAMESPACE}/RESEND_VERIFICATION`,
  SendPasswordReset: `${NAMESPACE}/SEND_PASSWORD_RESET`,
  LockOutUser: `${NAMESPACE}/LOCK_OUT_USER`,
  ActivateUser: `${NAMESPACE}/ACTIVATE_USER`,
};

export const getUser = (userId) =>
  apiGet(ActionTypes.GetUser, `users/${userId}`);

export const updateUser = (data, userId) => {
  return apiPut(ActionTypes.UpdateUser, `users/${userId}`, data);
};

export const resendVerification = (userId) =>
  apiPost(
    ActionTypes.ResendVerification,
    `users/${userId}/resend-verification-email`
  );

export const sendPasswordReset = (submitParams) => {
  return apiPost(
    ActionTypes.SendPasswordReset,
    `users/send-password-reset-email`,
    submitParams
  );
};
