export const Actions = {
  View: "v",
  Create: "c",
  Edit: "e",
  Delete: "d",
  Reorder: "ro",
};

export const Targets = {
  User: "u",
  Role: "r",
  Site: "s",
  Group: "g",
  File: "f",
  Bin: "b",
  Organisation: "o",
};
