import { produce } from "immer";

export const calcSelectionState = (selection, checked, totalResults, item) => {
  const next = produce(selection, (draft) => {
    if (!Array.isArray(draft.selected)) {
      draft.selected = [];
    }
    if (!Array.isArray(draft.deselected)) {
      draft.deselected = [];
    }
    if (checked) {
      if (draft.selected.every((x) => x !== item.dataKey)) {
        draft.selected.push(item.dataKey);
      }
      if (!draft.allSelected && draft.selected.length == totalResults) {
        draft.allSelected = true;
        draft.selected = [];
      }
    } else {
      if (
        draft.allSelected &&
        draft.deselected.every((x) => x !== item.dataKey)
      ) {
        draft.deselected.push(item.dataKey);
      } else if (draft.selected.some((x) => x === item.dataKey)) {
        const index = draft.selected.findIndex((x) => x === item.dataKey);
        if (index != -1) {
          draft.selected.splice(index, 1);
        }
      }

      if (draft.allSelected && draft.deselected.length == totalResults) {
        draft.allSelected = false;
        draft.deselected = [];
      }
    }
  });
  return next;
};
