import React, { useMemo, useContext } from "react";
import { NavMenu } from "@redriver/cinnamon";
import { Loader } from "semantic-ui-react";
import { ArrowIcon } from "components/icons";
import classNames from "classnames";
import TreeContext from "./TreeContext";
import { getDirectorySlug } from "modules/helpers";
import FullStarIcon from "assets/icons/star-full.svg";

const MenuTreeNode = ({
  node,
  link,
  className,
  onNodeExpanded,
  onLoadMore,
  totalNodes,
  loadingIds,
  level = 0,
  groupLevel = null,
  isGroupExpanded = false,
  isLastGroupItem = false,
  onClick,
  renderCustomContent,
  onlyRenderChildren = false,
  indent = 4,
}) => {
  const [expandedIds, setExpandedIds] = useContext(TreeContext);
  const isExpanded = useMemo(() => expandedIds.some((x) => x == node.id), [
    expandedIds,
  ]);
  // limiting identation to 10 levels deep (40px). otherwise indentation might get to a point where the text is pushed out of view entirely
  const maxIndentLevel = 10;

  // managing group expanded styling
  const isGroupLevel = groupLevel != null && level == groupLevel;
  const groupExpanded = isGroupExpanded || (isGroupLevel && isExpanded);
  const groupClass = groupExpanded ? "group-expanded" : null;
  const groupStart = groupExpanded && isGroupLevel ? "group-start" : null;
  const groupEnd =
    groupExpanded &&
    isLastGroupItem &&
    (node.directoriesCount == 0 || !isExpanded)
      ? "group-end"
      : null;
  return (
    <React.Fragment>
      {!onlyRenderChildren && (
        <NavMenu.Item
          exact
          link={onClick ? null : link}
          className={classNames(
            "tree-node",
            groupClass,
            groupStart,
            groupEnd,
            className
          )}
          onClick={() => {
            onClick != null ? onClick(node.id) : null;
          }}
        >
          {({ active }) => (
            <React.Fragment>
              <span
                className="arrow-wrapper"
                style={{
                  paddingLeft:
                    level <= maxIndentLevel
                      ? level * indent
                      : maxIndentLevel * indent,
                  visibility: !!node.directoriesCount ? "visible" : "hidden",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setExpandedIds(
                    isExpanded && node.directories.length
                      ? [...expandedIds.filter((x) => x != node.id)]
                      : [...expandedIds, node.id]
                  );
                  onNodeExpanded(node);
                }}
              >
                <ArrowIcon
                  white={active}
                  direction={
                    isExpanded && node.directories.length ? "down" : "right"
                  }
                />
              </span>

              {renderCustomContent ? (
                renderCustomContent(node)
              ) : (
                <React.Fragment>
                  {node.favourite ? (
                    <img
                      src={FullStarIcon}
                      className="item-star"
                      alt="Favourite site"
                    />
                  ) : null}
                  <span className="item-text">
                    {node.name}
                    <Loader
                      active={loadingIds.some((x) => x == node.id)}
                      inline
                      size="mini"
                    />
                  </span>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </NavMenu.Item>
      )}
      {(isExpanded || !!onlyRenderChildren) &&
        node.directories.map((x, i) => (
          <MenuTreeNode
            key={x.id}
            node={x}
            link={
              onClick
                ? null
                : `${link}/${getDirectorySlug(x.reference, x.name)}`
            }
            level={level + (onlyRenderChildren ? 0 : 1)}
            className={className}
            onNodeExpanded={onNodeExpanded}
            loadingIds={loadingIds}
            groupLevel={groupLevel}
            isGroupExpanded={groupExpanded}
            isLastGroupItem={
              i == node.directories.length - 1 &&
              (isLastGroupItem || isGroupLevel)
            }
            onClick={onClick}
            renderCustomContent={renderCustomContent}
            indent={indent}
          />
        ))}
      {(isExpanded || !!onlyRenderChildren) &&
      totalNodes &&
      totalNodes > node.directories.length &&
      node.directories.length > 0 ? (
        <NavMenu.Item className="item-text" onClick={onLoadMore}>
          Load More
          <Loader
            active={loadingIds.some((x) => x == null)}
            inline
            size="mini"
          />
        </NavMenu.Item>
      ) : null}
    </React.Fragment>
  );
};

export default MenuTreeNode;
