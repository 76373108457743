import React from "react";
import { FormBuilder, Form } from "@redriver/cinnamon";

const FilterRoles = ({ value, onChange }) => (
  <FormBuilder
    value={value}
    onChange={onChange}
    renderForm={formProps => (
      <Form {...formProps}>
        <Form.Input field="search" placeholder="Search" icon="search" />
      </Form>
    )}
  />
);

export default FilterRoles;
