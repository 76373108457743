import React, { useState, useEffect } from "react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import { Button, Grid } from "semantic-ui-react";
import { TagInput } from "features/System/TagInput";
import classNames from "classnames";
import { OrganisationTypeAhead } from "features/Organisations/OrganisationTypeAhead";

const AddUsers = ({
  as: As = Button,
  onAdded,
  submitAction,
  submitParams,
  searchParams,
  title,
  allowExistingUsers,
  ...props
}) => {
  const [emails, setEmails] = useState([]);
  const [isEnteringEmails, setIsEnteringEmails] = useState(true);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  return (
    <ModalBuilder
      withForm
      initialData={{
        users: [],
      }}
      submitAction={submitAction}
      submitParams={submitParams}
      onSubmitted={onAdded}
      onLoaded={() => {
        setShowErrors(false);
        setEmails([]);
        setIsEnteringEmails(true);
        setHasAttemptedSubmit(false);
      }}
      renderTrigger={(showModal) => (
        <As
          onClick={() => {
            setIsEnteringEmails(true);
            showModal();
          }}
          {...props}
        />
      )}
      renderModal={(modalProps, formProps, state, events) => (
        <Modal
          {...modalProps}
          header={title}
          size="tiny"
          submitLabel="Add"
          submitIcon="mail"
          onSubmit={() => {
            setHasAttemptedSubmit(true);
            if (isEnteringEmails) {
              if (emails.length > 0) {
                const users = emails.map((e) => {
                  const nameString = e.email.split("@")[0];
                  const names = nameString.split(/[\.\-_]/g);
                  return {
                    id: e.isNew ? null : e.id,
                    email: e.email,
                    firstName:
                      e.firstName ||
                      (names.length > 0
                        ? names[0][0].toUpperCase() + names[0].substring(1)
                        : ""),
                    lastName:
                      e.lastName ||
                      (names.length > 1
                        ? names[names.length - 1][0].toUpperCase() +
                          names[names.length - 1].substring(1)
                        : ""),
                    isAdmin: false,
                  };
                });

                formProps.onChange("users", emails, (v) => ({
                  ...v,
                  users,
                }));
                setIsEnteringEmails(false);
              }
            } else if (state.formValid) {
              events.onSubmit();
            } else {
              setShowErrors(true);
            }
          }}
        >
          <Form {...formProps} showErrors={showErrors}>
            <div
              style={{
                marginBottom: "1rem",
                display: isEnteringEmails ? "block" : "none",
              }}
            >
              <OrganisationTypeAhead
                label="Organisation"
                field="organisationId"
                textField="organisationName"
                fluid
                placeholder="Optionally search for organisation"
                clearable
              />
            </div>

            <TagInput
              allowExistingUsers={allowExistingUsers}
              label="Email Addresses"
              className="required"
              onUpdated={(items) => setEmails(items)}
              customError={
                hasAttemptedSubmit && emails.length == 0
                  ? "One or more emails are required"
                  : null
              }
              searchParams={searchParams}
              style={{ display: isEnteringEmails ? "block" : "none" }}
            />

            {isEnteringEmails ? (
              <React.Fragment></React.Fragment>
            ) : (
              <React.Fragment>
                <TextButton
                  onClick={() => {
                    setIsEnteringEmails(true);
                  }}
                >
                  Back
                </TextButton>
                {formProps.value.users.length == 0 && <p>No users to add.</p>}

                <Form.Array
                  field="users"
                  arrayKey="email"
                  propagateUpdates="always"
                >
                  {({ fields }) => (
                    <React.Fragment>
                      <div className="user-header">
                        <span className="email-label">{fields.email}</span>
                        <Form.ArrayRemover as={TextButton}>
                          Remove
                        </Form.ArrayRemover>
                      </div>
                      <Form.Group widths="equal">
                        <Form.Input
                          placeholder="First Name"
                          field="firstName"
                          fluid
                          required
                          disabled={!!fields.id}
                        />
                        <Form.Input
                          placeholder="Last Name"
                          field="lastName"
                          fluid
                          required
                          disabled={!!fields.id}
                        />
                      </Form.Group>
                    </React.Fragment>
                  )}
                </Form.Array>
              </React.Fragment>
            )}
          </Form>
        </Modal>
      )}
    />
  );
};

const TextButton = ({ children, className, ...props }) => (
  <a className={classNames("text-button", "pointer", className)} {...props}>
    {children}
  </a>
);

export default AddUsers;
