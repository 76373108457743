import React from "react";
import { PageNumber, PageSize } from "@redriver/cinnamon";
import ResultsCounter from "./ResultsCounter";

const TablePagination = ({
  value,
  onChange,
  totalItems,
  fixedPageSize,
  pageSizeOptions = [10, 25, 50, 100],
}) => {
  const onNumberChange = (pageNumber) => {
    onChange({
      pageSize: value.pageSize,
      pageNumber,
    });
  };

  const onSizeChange = (pageSize) => {
    // ensure page number still valid
    const totalPages = totalItems > 0 ? Math.ceil(totalItems / pageSize) : 0;
    onChange({
      pageSize,
      pageNumber: Math.min(totalPages, value.pageNumber || 1),
    });
  };

  return (
    <div className="table-pagination">
      <ResultsCounter
        pageNumber={value.pageNumber}
        pageSize={value.pageSize}
        totalItems={totalItems}
      />
      <PageNumber
        value={value.pageNumber}
        onChange={onNumberChange}
        pageSize={value.pageSize}
        totalItems={totalItems}
      />
      {!fixedPageSize && (
        <PageSize
          value={value.pageSize}
          onChange={onSizeChange}
          options={pageSizeOptions}
        />
      )}
    </div>
  );
};

export default TablePagination;
