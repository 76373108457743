import React from "react";
import { MaplesPage } from "components/pages";
import { WorkspaceItemRedirect } from "features/Workspace/WorkspaceItemRedirect";

const WorkspaceItemRedirectPage = ({ match, entityType }) => {
  const id = match.params.id;
  return (
    <MaplesPage>
      <WorkspaceItemRedirect itemId={id} entityType={entityType} />
    </MaplesPage>
  );
};

export default WorkspaceItemRedirectPage;
