import { apiPost } from "@redriver/cinnamon";

const NAMESPACE = "SYSTEM/PASSWORD_FORGOTTEN";
export const REQUEST_FORGOTTEN = `${NAMESPACE}/REQUEST`;

export const requestPasswordForgotten = ({ email }) =>
  apiPost(
    REQUEST_FORGOTTEN,
    "auth/reset-tokens",
    { email },
    {
      auth: false,
      preventErrorNotification: true
    }
  );
