import React from "react";
import { Table as STable, Checkbox } from "semantic-ui-react";
import { SortHeader } from "components/misc";
import { calcSelectionState } from "./WorkspaceContentHelpers";

const WorkspaceContentHeader = ({
  tableData,
  totalResults,
  state,
  selectedLength,
  onChangeFilters,
  reordering,
  events,
}) => (
  <STable.Header>
    <STable.Row>
      <STable.HeaderCell
        style={{ width: "50px" }}
        onClick={(e) => e.stopPropagation()}
      >
        <Checkbox
          onChange={(_, { checked }) => {
            let next = state.selection;
            const isIndeterminate =
              tableData.length !== selectedLength && selectedLength > 0;

            (tableData || []).forEach((item) => {
              next = calcSelectionState(
                next,
                checked || isIndeterminate,
                totalResults,
                item
              );
            });

            events.onChangeSelection(next);
          }}
          checked={
            state.selection.allSelected ||
            tableData.some((x) =>
              (state.selection.selected || []).some((y) => y == x.dataKey)
            )
          }
          indeterminate={
            tableData.length !== selectedLength && selectedLength > 0
          }
        />
      </STable.HeaderCell>
      <STable.HeaderCell style={{ width: "50px" }} />
      <STable.HeaderCell>
        <SortHeader
          title="Name"
          field="name"
          value={state.filters}
          onChange={onChangeFilters}
          disabled={reordering}
        />
      </STable.HeaderCell>
      <STable.HeaderCell>
        <SortHeader
          title="Size"
          field="size"
          value={state.filters}
          onChange={onChangeFilters}
          disabled={reordering}
        />
      </STable.HeaderCell>
      <STable.HeaderCell>
        <SortHeader
          title="Last Accessed"
          field="accessed"
          value={state.filters}
          onChange={onChangeFilters}
          disabled={reordering}
        />
      </STable.HeaderCell>
      <STable.HeaderCell>
        <SortHeader
          title="Author"
          field="author"
          value={state.filters}
          onChange={onChangeFilters}
          disabled={reordering}
        />
      </STable.HeaderCell>
      <STable.HeaderCell style={{ width: "5%" }} />
      <STable.HeaderCell style={{ width: "5%" }} />
    </STable.Row>
  </STable.Header>
);

export default WorkspaceContentHeader;
