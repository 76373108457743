import React from "react";
import { Table } from "@redriver/cinnamon";
import ActionsModal from "components/modals/ActionsModal";
import { deleteOrganisation } from "./actions";
import EditOrganisation from "./EditOrganisation";
import { useHistory } from "react-router-dom";
import { AppRoutes, UserRoutes } from "constants/routes";
import { TableActionMenu } from "components/misc";

const OrganisationActions = ({
  organisation,
  onRefresh,
  itemIndex,
  visibleRowCount,
}) => {
  const history = useHistory();
  return (
    <TableActionMenu itemIndex={itemIndex} visibleRowCount={visibleRowCount}>
      <Table.MenuItem
        onClick={() =>
          history.push(
            `${AppRoutes.Users}?organisationId=${organisation.id}&organisationName=${organisation.name}`
          )
        }
      >
        View Users
      </Table.MenuItem>
      <EditOrganisation
        as={Table.MenuItem}
        onUpdated={onRefresh}
        organisation={organisation}
      >
        Edit
      </EditOrganisation>
      {organisation.userCount == 0 && (
        <ActionsModal
          as={Table.MenuItem}
          submitAction={deleteOrganisation}
          id={organisation.id}
          name={organisation.name}
          actionName="delete"
          onRefresh={onRefresh}
          header="Delete Organisation"
          submitParams={{
            organisationId: organisation.id,
          }}
        >
          Delete
        </ActionsModal>
      )}
    </TableActionMenu>
  );
};

export default OrganisationActions;
