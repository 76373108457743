import React from "react";
import { MaplesPage } from "components/pages";
import { ListSites } from "features/Sites/ListSites";

const SitesListPage = () => {
  return (
    <MaplesPage headerItems={["Sites"]}>
      <ListSites />
    </MaplesPage>
  );
};

export default SitesListPage;
