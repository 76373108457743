import axios from "axios";

export const AJAX_PROGRESS = "AJAX_PROGRESS";

export const instance = (jwt) => {
  return axios.create({
    baseURL: process.env.API_URL,
    headers: jwt ? { Authorization: `Bearer ${jwt}` } : {},
  });
};

export const handleError = (error) => {
  if (error.response) {
    if (Array.isArray(error.response.data)) {
      return error.response.data
        .map((x) => {
          return `${x.message} [${x.code}]`;
        })
        .join(" / ");
    }
  }

  return "Unexpected error";
};
