import React from "react";
import { ListBuilder, Table, Format } from "@redriver/cinnamon";
import { Popup } from "semantic-ui-react";
import { getContent } from "./actions";
import { EntityType, Sort } from "constants/enums";
import { FileIcon, DirectoryIcon, SitesIcon } from "components/icons";
import { SortHeader, RunOnChange } from "components/misc";
import { DisplayDataSize } from "components/containers";
import { TablePagination } from "components/pagination";
import BinItemActions from "./BinItemActions";
import BinActionBar from "./BinActionBar";
import { stripFileExtension } from "modules/helpers";

const BinContent = () => {
  return (
    <ListBuilder
      withTable
      loadAction={getContent}
      initialFilters={{ sortName: "deleted", sortDirection: Sort.Desc }}
      initialPagination={{ pageSize: 25, pageNumber: 1 }}
      totalTransform={(data) => data.totalResults}
      dataTransform={(data) => {
        return {
          ...data,
          results: data.results.map((x) => ({
            ...x,
            dataKey: JSON.stringify({ type: x.type, id: x.id }),
          })),
        };
      }}
      renderList={(tableProps, state, events) => {
        const totalSelected = state.selection.allSelected
          ? state.total - (state.selection.deselected?.length || 0)
          : state.selection.selected?.length || 0;
        return (
          <div>
            <RunOnChange
              value={state.total}
              func={() => events.onChangeSelection({})}
            />
            <BinActionBar
              events={events}
              onRefresh={events.onRefresh}
              onChangeFilters={events.onChangeFilters}
              filters={state.filters}
              totalSelected={totalSelected}
              selection={state.selection}
            />
            <Table
              {...tableProps}
              data={tableProps.data.results}
              dataKey="dataKey"
              emptyMessage="No files found"
              selectable
            >
              <Table.SelectableColumn
                value={state.selection}
                onChange={events.onChangeSelection}
                totalItems={state.total}
              />
              <Table.Column
                render={(x) =>
                  x.type == EntityType.Directory ? (
                    <DirectoryIcon />
                  ) : x.type == EntityType.Site ? (
                    <SitesIcon className="site-icon" />
                  ) : (
                    <FileIcon fileName={x.name} />
                  )
                }
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Name"
                    field="name"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                render={(x) =>
                  x.type == EntityType.File
                    ? stripFileExtension(x.name)
                    : x.name
                }
              />
              <Table.Column
                header="Path"
                render={(x) =>
                  x.path ? (
                    x.path.length > 1 ? (
                      <Popup
                        content={x.path.join("/")}
                        trigger={
                          <p>
                            {x.path[0] +
                              (x.path.length > 2 ? "/.../" : "/") +
                              x.path[x.path.length - 1]}
                          </p>
                        }
                      />
                    ) : (
                      x.path[0]
                    )
                  ) : null
                }
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Size"
                    field="size"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                render={(x) => <DisplayDataSize value={x.size} />}
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Created"
                    field={"created"}
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                width="13%"
                render={(x) => (
                  <Format.DateTime
                    value={x.createdUtc}
                    format="DD MMM YYYY HH:mm"
                  />
                )}
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Deleted"
                    field="deleted"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                width="13%"
                render={(x) => (
                  <Format.DateTime
                    value={x.deletedUtc}
                    format="DD MMM YYYY HH:mm"
                  />
                )}
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Deleted By"
                    field="deletedBy"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                width="13%"
                field="deletedBy"
              />
              <Table.Column
                width="5%"
                align="right"
                render={(x) => (
                  <BinItemActions
                    item={x}
                    onRefresh={events.onRefresh}
                    selection={state.selection}
                  />
                )}
              />
            </Table>
            <TablePagination
              value={state.pagination}
              onChange={events.onChangePagination}
              totalItems={state.total}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>
        );
      }}
    />
  );
};

export default BinContent;
