import { useEffect } from "react";
import { createWriteStream } from "streamsaver";

const useDownloadStreaming = (download, fileName) => {
  useEffect(() => {
    if (download.success && download.response) {
      const fileStream = createWriteStream(fileName);

      const readableStream = download.response.body;

      // more optimized but temporaryily disabling as it is not working with polyfill for now
      if (false && window.WritableStream && readableStream.pipeTo) {
        readableStream.pipeTo(fileStream);
      } else {
        window.writer = fileStream.getWriter();

        const reader = download.response.body.getReader();
        const pump = () =>
          reader
            .read()
            .then((res) =>
              res.done ? writer.close() : writer.write(res.value).then(pump)
            );

        pump();
      }
    }
  }, [download.response]);
};

export default useDownloadStreaming;
