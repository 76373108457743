import { apiPost } from "@redriver/cinnamon";

const NAMESPACE = "SYSTEM/PASSWORD_CHANGE";
export const REQUEST_CHANGE = `${NAMESPACE}/REQUEST`;

export const requestPasswordChange = ({ oldPassword, newPassword }, email) =>
  apiPost(
    REQUEST_CHANGE,
    `auth/credentials?key=${encodeURIComponent(email)}`,
    {
      oldSecret: oldPassword,
      newSecret: newPassword
    },
    { preventErrorNotification: true }
  );
