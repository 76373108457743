import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal, Form, useFormState } from "@redriver/cinnamon";
import { addDirectory } from "./actions";
import { checkValidDirectoryName } from "./actions";

const AddDirectory = ({ as: As = Button, onAdded, directoryId, ...props }) => {
  const dispatch = useDispatch();
  const formState = useFormState();

  const [checkingName, setCheckingName] = useState(false);
  const [isValidName, setIsValidName] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const setNameIsValidFromResponse = useCallback(
    (response) => {
      setIsValidName(
        !!response &&
          (!formState.errors.name || formState.errors.name.length == 0)
      );
    },
    [formState.errors]
  );

  return (
    <ModalBuilder
      withForm
      submitAction={addDirectory}
      submitParams={directoryId}
      onSubmitted={(response) => {
        if (onAdded) onAdded(response);
        setShowErrors(false);
        setIsValidName(false);
      }}
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps, state, events) => (
        <Modal
          {...modalProps}
          header="Add Folder"
          submitLabel="Add"
          size="tiny"
          onSubmit={() => {
            setShowErrors(true);
            if (formValid && isValidName && !checkingName) {
              events.onSubmit();
            }
          }}
        >
          <Form
            {...formProps}
            onValidated={(isValid) => setFormValid(isValid)}
            showErrors={showErrors}
          >
            {(fields, errors) => (
              <React.Fragment>
                <Form.Input
                  label="Name"
                  field="name"
                  fluid
                  required
                  showErrors={showErrors || (!isValidName && !!fields.name)}
                  customValidator={() => {
                    if (formProps.value.name) {
                      setCheckingName(true);
                      dispatch(
                        checkValidDirectoryName(
                          directoryId,
                          formProps.value.name
                        )
                      )
                        .then((result) => {
                          if (result.success && result.response != null) {
                            setNameIsValidFromResponse(result.response);
                          }
                        })
                        .finally(() => setCheckingName(false));
                    }
                  }}
                  customErrors={
                    !checkingName && !isValidName && !!fields.name
                      ? ["Duplicate folder name, please choose another"]
                      : errors.name
                  }
                />
              </React.Fragment>
            )}
          </Form>
        </Modal>
      )}
    />
  );
};

export default AddDirectory;
