import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FormBuilder, Form, ErrorMessage } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { SystemRoutes } from "constants/routes";
import { requestPasswordForgotten } from "./actions";

const PasswordForgotten = () => {
  const [requestSent, setRequestSent] = useState(false);
  const [requestEmail, setRequestEmail] = useState("");

  if (requestSent) {
    return (
      <div>
        <p>
          A password reset email has been sent
          {requestEmail ? ` to ${requestEmail}` : ""}
        </p>
        <div style={{ marginTop: 20 }}>
          <Button fluid as={Link} to={SystemRoutes.Login}>
            Continue
          </Button>
        </div>
      </div>
    );
  }

  return (
    <FormBuilder
      submitAction={requestPasswordForgotten}
      onSubmit={({ email }) => setRequestEmail(email)}
      onSubmitted={() => setRequestSent(true)}
      renderSubmitting={false}
      renderError={false}
      renderForm={(
        formProps,
        { submitting, slowSubmitting, error },
        events
      ) => (
        <Form {...formProps} className="public-form">
          <h1 className="big-header">Reset password</h1>
          <p>Enter you email address to reset your password.</p>
          <Form.Email
            field="email"
            placeholder="Email"
            disabled={submitting && slowSubmitting}
            autoFocus
            required
            fluid
            icon="at"
          />

          <Button
            className="confirm-btn"
            onClick={events.onSubmit}
            disabled={submitting}
            loading={submitting && slowSubmitting}
            fluid
          >
            Continue
          </Button>

          <Link to={SystemRoutes.Login}>Back to Sign In</Link>
          <ErrorMessage error={error} />
        </Form>
      )}
    />
  );
};

export default PasswordForgotten;
