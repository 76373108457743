import React from "react";
import Colours from "constants/colours";

const UsersIcon = ({ white = false }) => {
  const colour = white ? Colours.White : Colours.PrimaryBlue;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="11"
      viewBox="0 0 11 11"
    >
      <g id="teamwork" transform="translate(0.001 -0.005)">
        <g
          id="Group_34"
          data-name="Group 34"
          transform="translate(4.124 0.005)"
        >
          <g id="Group_33" data-name="Group 33" transform="translate(0 0)">
            <circle
              id="Ellipse_3"
              data-name="Ellipse 3"
              cx="1.375"
              cy="1.375"
              r="1.375"
              fill={colour}
            />
          </g>
        </g>
        <g
          id="Group_36"
          data-name="Group 36"
          transform="translate(3.437 3.442)"
        >
          <g id="Group_35" data-name="Group 35">
            <path
              id="Path_1266"
              data-name="Path 1266"
              d="M164.123,163.055l-.312-2.431a.685.685,0,0,0-.683-.619h-.377a.346.346,0,0,0-.243.1l-.444.444-.444-.444a.346.346,0,0,0-.243-.1H161a.683.683,0,0,0-.683.61L160,163.055a.344.344,0,0,0,.341.387h.371l.287,3.506a.685.685,0,0,0,.684.619h.753a.687.687,0,0,0,.685-.625l.287-3.5h.371a.343.343,0,0,0,.258-.117A.339.339,0,0,0,164.123,163.055Z"
              transform="translate(-160 -160.005)"
              fill={colour}
            />
          </g>
        </g>
        <g
          id="Group_38"
          data-name="Group 38"
          transform="translate(7.562 0.005)"
        >
          <g id="Group_37" data-name="Group 37" transform="translate(0 0)">
            <circle
              id="Ellipse_4"
              data-name="Ellipse 4"
              cx="1.375"
              cy="1.375"
              r="1.375"
              fill={colour}
            />
          </g>
        </g>
        <g
          id="Group_40"
          data-name="Group 40"
          transform="translate(0.687 0.005)"
        >
          <g id="Group_39" data-name="Group 39" transform="translate(0 0)">
            <path
              id="Path_1267"
              data-name="Path 1267"
              d="M33.374,0A1.375,1.375,0,1,0,34.749,1.38,1.376,1.376,0,0,0,33.374,0Z"
              transform="translate(-31.999 -0.005)"
              fill={colour}
            />
          </g>
        </g>
        <g
          id="Group_42"
          data-name="Group 42"
          transform="translate(7.649 3.442)"
        >
          <g id="Group_41" data-name="Group 41">
            <path
              id="Path_1268"
              data-name="Path 1268"
              d="M359.41,163.055l-.312-2.43a.684.684,0,0,0-.683-.619h-.377a.346.346,0,0,0-.243.1l-.444.444-.444-.444a.346.346,0,0,0-.243-.1h-.377a.655.655,0,0,0-.122.023,1.338,1.338,0,0,1,.179.517l.311,2.423a1.029,1.029,0,0,1-.252.815,1,1,0,0,1-.341.242l.228,2.236a.685.685,0,0,0,.684.619h.753a.687.687,0,0,0,.685-.625l.286-2.813h.371a.343.343,0,0,0,.258-.117A.339.339,0,0,0,359.41,163.055Z"
              transform="translate(-356.063 -160.005)"
              fill={colour}
            />
          </g>
        </g>
        <g
          id="Group_44"
          data-name="Group 44"
          transform="translate(-0.001 3.442)"
        >
          <g id="Group_43" data-name="Group 43" transform="translate(0)">
            <path
              id="Path_1269"
              data-name="Path 1269"
              d="M3.007,163.779a1.03,1.03,0,0,1-.249-.811l.313-2.441a1.271,1.271,0,0,1,.171-.5A.646.646,0,0,0,3.126,160H2.749a.346.346,0,0,0-.243.1l-.444.444-.444-.444a.346.346,0,0,0-.243-.1H1a.683.683,0,0,0-.683.61L0,163.055a.344.344,0,0,0,.341.387H.714L1,166.261a.685.685,0,0,0,.684.619h.753a.687.687,0,0,0,.685-.625l.228-2.231A1.031,1.031,0,0,1,3.007,163.779Z"
              transform="translate(0.001 -160.005)"
              fill={colour}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default UsersIcon;
