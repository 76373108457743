import React, { useMemo, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AppRoutes } from "constants/routes";
import TreeContext from "./TreeContext";
import MenuTreeNode from "./MenuTreeNode";

const MenuTree = ({
  sites = [],
  className,
  onNodeExpanded,
  onLoadMore,
  totalNodes,
  loadingIds,
  rootName,
  rootId,
  onClick,
  onlyRenderChildren,
  renderCustomContent,
  indent,
  expandRoot,
}) => {
  const treeHook = useState([]);
  const location = useLocation();

  useEffect(() => {
    const ids = extractWorkspaceIds(location.pathname);
    if (ids.length > 0) {
      ids.unshift("root");
      treeHook[1](ids);
    } else if (treeHook[0].length == 0) {
      treeHook[1](["root"]);
    }
  }, []);

  useEffect(() => {
    if (expandRoot && !treeHook[0].some((x) => x == rootId)) {
      treeHook[1]([...treeHook[0], rootId]);
    }
  }, [rootId]);

  const root = useMemo(
    () => ({
      id: rootId ?? "root",
      name: rootName ?? "My Workspace",
      directories: sites,
      directoriesCount: sites.length,
    }),
    [sites]
  );

  return (
    <TreeContext.Provider value={treeHook}>
      <MenuTreeNode
        node={root}
        link={AppRoutes.Workspace}
        className={className}
        onNodeExpanded={(x) => onNodeExpanded(x)}
        totalNodes={totalNodes}
        onLoadMore={onLoadMore}
        loadingIds={loadingIds}
        groupLevel={1}
        onClick={onClick}
        onlyRenderChildren={onlyRenderChildren}
        renderCustomContent={renderCustomContent}
        indent={indent}
      />
    </TreeContext.Provider>
  );
};

export const extractWorkspaceIds = (pathname) => {
  return pathname.split("/").filter((x) => !!x && x != AppRoutes.Workspace);
};

export default MenuTree;
