import React from "react";
import { MaplesPage } from "components/pages";
import { UserDetails } from "features/Admin/UserDetails";
import * as BreadcrumbText from "constants/breadcrumbs";

const UserDetailsPage = ({ match }) => {
  const userId = match.params.id;
  return (
    <MaplesPage headerItems={[BreadcrumbText.Users]}>
      <UserDetails userId={userId} />
    </MaplesPage>
  );
};

export default UserDetailsPage;
