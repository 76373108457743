import { apiGet, apiPut, apiDelete, apiPost } from "@redriver/cinnamon";

const NAMESPACE = "GROUPS/LIST_GROUPS";

const ActionTypes = {
  GetGroups: `${NAMESPACE}/GET_GROUPS`,
  AddGroup: `${NAMESPACE}/ADD_GROUP`,
  GetGroup: `${NAMESPACE}/GET_GROUP`,
  CheckIsGroupNameValid: `${NAMESPACE}/CHECK_VALID_GROUP_NAME`,
  UpdateGroupStatus: `${NAMESPACE}/UPDATE_GROUP_STATUS`,
  UpdateGroup: `${NAMESPACE}/UPDATE_GROUP`,
  DeleteGroup: `${NAMESPACE}/DELETE_GROUP`,
};

export const getGroups = ({ filters, pagination }) => {
  const { pageSize, pageNumber } = pagination;
  const { search, sortName, sortDirection } = filters;
  return apiGet(ActionTypes.GetGroups, "groups", {
    pageSize,
    pageNumber,
    search,
    sortName,
    sortDirection,
  });
};

export const updateGroup = (formData, groupId) => {
  return apiPut(ActionTypes.UpdateGroup, `groups/${groupId}`, formData);
};

export const getGroup = (groupId) =>
  apiGet(ActionTypes.GetGroup, `groups/${groupId}`);

export const addGroup = (data) => apiPost(ActionTypes.AddGroup, "groups", data);

export const checkValidGroupName = (name, currentGroupId = null) =>
  apiGet(
    ActionTypes.CheckIsGroupNameValid,
    "groups/valid-name",
    {
      name,
      currentGroupId,
    },
    {
      debounce: 300,
    }
  );

export const updateGroupStatus = (submitParams) => {
  return apiPut(ActionTypes.UpdateGroupStatus, "groups/status", submitParams);
};

export const deleteGroup = (submitParams) => {
  return apiDelete(ActionTypes.DeleteGroup, "groups", submitParams);
};
