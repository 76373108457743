import React from "react";
import { ListBuilder, Table, Format, ActionBar } from "@redriver/cinnamon";
import { getSiteGroups } from "./actions";
import { Sort } from "constants/enums";
import { SortHeader, RunOnChange } from "components/misc";
import { MaplesLabel } from "components/containers";
import { TablePagination } from "components/pagination";
import FilterSiteGroups from "./FilterSiteGroups";
import SiteGroupActions from "./SiteGroupActions";
import { GroupRoutes } from "constants/routes";
import { useHistory } from "react-router-dom";

const ListSiteGroups = ({ siteId, directoryRef }) => {
  const history = useHistory();
  return (
    <ListBuilder
      withTable
      loadAction={getSiteGroups}
      loadParams={{ siteId, directoryRef }}
      initialFilters={{ sortName: "name", sortDirection: Sort.Asc }}
      initialPagination={{ pageSize: 25, pageNumber: 1 }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      renderList={(tableProps, state, events) => {
        return (
          <div>
            <RunOnChange
              value={state.total}
              func={() => events.onChangeSelection({})}
            />
            <ActionBar>
              <ActionBar.Item>
                <FilterSiteGroups
                  value={state.filters}
                  onChange={events.onChangeFilters}
                />
              </ActionBar.Item>
            </ActionBar>
            <Table
              {...tableProps}
              dataKey="id"
              emptyMessage="No groups found"
              onRowClick={(group) => history.push(GroupRoutes.Group(group.id))}
            >
              <Table.Column
                header={
                  <SortHeader
                    title="Name"
                    field="name"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                field="name"
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Status"
                    field="status"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                render={(group) =>
                  group.isActive ? (
                    <MaplesLabel type="primary">Active</MaplesLabel>
                  ) : (
                    <MaplesLabel type="default">Inactive</MaplesLabel>
                  )
                }
              />

              <Table.Column
                header={
                  <SortHeader
                    title="Sites"
                    field="sites"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                field="sitesCount"
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Users"
                    field="users"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                field="userCount"
              />

              <Table.Column
                header={
                  <SortHeader
                    title="Created"
                    field="created"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                render={(group) => (
                  <Format.DateTime
                    value={group.createdUtc}
                    format="DD MMM YYYY HH:mm"
                  />
                )}
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Modified"
                    field="modified"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                render={(group) =>
                  group.modifiedUtc ? (
                    <Format.DateTime
                      value={group.modifiedUtc}
                      format="DD MMM YYYY HH:mm"
                    />
                  ) : (
                    "-"
                  )
                }
              />

              {directoryRef && (
                <Table.Column
                  header={"Access"}
                  render={(group) => group.access || "None"}
                />
              )}

              <Table.Column
                width="5%"
                align="right"
                render={(group) => <SiteGroupActions group={group} />}
              />
            </Table>
            <TablePagination
              value={state.pagination}
              onChange={events.onChangePagination}
              totalItems={state.total}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>
        );
      }}
    />
  );
};

export default ListSiteGroups;
