import { apiDelete, apiGet, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "ADMIN/USER_DETAILS";

const ActionTypes = {
  GetUserDetails: `${NAMESPACE}/GET_USER`,
  GetUserGroups: `${NAMESPACE}/GET_USER_GROUPS`,
  AddUserGroup: `${NAMESPACE}/ADD_USER_GROUP`,
  RemoveUserGroup: `${NAMESPACE}/REMOVE_USER_GROUP`,
  GetUserSites: `${NAMESPACE}/GET_USER_SITES`,
};

export const getUser = (userId) =>
  apiGet(ActionTypes.GetUserDetails, `users/${userId}`);

export const addUserGroup = ({ groupId }, userId) => {
  return apiPut(ActionTypes.AddUserGroup, `users/${userId}/groups`, {
    groupId,
  });
};

export const getUserGroups = ({ filters, pagination }, userId) => {
  const { pageSize, pageNumber } = pagination;
  const { search, sortName, sortDirection } = filters;
  return apiGet(ActionTypes.GetUserGroups, `users/${userId}/groups`, {
    pageSize,
    pageNumber,
    search,
    sortName,
    sortDirection,
  });
};

export const removeUserGroup = ({ userId, ...params }) => {
  return apiDelete(
    ActionTypes.RemoveUserGroup,
    `users/${userId}/groups`,
    params
  );
};

export const getUserSites = ({ filters, pagination }, userId) => {
  const { pageSize, pageNumber } = pagination;
  const { search, sortName, sortDirection } = filters;
  return apiGet(ActionTypes.GetUserSites, `users/${userId}/sites`, {
    pageSize,
    pageNumber,
    search,
    sortName,
    sortDirection,
  });
};
