import { ActionTypes } from "./actions";
import { produce } from "immer";

const initialState = {
  data: { totalResults: 0, results: [] },
  pagesLoaded: 0,
  loadingIds: [],
};

const populateDirectories = function (directory, newDirs, rootDirectoryId) {
  directory.directories.forEach((d) => {
    populateDirectories(d, newDirs, rootDirectoryId);
  });
  if (directory.id === rootDirectoryId) {
    directory.directories = newDirs.results[0].directories;
  }
};

const addDirectory = function (directory, newDir, directoryId) {
  directory.directories.forEach((d) => {
    addDirectory(d, newDir, directoryId);
  });

  if (directory.id == directoryId) {
    if (!!directory.directories.length || !directory.directoriesCount) {
      directory.directories.push(newDir);
    }
    directory.directoriesCount += 1;
  }
};

const removeDirectory = function (directory, parentDirectoryId, directoryId) {
  if (directory.id == parentDirectoryId) {
    directory.directoriesCount -= 1;
    directory.directories = directory.directories.filter(
      (x) => x.id != directoryId
    );
  }

  directory.directories.forEach((d) => {
    removeDirectory(d, parentDirectoryId, directoryId);
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GetWorkspaceDirectories:
      return {
        ...state,
        data: {
          totalResults: action.customData.reset ? 0 : state.data.totalResults,
          results: action.customData.reset ? [] : state.data.results,
        },
        loadingIds: [...state.loadingIds, action.customData.rootDirectoryId],
      };

    case ActionTypes.SetWorkspaceDirectories:
      if (!action.success) {
        return state;
      }

      const data = produce(state.data, (draft) => {
        if (action.rootDirectoryId) {
          draft.results.forEach((d) =>
            populateDirectories(d, action.response, action.rootDirectoryId)
          );
        } else {
          draft.results = draft.results.concat(action.response.results);
          draft.totalResults = action.response.totalResults;
        }
      });

      return {
        ...state,
        data: data,
        pagesLoaded: action.rootDirectoryId
          ? state.pagesLoaded
          : action.pageNumber,
        loadingIds: state.loadingIds.filter((x) => x != action.rootDirectoryId),
      };

    case ActionTypes.AddWorkspaceDirectory:
      const addedData = produce(state.data, (draft) => {
        draft.results.forEach((d) =>
          addDirectory(
            d,
            {
              id: action.id,
              name: action.name,
              reference: action.reference,
              directories: [],
              directoriesCount: 0,
            },
            action.parentDirectoryId
          )
        );
      });
      return {
        ...state,
        data: addedData,
      };

    case ActionTypes.RemoveWorkspaceDirectory:
      const removedData = produce(state.data, (draft) => {
        draft.results.forEach((d) =>
          removeDirectory(d, action.parentDirectoryId, action.directoryId)
        );
      });
      return {
        ...state,
        data: removedData,
      };
    default:
      return state;
  }
};
