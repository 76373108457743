import {
  apiGet,
  apiPut,
  apiDelete,
  apiPost,
  mockSuccess,
} from "@redriver/cinnamon";

const NAMESPACE = "ORGANISATIONS/LIST_ORGANISATIONS";

const ActionTypes = {
  GetOrganisations: `${NAMESPACE}/GET_ORGANISATIONS`,
  GetOrganisation: `${NAMESPACE}/GET_ORGANISATION`,
  AddOrganisation: `${NAMESPACE}/ADD_ORGANISATION`,
  DeleteOrganisation: `${NAMESPACE}/DELETE_ORGANISATION`,
  UpdateOrganisation: `${NAMESPACE}/UPDATE_ORGANISATION`,
  CheckIsOrganisationNameValid: `${NAMESPACE}/CHECK_VALID_ORGANISATION_NAME`,
};

export const getOrganisation = (organisationId) =>
  apiGet(ActionTypes.GetOrganisation, `organisations/${organisationId}`);

export const addOrganisation = (data) =>
  apiPost(ActionTypes.AddOrganisation, "organisations", data);

export const updateOrganisation = (formData, organisationId) => {
  return apiPut(
    ActionTypes.UpdateOrganisation,
    `organisations/${organisationId}`,
    formData
  );
};

export const checkValidOrganisationName = (
  name,
  currentOrganisationId = null
) =>
  apiGet(
    ActionTypes.CheckIsOrganisationNameValid,
    "organisations/valid-name",
    {
      name,
      currentOrganisationId,
    },
    {
      debounce: 300,
    }
  );

export const getOrganisations = ({ filters, pagination }) => {
  const { pageSize, pageNumber } = pagination;
  const { search, sortName, sortDirection } = filters;
  return apiGet(ActionTypes.GetOrganisations, "organisations", {
    pageSize,
    pageNumber,
    search,
    sortName,
    sortDirection,
  });
};

export const deleteOrganisation = (submitParams) => {
  return apiDelete(
    ActionTypes.DeleteOrganisation,
    "organisations",
    submitParams
  );
};
