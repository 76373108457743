import { apiGet, apiPut, apiPost } from "@redriver/cinnamon";

const NAMESPACE = "ADMIN/LIST_USERS";

const ActionTypes = {
  GetUsers: `${NAMESPACE}/GET_USERS`,
  GetUser: `${NAMESPACE}/GET_USER`,
  AddUsers: `${NAMESPACE}/ADD_USERS`,
  UpdateUserStatus: `${NAMESPACE}/UPDATE_USER_STATUS`,
  DownloadUsers: `${NAMESPACE}/DOWNLOAD_USER`,
};

export const getUsers = ({ filters, pagination }) => {
  const { pageSize, pageNumber } = pagination;
  const { search, sortName, sortDirection, organisationId, status } = filters;
  return apiGet(ActionTypes.GetUsers, "users", {
    pageSize,
    pageNumber,
    search,
    sortName,
    sortDirection,
    organisationId,
    status,
  });
};

export const getUser = (userId) =>
  apiGet(ActionTypes.GetUser, `users/${userId}`);

export const addUsers = (data) => {
  return apiPost(ActionTypes.AddUsers, `users`, data);
};

export const updateUserStatus = (submitParams) => {
  return apiPut(ActionTypes.UpdateUserStatus, "users/status", submitParams);
};

export const downloadUsers = (submitParams) =>
  apiGet(ActionTypes.DownloadUsers, "users/download", submitParams);
