// example of enum object
//
// export const UserType = {
//   Internal: "Internal",
//   External: "External",
// };

export const Sort = {
  Asc: "Asc",
  Desc: "Desc",
};

export const enumOptionsArray = (enumObj) => {
  return Object.keys(enumObj).map((k) => ({ value: k, text: enumObj[k] }));
};

export const SitePermission = {
  None: "None",
  View: "View",
  Edit: "Edit",
};

export const SitePermissionItemType = {
  Site: "Site",
  Directory: "Directory",
  File: "File",
};

export const GroupStatus = {
  Active: "Active",
  Inactive: "Inactive",
};

export const NotificationFrequency = {
  Never: "Never",
  Adhoc: "Adhoc",
  Daily: "Daily",
  Weekly: "Weekly",
  Monthly: "Monthly",
};

export const NotificationFrequencyLabels = {
  [NotificationFrequency.Never]: "Never",
  [NotificationFrequency.Adhoc]: "Ad-hoc",
  [NotificationFrequency.Daily]: "Daily",
  [NotificationFrequency.Weekly]: "Weekly",
  [NotificationFrequency.Monthly]: "Monthly",
};

export const EntityType = {
  File: "File",
  Directory: "Directory",
  Site: "Site",
};

export const UserStatus = {
  Active: "Active",
  Unverified: "Unverified",
  Inactive: "Inactive",
};

export const AuditType = {
  Created: "Created",
  Updated: "Updated",
  Downloaded: "Downloaded",
  Deleted: "Deleted",
  Shared: "Shared",
  Activated: "Activated",
  Deactivated: "Deactivated",
  Moved: "Moved",
  Restored: "Restored",
};

export const AuditTypeLabels = {
  [AuditType.Created]: "Created",
  [AuditType.Updated]: "Updated",
  [AuditType.Downloaded]: "Downloaded",
  [AuditType.Deleted]: "Deleted",
  [AuditType.Shared]: "Shared",
  [AuditType.Activated]: "Activated",
  [AuditType.Deactivated]: "Deactivated",
  [AuditType.Moved]: "Moved",
  [AuditType.Restored]: "Restored",
};
