import React from "react";
import {
  ListBuilder,
  Table,
  Format,
  ActionBar,
  ActionMenu,
} from "@redriver/cinnamon";
import { useHistory } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { getSites, updateSiteStatus, deleteSite } from "./actions";
import { GroupStatus, Sort } from "constants/enums";
import { SortHeader, RunOnChange } from "components/misc";
import { MaplesLabel, DisplayDataSize } from "components/containers";
import { TablePagination } from "components/pagination";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import SiteActions from "./SiteActions";
import FilterSites from "./FilterSites";
import AddSite from "./AddSite";
import BulkActionsModal from "components/modals/BulkActionsModal";
import { SiteRoutes } from "constants/routes";
import DownloadDownloadHistory from "./DownloadDownloadHistory";

const ListSites = () => {
  const history = useHistory();
  return (
    <ListBuilder
      withTable
      loadAction={getSites}
      initialFilters={{ sortName: "name", sortDirection: Sort.Asc }}
      initialPagination={{ pageSize: 25, pageNumber: 1 }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      renderList={(tableProps, state, events) => {
        const totalSelected = state.selection.allSelected
          ? state.total - (state.selection.deselected?.length || 0)
          : state.selection.selected?.length || 0;

        const visibleRowCount = tableProps?.data?.length;
        return (
          <div>
            <RunOnChange
              value={state.total}
              func={() => events.onChangeSelection({})}
            />
            <ActionBar>
              <ActionBar.Item>
                <FilterSites
                  value={state.filters}
                  onChange={events.onChangeFilters}
                />
              </ActionBar.Item>
              <ActionBar.Item fluid align="right">
                <ActionMenu
                  openOnHover={false}
                  disabled={totalSelected == 0}
                  trigger={<Button compact> Actions ({totalSelected}) </Button>}
                >
                  <BulkActionsModal
                    actionName="activate"
                    header="Activate"
                    submitParams={{
                      status: GroupStatus.Active,
                      selected: state.selection.selected,
                      deselected: state.selection.deselected,
                      allSelected: state.selection.allSelected,
                      search: state.filters.search,
                    }}
                    onRefresh={events.onRefresh}
                    submitAction={updateSiteStatus}
                    totalSelected={totalSelected}
                    as={ActionMenu.Item}
                    entityName="site"
                  >
                    Activate
                  </BulkActionsModal>
                  <BulkActionsModal
                    actionName="deactivate"
                    header="Deactivate"
                    submitParams={{
                      status: GroupStatus.Inactive,
                      selected: state.selection.selected,
                      deselected: state.selection.deselected,
                      allSelected: state.selection.allSelected,
                      search: state.filters.search,
                    }}
                    onRefresh={events.onRefresh}
                    submitAction={updateSiteStatus}
                    totalSelected={totalSelected}
                    as={ActionMenu.Item}
                    entityName="site"
                  >
                    Deactivate
                  </BulkActionsModal>
                  <BulkActionsModal
                    actionName="delete"
                    header="Delete"
                    submitParams={{
                      selected: state.selection.selected,
                      deselected: state.selection.deselected,
                      allSelected: state.selection.allSelected,
                      search: state.filters.search,
                    }}
                    onRefresh={events.onRefresh}
                    submitAction={deleteSite}
                    totalSelected={totalSelected}
                    as={ActionMenu.Item}
                    entityName="site"
                  >
                    Delete
                  </BulkActionsModal>
                </ActionMenu>
                <PermissionCheck action={Actions.Create} target={Targets.Site}>
                  <AddSite
                    compact
                    content="Add Site"
                    onAdded={events.onRefresh}
                  />
                </PermissionCheck>
                <DownloadDownloadHistory
                  compact
                  content="Export Download History"
                />
              </ActionBar.Item>
            </ActionBar>
            <Table
              {...tableProps}
              dataKey="id"
              emptyMessage="No sites found"
              selectable
              onRowClick={(site) => history.push(SiteRoutes.Site(site.id))}
            >
              <Table.SelectableColumn
                value={state.selection}
                onChange={events.onChangeSelection}
                totalItems={state.total}
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Name"
                    field="name"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                field="name"
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Status"
                    field="status"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                render={(site) =>
                  site.isActive ? (
                    <MaplesLabel type="primary">Active</MaplesLabel>
                  ) : (
                    <MaplesLabel type="default">Inactive</MaplesLabel>
                  )
                }
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Size"
                    field="size"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                render={(site) => <DisplayDataSize value={site.size} />}
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Groups"
                    field="groups"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                field="groupsCount"
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Users"
                    field="users"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                field="userCount"
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Created"
                    field="created"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                render={(site) => (
                  <Format.DateTime
                    value={site.createdUtc}
                    format="DD MMM YYYY HH:mm"
                  />
                )}
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Modified"
                    field="modified"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                render={(site) =>
                  site.modifiedUtc ? (
                    <Format.DateTime
                      value={site.modifiedUtc}
                      format="DD MMM YYYY HH:mm"
                    />
                  ) : (
                    "-"
                  )
                }
              />
              <Table.Column
                width="5%"
                align="right"
                render={(site, colState) => (
                  <SiteActions
                    site={site}
                    onRefresh={events.onRefresh}
                    itemIndex={colState.itemIndex}
                    visibleRowCount={visibleRowCount}
                  />
                )}
              />
            </Table>
            <TablePagination
              value={state.pagination}
              onChange={events.onChangePagination}
              totalItems={state.total}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>
        );
      }}
    />
  );
};

export default ListSites;
