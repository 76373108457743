import React from "react";
import classNames from "classnames";
import FullStarIcon from "assets/icons/star-full.svg";
import EmptyStarIcon from "assets/icons/star-empty.svg";

const Star = ({ isFull, className, ...props }) => (
  <div className={classNames("star-icon", className)} {...props}>
    <img
      src={isFull ? FullStarIcon : EmptyStarIcon}
      alt={isFull ? "Selected" : "Not selected"}
    />
  </div>
);

export default Star;
