import React, { useContext, useState, useEffect } from "react";
import {
  AJAX_ERROR,
  AJAX_START,
  AJAX_SUCCESS,
  ListBuilder,
  notifyError,
  notifySuccess,
  refreshJwt,
} from "@redriver/cinnamon";
import { useDispatch } from "react-redux";
import { getContent, reorderContent } from "./actions";
import { getDirectorySlug } from "modules/helpers";
import { useHistory } from "react-router-dom";
import { RunOnChange } from "components/misc";
import { TablePagination } from "components/pagination";
import { UploadTarget } from "components/workspace";
import WorkspaceContentTable from "./WorkspaceContentTable";
import WorkspaceContentActionBar from "./WorkspaceContentActionBar";
import { BreadcrumbContext } from "components/pages";
import { createFiles } from "./actions";
import { EntityType, Sort } from "constants/enums";
import UploadProgressModal from "./UploadProgressModal";
import { AJAX_PROGRESS } from "modules/axios";

const WorkspaceContent = ({
  path,
  rootSource = "",
  rootBreadcrumbs = [],
  skipRoot = false,
}) => {
  const [_, setBreadcrumbs] = useContext(BreadcrumbContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const [tableData, setTableData] = useState([]);
  const [canReorder, setCanReorder] = useState(false);
  const [reordering, setReordering] = useState(false);
  const [uploadingState, setUploadingState] = useState({});

  useEffect(() => setReordering(false), [path]);

  if (!Array.isArray(path) || path.length == 0) return null;

  return (
    <ListBuilder
      withTable
      loadAction={getContent}
      loadParams={path}
      initialData={{ results: [] }}
      initialFilters={{ sortName: "name", sortDirection: Sort.Asc }}
      initialPagination={{ pageSize: 25, pageNumber: 1 }}
      totalTransform={(data) => data.totalResults}
      onLoaded={(data) => {
        setTableData(
          data.results.map((x) => ({
            ...x,
            dataKey: JSON.stringify({
              type: x.directory ? EntityType.Directory : EntityType.File,
              id: x.id,
            }),
          }))
        );
        const filteredBreadcrumbs = data.breadcrumbs.filter((_, i) =>
          skipRoot ? i != 0 : true
        );

        var actualLocation =
          rootSource +
          "/" +
          path.filter((_, i) => (skipRoot ? i != 0 : true)).join("/");

        var desiredLocation =
          rootSource +
          "/" +
          filteredBreadcrumbs
            .map((x) => getDirectorySlug(x.key, x.value))
            .join("/");

        if (actualLocation != desiredLocation) {
          history.replace(desiredLocation);
        }
        setBreadcrumbs(
          rootBreadcrumbs.concat(
            filteredBreadcrumbs.map((x, i) => {
              if (i == filteredBreadcrumbs.length - 1) {
                return x.value;
              }
              return {
                content: x.value,
                to:
                  rootSource +
                  "/" +
                  path
                    .filter((_, i) => (skipRoot ? i != 0 : true))
                    .slice(0, i + 1)
                    .join("/"),
              };
            })
          )
        );
      }}
      renderList={(tableProps, state, events) => {
        const totalSelected = state.selection.allSelected
          ? state.total - (state.selection.deselected?.length || 0)
          : state.selection.selected?.length || 0;

        const selectedLength = tableData.filter(
          (x) =>
            !(state.selection.deseleted || []).some((a) => a == x.dataKey) &&
            (!!state.selection.allSelected ||
              (state.selection.selected || []).some((a) => a == x.dataKey))
        ).length;

        const onChangeFilters = (filters) => {
          setCanReorder(!filters.search && !filters.sortName);
          events.onChangeFilters(filters);
        };
        return (
          <div>
            <RunOnChange
              value={state.total}
              func={() => events.onChangeSelection({})}
            />
            <WorkspaceContentActionBar
              canEdit={tableProps.data.canEdit}
              canEditContent={tableProps.data.results.some((x) => {
                return x.canEdit;
              })}
              directoryId={tableProps.data.id}
              siteId={tableProps.data.siteId}
              siteName={tableProps.data.siteName}
              siteRootDirectoryId={tableProps.data.siteRootDirectoryId}
              events={events}
              onRefresh={events.onRefresh}
              onChangeFilters={onChangeFilters}
              path={path}
              filters={state.filters}
              totalSelected={totalSelected}
              selection={state.selection}
              skipRoot={skipRoot}
              rootSource={rootSource}
              canReorder={canReorder}
              reordering={reordering}
              setReordering={(nextReordering) => {
                setReordering(nextReordering);
                if (!nextReordering) {
                  dispatch(
                    reorderContent(
                      {
                        ...state.pagination,
                        orderedItems: tableData.map((x) => {
                          return {
                            id: x.id,
                            type: x.directory
                              ? EntityType.Directory
                              : EntityType.File,
                          };
                        }),
                      },
                      tableProps.data.id
                    )
                  );
                }
              }}
            />

            <UploadTarget
              onDrop={(files) => {
                const toastExpiry = 60000 * 60 * 24;
                dispatch(refreshJwt()).then((jwt) => {
                  createFiles(
                    jwt,
                    {
                      files: files.map((f) => {
                        return { file: f };
                      }),
                    },
                    tableProps.data.id,
                    true,
                    ({ state, progress, response, error }) => {
                      setUploadingState({ state, progress, response });
                      if (state == AJAX_SUCCESS) {
                        const successfulFiles =
                          files.length - response.data.length;
                        if (response.data.length) {
                          response.data.forEach((item) => {
                            dispatch(
                              notifyError(item.value, { expiry: toastExpiry })
                            );
                          });
                        }
                        if (successfulFiles > 0) {
                          dispatch(
                            notifySuccess(
                              `${
                                files.length > 1 ? successfulFiles + " f" : "F"
                              }ile${successfulFiles > 1 ? "s" : ""} uploaded`,
                              { expiry: toastExpiry }
                            )
                          );
                          events.onRefresh();
                        }
                      } else if (state == AJAX_ERROR) {
                        dispatch(notifyError(error, { expiry: toastExpiry }));
                      }
                    }
                  );
                });
              }}
              canUpload={tableProps.data.canEdit}
            >
              <WorkspaceContentTable
                tableProps={tableProps}
                state={state}
                selectedLength={selectedLength}
                onChangeFilters={onChangeFilters}
                reordering={reordering}
                tableData={tableData}
                path={path}
                skipRoot={skipRoot}
                rootSource={rootSource}
                events={events}
                setTableData={setTableData}
              />
            </UploadTarget>
            {!reordering && (
              <TablePagination
                value={state.pagination}
                onChange={events.onChangePagination}
                totalItems={state.total}
                pageSizeOptions={[10, 25, 50, 100]}
              />
            )}
            <UploadProgressModal
              open={
                uploadingState.state == AJAX_START ||
                uploadingState.state == AJAX_PROGRESS
              }
              progress={uploadingState.progress}
            />
          </div>
        );
      }}
    />
  );
};

export default WorkspaceContent;
