import React from "react";
import { MaplesPage } from "components/pages";
import { GroupDetails } from "features/Groups/GroupDetails";

const GroupDetailsPage = ({ match }) => {
  const groupId = match.params.id;
  return (
    <MaplesPage headerItems={["Sites"]}>
      <GroupDetails groupId={groupId} />
    </MaplesPage>
  );
};

export default GroupDetailsPage;
