import { SitePermission } from "constants/enums";

export const getGroupPermissionsDetails = (state) =>
  state.groups.groupPermissions;
export const getGroupPermissionExpanded = (state, nodeId) =>
  !!state.groups.groupPermissions.expandedIds[nodeId];
export const getGroupPermissionLoading = (state, nodeId) =>
  !!state.groups.groupPermissions.loadingIds[nodeId];
export const getGroupPermissionAccess = (state, nodeId) =>
  state.groups.groupPermissions.customisedValues[nodeId].access ||
  SitePermission.None;
export const getGroupPermissionSaving = (state) =>
  !!state.groups.groupPermissions.saving;

export const getGroupPermissionHasPartialChildViewAccess = (state, nodeId) =>
  !!state.groups.groupPermissions.customisedValues[nodeId]
    .hasPartialChildViewAccess;
export const getGroupPermissionHasPartialChildEditAccess = (state, nodeId) =>
  !!state.groups.groupPermissions.customisedValues[nodeId]
    .hasPartialChildEditAccess;
