import React from "react";
import Colours from "constants/colours";

const BinIcon = ({ white = false }) => {
  const colour = white ? Colours.White : Colours.PrimaryBlue;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8.645"
      height="11"
      viewBox="0 0 8.645 11"
    >
      <g id="garbage-can" transform="translate(-54.798)">
        <path
          id="Path_1286"
          data-name="Path 1286"
          d="M63.12,3.115a.322.322,0,0,0,.3-.441A2.044,2.044,0,0,0,61.527,1.4h-.4A1.711,1.711,0,0,0,59.464,0h-.687A1.711,1.711,0,0,0,57.11,1.4h-.4a2.044,2.044,0,0,0-1.893,1.278.322.322,0,0,0,.3.441ZM58.777.645h.688a1.057,1.057,0,0,1,1,.752h-2.7A1.057,1.057,0,0,1,58.777.645Z"
          fill={colour}
        />
        <path
          id="Path_1287"
          data-name="Path 1287"
          d="M90.483,181.342a.97.97,0,0,0,.964.9h4.3a.97.97,0,0,0,.964-.9L97.162,175H90.031Zm4-4.618a.322.322,0,0,1,.644.032l-.172,3.438a.322.322,0,0,1-.644-.032Zm-2.1-.306a.322.322,0,0,1,.338.306l.172,3.438a.322.322,0,0,1-.644.032l-.172-3.437A.322.322,0,0,1,92.378,176.418Z"
          transform="translate(-34.476 -171.24)"
          fill={colour}
        />
      </g>
    </svg>
  );
};

export default BinIcon;
