import React from "react";
import {
  ListBuilder,
  FormBuilder,
  Form,
  ModalBuilder,
  Modal,
  PageSize,
} from "@redriver/cinnamon";
import { MaplesPageNumber } from "components/pagination";
import { getFavouriteSites, getSites, toggleFavouriteSite } from "./actions";
import { Link } from "react-router-dom";
import { WorkspaceRoutes } from "constants/routes";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  getWorkspaceDirectories,
  setWorkspaceDirectories,
} from "features/System/WorkspaceMenu/actions";
import DashboardList from "./DashboardList";
import Star from "./Star";
import { ResultsCounter } from "components/pagination";
import { getDirectorySlug } from "modules/helpers";

const DashboardSites = ({ setFavsTimestamp, favourites, timestamp }) => {
  const dispatch = useDispatch();
  const [toggleFavDetails, setToggleFavDetails] = useState({
    siteId: null,
    isFavourite: null,
  });

  return (
    <section className="dashboard-sites">
      <ListBuilder
        loadAction={favourites ? getFavouriteSites : getSites}
        loadParams={timestamp}
        initialPagination={{ pageSize: 10, pageNumber: 1 }}
        initialFilters={{ search: "" }}
        dataTransform={(data) => data.results}
        totalTransform={(data) => data.totalResults}
        renderLoading={false}
        renderList={(data, state, events) => (
          <React.Fragment>
            <DashboardList
              header={
                <FormBuilder
                  value={state.filters}
                  onChange={events.onChangeFilters}
                  renderForm={(formProps) => (
                    <Form {...formProps}>
                      <header className="dashboard-sites-header">
                        <h2
                          className="medium-header"
                          style={{ marginBottom: "1rem" }}
                        >
                          {favourites ? "My Favourite Sites" : "Sites"}
                        </h2>
                        <div className="dashboard-page-size ">
                          <span style={{ marginRight: "0.5rem" }}>
                            Viewing{" "}
                          </span>
                          <PageSize
                            value={state.pagination.pageSize}
                            onChange={(x) =>
                              events.onChangePagination({
                                pageSize: x,
                                pageNumber: 1,
                              })
                            }
                          />
                        </div>
                      </header>

                      <Form.Input
                        field="search"
                        placeholder="Search"
                        icon="search"
                        fluid
                      />
                    </Form>
                  )}
                />
              }
              loading={state.loading}
              items={data}
              renderItem={(item) => (
                <div key={item.id} className="site-item">
                  <Star
                    isFull={item.isFav}
                    className="pointer"
                    style={{ marginRight: "1rem" }}
                    onClick={() =>
                      setToggleFavDetails({
                        siteId: item.id,
                        isFavourite: !item.isFav,
                      })
                    }
                  />
                  <Link
                    to={WorkspaceRoutes.WorkspaceItem(
                      getDirectorySlug(item.reference, item.name)
                    )}
                  >
                    {item.name}
                  </Link>
                </div>
              )}
              footer={
                data && data.length > 0 ? (
                  <React.Fragment>
                    <ResultsCounter
                      pageNumber={state.pagination.pageNumber}
                      pageSize={state.pagination.pageSize}
                      totalItems={state.total}
                    />
                    <div
                      className="table-pagination"
                      style={{ justifyContent: "center" }}
                    >
                      <MaplesPageNumber
                        ellipsisItem={null}
                        siblingRange={1}
                        value={state.pagination.pageNumber}
                        onChange={(x) =>
                          events.onChangePagination({
                            pageSize: state.pagination.pageSize,
                            pageNumber: x,
                          })
                        }
                        pageSize={state.pagination.pageSize}
                        totalItems={state.total}
                        prevItem={null}
                        nextItem={null}
                        className="mini-pagination"
                      />
                    </div>
                  </React.Fragment>
                ) : null
              }
            />
            <ModalBuilder
              visible={!!toggleFavDetails.siteId}
              submitAction={toggleFavouriteSite}
              submitParams={{
                isFavourite: toggleFavDetails.isFavourite,
                siteId: toggleFavDetails.siteId,
              }}
              onSubmitted={() => {
                setToggleFavDetails({
                  siteId: null,
                  isFavourite: null,
                });
                events.onRefresh();
                // will trigger reload on favs list
                setFavsTimestamp(new Date().getTime());
                // force the nav menu to reload for the updated favs
                dispatch(
                  getWorkspaceDirectories(
                    null,
                    {
                      pageSize: 15,
                      pageNumber: 1,
                    },
                    true
                  )
                ).then((x) => dispatch(setWorkspaceDirectories(x, null, 1)));
              }}
              onCancelled={() => {
                setToggleFavDetails({
                  siteId: null,
                  isFavourite: null,
                });
              }}
              renderModal={(modalProps) => (
                <Modal.Confirmation
                  {...modalProps}
                  header="Change favourite site"
                  cancelLabel="Cancel"
                  submitLabel="Confirm"
                >
                  Are you sure you wish to mark this as{" "}
                  {toggleFavDetails.isFavourite
                    ? "a favourite"
                    : "not a favourite"}
                  ?
                </Modal.Confirmation>
              )}
            />
          </React.Fragment>
        )}
      />
    </section>
  );
};

export default DashboardSites;
