import React from "react";
import { ActionBar, ActionMenu } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import FilterBinContent from "./FilterBinContent";
import RestoreFiles from "./RestoreFiles";

const BinActionBar = ({
  onChangeFilters,
  onRefresh,
  totalSelected,
  filters,
  selection,
}) => {
  const selectedCount = (selection?.selected || []).length;
  return (
    <ActionBar>
      <ActionBar.Item>
        <FilterBinContent value={filters} onChange={onChangeFilters} />
      </ActionBar.Item>
      <ActionBar.Item fluid align="right">
        <PermissionCheck action={Actions.Edit} target={Targets.Bin}>
          <ActionMenu
            openOnHover={false}
            disabled={totalSelected == 0}
            trigger={<Button compact> Actions ({totalSelected}) </Button>}
          >
            <RestoreFiles
              selection={{
                ...selection,
                search: filters.search,
              }}
              as={ActionMenu.Item}
              onSubmitted={onRefresh}
              totalSelected={selectedCount}
            >
              Restore
            </RestoreFiles>
          </ActionMenu>
        </PermissionCheck>
      </ActionBar.Item>
    </ActionBar>
  );
};

export default BinActionBar;
