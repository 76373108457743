import {
  apiGet,
  apiPut,
  apiDelete,
  apiPost,
  mockSuccess,
} from "@redriver/cinnamon";

const NAMESPACE = "WORKSPACE/DASHBOARD";

const ActionTypes = {
  GetSites: `${NAMESPACE}/GET_SITES`,
  ToggleFavouriteSite: `${NAMESPACE}/TOGGLE_FAVOURITE_SITE`,
  GetFavouriteSites: `${NAMESPACE}/GET_FAVOURTIE_SITES`,
  GetActivity: `${NAMESPACE}/GET_ACTIVITY`,
};

export const getSites = ({ filters, pagination }) => {
  const { search } = filters;
  const { pageSize, pageNumber } = pagination;

  return apiGet(ActionTypes.GetSites, `dashboard/sites`, {
    search,
    pageSize,
    pageNumber,
  });
};

export const toggleFavouriteSite = ({ siteId, isFavourite }) => {
  return apiPut(ActionTypes.ToggleFavouriteSite, `dashboard/sites/${siteId}`, {
    isFavourite,
  });
};

export const getFavouriteSites = ({ filters, pagination }) => {
  const { search } = filters;
  const { pageSize, pageNumber } = pagination;

  return apiGet(ActionTypes.GetSites, `dashboard/sites`, {
    search,
    pageSize,
    pageNumber,
    onlyFavourites: true,
  });
};

export const getActivityLogs = ({ pagination }) => {
  const { pageSize, pageNumber } = pagination;
  return apiGet(ActionTypes.GetActivity, `dashboard/activity`, {
    pageSize,
    pageNumber,
  });
};
