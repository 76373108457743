import React from "react";
import { Table } from "@redriver/cinnamon";
import { useHistory } from "react-router-dom";
import { GroupRoutes } from "constants/routes";
import { TableActionMenu } from "components/misc";

const SiteGroupActions = ({ group }) => {
  const history = useHistory();

  return (
    <TableActionMenu>
      <Table.MenuItem onClick={() => history.push(GroupRoutes.Group(group.id))}>
        Go To Group
      </Table.MenuItem>
    </TableActionMenu>
  );
};

export default SiteGroupActions;
