import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, Message } from "semantic-ui-react";
import { FormBuilder, Form, ErrorMessage } from "@redriver/cinnamon";
import { SystemRoutes } from "constants/routes";
import { validateResetToken, requestPasswordReset } from "./actions";
import PasswordForgotten from "../PasswordForgotten";

const PasswordReset = ({ token }) => {
  const [tokenValid, setTokenValid] = useState(null);
  const [resetSuccess, setResetSuccess] = useState(false);

  if (tokenValid === false || !token) {
    return (
      <div>
        <Message negative>
          <p>
            The reset password link expired or is invalid, please request
            another
          </p>
        </Message>
        <PasswordForgotten />
      </div>
    );
  }

  if (resetSuccess) {
    return (
      <div>
        <p>Your password has been reset</p>
        <div style={{ marginTop: 20 }}>
          <Button fluid as={Link} to={SystemRoutes.Login}>
            Continue
          </Button>
        </div>
      </div>
    );
  }

  return (
    <FormBuilder
      loadAction={validateResetToken}
      loadParams={token}
      onLoaded={(response) => setTokenValid(!!response && response.ok)}
      submitAction={requestPasswordReset}
      submitParams={token}
      onSubmitted={() => setResetSuccess(true)}
      renderLoading={false}
      renderSubmitting={false}
      renderError={false}
      renderForm={(
        formProps,
        { loading, slowLoading, submitting, slowSubmitting, error },
        events
      ) => {
        if (loading && slowLoading) return <p>Validating reset token...</p>;
        if (!tokenValid) return null;

        return (
          <Form {...formProps} className="public-form">
            <h1 className="big-header">Setup password</h1>
            <p>Setup your new password</p>
            <Form.Password
              field="newPassword"
              placeholder="Enter new password"
              disabled={submitting && slowSubmitting}
              autoFocus
              required
              minLength={8}
              fluid
              toggleVisibility
            />
            <Form.Password
              field="confirmPassword"
              placeholder="Confirm new password"
              disabled={submitting && slowSubmitting}
              required
              confirmField="newPassword"
              fluid
              toggleVisibility
            />
            <Button
              className="confirm-btn"
              onClick={events.onSubmit}
              disabled={submitting}
              loading={submitting && slowSubmitting}
              fluid
              toggleVisibility
            >
              Reset
            </Button>
            <Link to={SystemRoutes.Login}>Back to Sign In</Link>
            <ErrorMessage error={error} />
          </Form>
        );
      }}
    />
  );
};

PasswordReset.propTypes = {
  token: PropTypes.string.isRequired,
};

export default PasswordReset;
