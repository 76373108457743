import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "SYSTEM/WORKSPACE_MENU";

export const ActionTypes = {
  GetWorkspaceDirectories: `${NAMESPACE}/GET_WORKSPACE_DIRECTORIES`,
  SetWorkspaceDirectories: `${NAMESPACE}/SET_WORKSPACE_DIRECTORIES`,
  AddWorkspaceDirectory: `${NAMESPACE}/ADD_WORKSPACE_DIRECTORIES`,
  RemoveWorkspaceDirectory: `${NAMESPACE}/REMOVE_WORKSPACE_DIRECTORIES`,
};

export const getWorkspaceDirectories = (
  rootDirectoryId,
  pagination,
  reset = false
) => {
  const { pageSize, pageNumber } = pagination;
  return apiGet(
    ActionTypes.GetWorkspaceDirectories,
    "workspace/tree",
    {
      pageSize,
      pageNumber,
      rootDirectoryId,
    },
    { actionCustomData: { rootDirectoryId, reset } }
  );
};

export const setWorkspaceDirectories = (data, rootDirectoryId, pageNumber) => {
  return {
    type: ActionTypes.SetWorkspaceDirectories,
    ...data,
    rootDirectoryId,
    pageNumber,
  };
};

export const addWorkspaceDirectory = (
  parentDirectoryId,
  id,
  name,
  reference
) => {
  return {
    type: ActionTypes.AddWorkspaceDirectory,
    parentDirectoryId,
    id,
    name,
    reference,
  };
};

export const removeWorkspaceDirectory = (parentDirectoryId, directoryId) => {
  return {
    type: ActionTypes.RemoveWorkspaceDirectory,
    parentDirectoryId,
    directoryId,
  };
};
