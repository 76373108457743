import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import {
  connectForm,
  FormUtils,
  FormValidators,
  Field,
} from "@redriver/cinnamon";
import { Icon } from "semantic-ui-react";
import classNames from "classnames";

/**
 * Field for uploading multiple files
 */
class MultipleFileUpload extends React.Component {
  static propTypes = {
    // -------------------
    // field props
    // -------------------

    /**
     * Label text to display alongside the field
     */
    label: PropTypes.node,
    /**
     * Width of the field in approximate number of characters, or a valid CSS width
     */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * Whether the width of the field should automatically fill all available space
     */
    fluid: PropTypes.bool,
    /**
     * Text to display in dropzone area before uploading file
     */
    uploadText: PropTypes.string,
    /**
     * Icon to display in dropzone area before uploading file
     */
    uploadIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /**
     * Text to display in dropzone area after uploading file (displays below filename)
     */
    replaceText: PropTypes.string,
    /**
     * Icon to display in dropzone area after uploading file (displays next to filename)
     */
    replaceIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /**
     * Additional classes for styling
     */
    className: PropTypes.string,

    // -------------------
    // validator props
    // -------------------

    /**
     * Whether this field should be mandatory to completing the form
     */
    required: PropTypes.bool,
    /**
     * Override the default error message for required fields
     */
    requiredError: PropTypes.string,
    /**
     * The file mime types accepted by this field
     */
    fileMimeTypes: PropTypes.arrayOf(PropTypes.string),
    /**
     * The file extension types accepted by this field
     */
    fileExtensionTypes: PropTypes.arrayOf(PropTypes.string),
    /**
     * Override the default error message for fileMimeTypes/fileExtensionTypes fields
     */
    fileTypesError: PropTypes.string,

    // -------------------
    // connectForm props
    // -------------------

    /**
     * Name of this field, and the form data key against which the value will be stored
     */
    field: PropTypes.string.isRequired,
    /**
     * Whether any errors on the field should be displayed, if not specified then inherits from the parent form or fields
     */
    showErrors: PropTypes.bool,
    /**
     * Whether to display all errors for this field or just show one error at a time, if not specified then inherits from the parent form or fields
     */
    allErrors: PropTypes.bool,
    /**
     * Time in milliseconds for field error animation transitions or false to disable, if not specified then inherits from the parent form or fields
     */
    animateErrors: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    /**
     * Whether to disable the field, if not specified then inherits from the parent form or fields
     */
    disabled: PropTypes.bool,
    /**
     * Whether the field should be read-only, if not specified then inherits from the parent form or fields
     * @ignore
     */
    readOnly: PropTypes.bool,
    /**
     * Additional error messages that should be displayed before validator error messages
     */
    customErrors: PropTypes.arrayOf(PropTypes.string),
    /**
     * Function that will be run everytime the field changes to perform additional validation
     * Resulting errors can be passed to customErrors
     */
    customValidator: PropTypes.func,
    /**
     * List of other field names that should be re-validated when this field changes
     */
    notifiedFields: PropTypes.arrayOf(PropTypes.string),
    /**
     * Current value of the field (supplied by the form connection)
     * @ignore
     */
    value: PropTypes.arrayOf(PropTypes.object),
    /**
     * Callback when the field has changed (supplied by the form connection)
     * @ignore
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Array of error messages to display on the field (supplied by the form connection)
     * @ignore
     */
    errors: PropTypes.arrayOf(PropTypes.string),
    /**
     * Props excluding those from the form connection (supplied by the form connection)
     * @ignore
     */
    passThruProps: PropTypes.object,
    /**
     * The current state of the form relative to this field (supplied by the form connection)
     * @ignore
     */
    formState: PropTypes.object,
  };

  static defaultProps = {
    onChange: () => {},
    label: "",
    uploadText: "Drag and drop your file here",
    uploadIcon: "upload",
    replaceText: "Drag and drop another file here to replace",
    replaceIcon: "file",
  };

  onDrop = (files) => {
    if (files) {
      this.props.onChange(files);
    }
  };

  render() {
    const {
      value,
      onChange,
      errors,
      showErrors,
      allErrors,
      animateErrors,
      disabled,
      label,
      width,
      fluid,
      required,
      passThruProps,
      uploadText,
      uploadIcon,
      replaceText,
      replaceIcon,
      className,
    } = this.props;

    const otherProps = FormUtils.omitProps(
      passThruProps,
      Object.keys(MultipleFileUpload.propTypes)
    );

    const totalSelected = value?.length ?? 0;

    return (
      <Field
        required={required}
        disabled={disabled}
        width={width}
        fluid={fluid}
        label={label}
        errors={FormUtils.fieldErrors(errors, showErrors, allErrors)}
        animateErrors={animateErrors}
      >
        <Dropzone onDrop={this.onDrop} disabled={disabled}>
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragAccept,
            isDragReject,
          }) => (
            <div
              {...getRootProps({
                className: classNames(
                  "dropzone",
                  {
                    uploaded: totalSelected > 0,
                    active: isDragActive,
                    accept: isDragAccept,
                    reject: isDragReject,
                    disabled: disabled,
                  },
                  className
                ),
              })}
            >
              <input {...getInputProps()} />
              <div>
                {typeof uploadIcon === "string" ? (
                  <Icon name={uploadIcon} size="big" />
                ) : (
                  uploadIcon
                )}
                <p>{totalSelected > 0 ? replaceText : uploadText}</p>
              </div>
            </div>
          )}
        </Dropzone>
      </Field>
    );
  }
}

export default connectForm({
  displayName: (props) =>
    props.label && typeof props.label === "string"
      ? props.label
      : FormUtils.prettifyField(props.field),
  validators: [FormValidators.requiredField(false), FormValidators.fileTypes()],
})(MultipleFileUpload);
