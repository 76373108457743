export const getInitials = (name) => {
  let names = [];

  if (Array.isArray(name)) {
    names = name;
  } else if (typeof name == "string") {
    names = name.split(" ");
  }
  return names.length > 1
    ? `${names[0].substring(0, 1)}${(names[names.length - 1] || "").substring(
        0,
        1
      )}`
    : names.length > 0
    ? names[0].substring(0, 1)
    : "-";
};
