import React from "react";
import { Table } from "@redriver/cinnamon";
import { withPermissions } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import EditRole from "./EditRole";
import DeleteRole from "./DeleteRole";

const RoleActions = ({ role, onRefresh, hasPermission }) => {
  const access = {
    canEdit: hasPermission(Targets.Role, Actions.Edit),
    canDelete:
      role.totalUsers === 0 && hasPermission(Targets.Role, Actions.Delete),
  };

  if (!Object.values(access).some((x) => x)) return null;

  return (
    <Table.Menu>
      {access.canEdit && (
        <EditRole role={role} onUpdated={onRefresh} as={Table.MenuItem}>
          Edit Role
        </EditRole>
      )}
      {access.canDelete && (
        <DeleteRole role={role} onDeleted={onRefresh} as={Table.MenuItem}>
          Delete Role
        </DeleteRole>
      )}
    </Table.Menu>
  );
};

export default withPermissions(RoleActions);
