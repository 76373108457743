import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { NavSidebar, NotificationStack, NotFound } from "@redriver/cinnamon";
import { AppRoutes, SystemRoutes } from "constants/routes";
import MaplesTeesdaleMenu from "./MaplesTeesdaleMenu";
import DashboardPage from "./DashboardPage";
import AccountPage from "./system/AccountPage";
import RoleRouter from "./roles/RoleRouter";
import UserRouter from "./users/UserRouter";
import { SitesRouter } from "./sites";
import { GroupsRouter } from "./groups";
import BinContentPage from "./bin/BinContentPage";
import ChangeMyPasswordPage from "./system/ChangeMyPasswordPage";
import NotFoundPage from "./NotFoundPage";
import WorkspaceContentPage from "./workspace/WorkspaceContentPage";
import WorkspaceItemRedirectPage from "./workspace/WorkspaceItemRedirectPage";
import StylesPage from "./StylesPage";
import { OrganisationsListPage } from "pages/organisations";
import { EntityType } from "constants/enums";

// the authenticated app
const MaplesTeesdale = () => (
  <article className="app-content">
    <MaplesTeesdaleMenu />
    <Switch>
      <Route
        exact
        path={AppRoutes.Root}
        render={() => (
          <Redirect from={AppRoutes.Root} to={AppRoutes.Workspace} />
        )}
      />

      <Route exact path={AppRoutes.Workspace} component={DashboardPage} />
      <Route
        exact
        path={AppRoutes.FileRedirect()}
        render={(props) => (
          <WorkspaceItemRedirectPage {...props} entityType={EntityType.File} />
        )}
      />
      <Route
        exact
        path={AppRoutes.DirectoryRedirect()}
        render={(props) => (
          <WorkspaceItemRedirectPage
            {...props}
            entityType={EntityType.Directory}
          />
        )}
      />
      <Route
        exact
        path={AppRoutes.SiteRedirect()}
        render={(props) => (
          <WorkspaceItemRedirectPage {...props} entityType={EntityType.Site} />
        )}
      />
      <Route path={AppRoutes.Workspace} component={WorkspaceContentPage} />
      <Route exact path={SystemRoutes.Account} component={AccountPage} />
      <Route
        exact
        path={SystemRoutes.ChangePassword}
        component={ChangeMyPasswordPage}
      />
      <Route exact path={AppRoutes.Bin} component={BinContentPage} />
      <Route path={AppRoutes.Roles} component={RoleRouter} />
      <Route path={AppRoutes.Users} component={UserRouter} />
      <Route path={AppRoutes.Sites} component={SitesRouter} />
      <Route path={AppRoutes.Groups} component={GroupsRouter} />
      <Route path={AppRoutes.Styles} component={StylesPage} />
      <Route path={AppRoutes.Organisations} component={OrganisationsListPage} />
      <Route component={NotFoundPage} />
    </Switch>
    <NotificationStack />
  </article>
);

export default MaplesTeesdale;
