import React from "react";
import PropTypes from "prop-types";
import Colours from "constants/colours";

const ArrowIcon = ({ white = false, direction = "right" }) => {
  const colour = white ? Colours.White : Colours.PrimaryBlue;

  switch (direction) {
    case "right":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="5"
          height="8"
          viewBox="0 0 5 8"
        >
          <path
            id="Account_Arrow"
            data-name="Account Arrow"
            d="M3.61.488a.5.5,0,0,1,.781,0l2.96,3.7A.5.5,0,0,1,6.96,5H1.04a.5.5,0,0,1-.39-.812Z"
            transform="translate(5) rotate(90)"
            fill={colour}
          />
        </svg>
      );
    case "down":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="5"
          viewBox="0 0 8 5"
        >
          <path
            id="Account_Arrow"
            data-name="Account Arrow"
            d="M3.61.488a.5.5,0,0,1,.781,0l2.96,3.7A.5.5,0,0,1,6.96,5H1.04a.5.5,0,0,1-.39-.812Z"
            transform="translate(8 5) rotate(180)"
            fill={colour}
          />
        </svg>
      );
    default:
      return null;
  }
};

ArrowIcon.propTypes = {
  direction: PropTypes.oneOf(["right", "down"]),
  white: PropTypes.bool,
};

export default ArrowIcon;
