import React from "react";
import { MaplesPage } from "components/pages";
import { useLocation } from "react-router-dom";
import { extractWorkspaceIds } from "features/System/TreeMenu";
import { WorkspaceContent } from "features/Workspace/WorkspaceContent";
import { AppRoutes } from "constants/routes";
import * as Breadcrumbs from "constants/breadcrumbs";

const WorkspaceContentPage = () => {
  const location = useLocation();

  const ids = extractWorkspaceIds(location.pathname);
  const lastId = ids.length > 0 ? ids[ids.length - 1] : null;

  // TODO: use lookup to get header details about item for building breadcrumbs?

  return (
    <MaplesPage headerItems={["Workspace Item"]}>
      <WorkspaceContent
        path={ids.splice(1, ids.length)}
        rootSource={AppRoutes.Workspace}
        rootBreadcrumbs={[
          { content: Breadcrumbs.Workspace, to: AppRoutes.Workspace },
        ]}
      />
    </MaplesPage>
  );
};

export default WorkspaceContentPage;
