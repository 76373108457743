import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "SITES/GROUPS";

const ActionTypes = {
  GetSiteGroups: `${NAMESPACE}/GET_SITE_GROUPS`,
};

export const getSiteGroups = (
  { filters, pagination },
  { siteId, directoryRef }
) => {
  const { pageSize, pageNumber } = pagination;
  const { search, sortName, sortDirection } = filters;
  return apiGet(ActionTypes.GetSiteGroups, `sites/${siteId}/groups`, {
    pageSize,
    pageNumber,
    search,
    sortName,
    sortDirection,
    directoryRef,
  });
};
