import React from "react";
import classNames from "classnames";
import { Image } from "semantic-ui-react";
import Logo from "assets/logo.svg";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "constants/routes";

const PublicPageContent = ({ className, children }) => {
  const history = useHistory();
  return (
    <div className={classNames("public-page-content", className)}>
      <section className="content-wrapper">
        <div className="logo-container">
          <Image
            src={Logo}
            alt="MaplesTeesdale"
            className="pointer"
            onClick={() => history.push(AppRoutes.Root)}
          />
        </div>
        <div className="content">{children}</div>
      </section>
      <section className="background"></section>
    </div>
  );
};

export default PublicPageContent;
