import React from "react";
import { Button } from "semantic-ui-react";
import { Table } from "@redriver/cinnamon";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { removeUserGroup } from "./actions";

const RemoveGroupFromUser = ({
  as: As = Button,
  userId,
  groupId,
  groupName,
  onUpdated,
}) => {
  return (
    <ModalBuilder
      submitAction={removeUserGroup}
      submitParams={{
        selected: [groupId],
        userId: userId,
      }}
      onSubmitted={onUpdated}
      renderTrigger={(showModal) => (
        <Table.MenuItem onClick={showModal}>Remove Group</Table.MenuItem>
      )}
      renderModal={(modalProps) => (
        <Modal.Confirmation
          {...modalProps}
          header="Remove Group"
          cancelLabel="Cancel"
          submitLabel="Confirm"
        >
          <p>Are you sure you wish to remove {groupName} from this user?</p>
        </Modal.Confirmation>
      )}
    />
  );
};

export default RemoveGroupFromUser;
