import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ListBuilder,
  Table,
  ActionBar,
  Form,
  FormBuilder,
} from "@redriver/cinnamon";
import {
  listGroupPermissions,
  toggleExpandedNode,
  setAccessForPermissionNode,
  updateSitePermissions,
} from "./actions";
import {
  Checkbox as SCheckbox,
  Table as STable,
  Loader,
  Button,
} from "semantic-ui-react";
import { ArrowIcon } from "components/icons";
import {
  getGroupPermissionsDetails,
  getGroupPermissionExpanded,
  getGroupPermissionLoading,
  getGroupPermissionAccess,
  getGroupPermissionSaving,
  getGroupPermissionHasPartialChildViewAccess,
  getGroupPermissionHasPartialChildEditAccess,
} from "./selectors";
import { DirectoryIcon, FileIcon, SitesIcon } from "components/icons";
import { SitePermission, SitePermissionItemType } from "constants/enums";
import classNames from "classnames";

const maxIndentDepth = 10;

const GroupPermissionsList = ({ groupId }) => {
  const dispatch = useDispatch();
  const saving = useSelector((s) => getGroupPermissionSaving(s));
  const permissionDetails = useSelector((s) => getGroupPermissionsDetails(s));
  const { permissionData, loading } = permissionDetails;

  const [filterData, setFilterData] = useState({ search: "" });

  const filteredPermissionData = useMemo(() => {
    return permissionData.filter((x) =>
      filterData.search
        ? x.name.toLowerCase().includes(filterData.search.toLowerCase().trim())
        : true
    );
  }, [filterData.search, permissionData]);

  useEffect(() => {
    dispatch(listGroupPermissions(groupId));
  }, []);

  return (
    <div className="site-permissions">
      <FormBuilder
        value={filterData}
        onChange={setFilterData}
        renderForm={(formProps) => {
          return (
            <Form {...formProps}>
              <ActionBar>
                <ActionBar.Item>
                  <Form.Input field="search" placeholder="Search site" />
                </ActionBar.Item>
                <ActionBar.Item fluid align="right">
                  <Button
                    onClick={() => dispatch(updateSitePermissions())}
                    loading={saving}
                  >
                    Save
                  </Button>
                </ActionBar.Item>
              </ActionBar>
            </Form>
          );
        }}
      />

      <STable>
        <STable.Header>
          <STable.Row>
            <STable.HeaderCell>Name</STable.HeaderCell>
            <STable.HeaderCell className="permission-column">
              Edit/Upload
            </STable.HeaderCell>
            <STable.HeaderCell className="permission-column">
              View
            </STable.HeaderCell>
          </STable.Row>
        </STable.Header>
        <STable.Body>
          {loading && (
            <STable.Row>
              <STable.Cell colSpan="3">
                <div className="loading-row">
                  <Loader active />
                </div>
              </STable.Cell>
            </STable.Row>
          )}
          {!loading &&
            filteredPermissionData.map((x) => (
              <PermissionRow key={x.id} item={x} />
            ))}
          {!loading && filteredPermissionData.length == 0 && (
            <STable.Row>
              <STable.Cell colSpan={3} style={{ textAlign: "center" }}>
                No sites found
              </STable.Cell>
            </STable.Row>
          )}
        </STable.Body>
      </STable>
    </div>
  );
};

const PermissionRow = ({ item, depth = 0, getParentIds = () => [] }) => {
  const dispatch = useDispatch();
  const access = useSelector((s) => getGroupPermissionAccess(s, item.id));
  const isExpanded = useSelector((s) => getGroupPermissionExpanded(s, item.id));
  const isLoadingChildren = useSelector((s) =>
    getGroupPermissionLoading(s, item.id)
  );
  // parent ids are in order they are accessed from the root
  const parentIds = useMemo(() => {
    return getParentIds ? getParentIds() : [];
  }, [getParentIds]);
  const getNextParentIds = useCallback(() => [...parentIds, item.id], [
    parentIds,
  ]);

  const hasPartialChildViewAccess = useSelector((s) =>
    getGroupPermissionHasPartialChildViewAccess(s, item.id)
  );

  const hasPartialChildEditAccess = useSelector((s) =>
    getGroupPermissionHasPartialChildEditAccess(s, item.id)
  );

  const saving = useSelector((s) => getGroupPermissionSaving(s));

  const isViewChecked =
    access == SitePermission.Edit || access == SitePermission.View;
  const isEditChecked = access == SitePermission.Edit;

  return (
    <React.Fragment>
      <STable.Row>
        <STable.Cell>
          <div
            className="item-title"
            style={{
              marginLeft: `calc(1rem * ${
                depth > maxIndentDepth ? maxIndentDepth : depth
              })`,
            }}
            data-node-id={item.id}
          >
            <span
              className={classNames(
                "item-name-group",
                item.hasChildItems ? "pointer" : null
              )}
              onClick={() =>
                item.hasChildItems
                  ? dispatch(toggleExpandedNode(item, parentIds))
                  : null
              }
            >
              <span
                className={classNames(
                  "arrow-wrapper",
                  item.hasChildItems ? null : "hidden"
                )}
              >
                <ArrowIcon direction={isExpanded ? "down" : "right"} />
              </span>
              <span className="icon-wrapper">
                {item.type == SitePermissionItemType.Site ? (
                  <SitesIcon className="site-icon" />
                ) : item.type == SitePermissionItemType.Directory ? (
                  <DirectoryIcon inline />
                ) : (
                  <FileIcon inline fileName={item.name} />
                )}
              </span>
              <span className="name-text">{item.name}</span>
            </span>
            {isLoadingChildren && <Loader inline active size="tiny" />}
          </div>
        </STable.Cell>
        <STable.Cell>
          <SCheckbox
            className={classNames({
              minor: hasPartialChildEditAccess && !isEditChecked,
              partial: hasPartialChildEditAccess,
            })}
            checked={isEditChecked}
            disabled={saving}
            onChange={(e, { checked }) => {
              const toFullCheckedFromPartial =
                !checked && isEditChecked && hasPartialChildEditAccess;

              dispatch(
                setAccessForPermissionNode(
                  item,
                  parentIds,
                  toFullCheckedFromPartial
                    ? SitePermission.Edit
                    : checked
                    ? SitePermission.Edit
                    : access != SitePermission.None
                    ? SitePermission.View
                    : SitePermission.None
                )
              );
            }}
          />
        </STable.Cell>
        <STable.Cell>
          <SCheckbox
            className={classNames({
              minor: hasPartialChildViewAccess && !isViewChecked,
              partial: hasPartialChildViewAccess,
            })}
            checked={isViewChecked}
            disabled={saving}
            onChange={(e, { checked }) => {
              const toFullCheckedFromPartial =
                !checked && isViewChecked && hasPartialChildViewAccess;

              dispatch(
                setAccessForPermissionNode(
                  item,
                  parentIds,
                  toFullCheckedFromPartial
                    ? SitePermission.View
                    : checked
                    ? SitePermission.View
                    : SitePermission.None
                )
              );
            }}
          />
        </STable.Cell>
      </STable.Row>
      {isExpanded &&
        Array.isArray(item.items) &&
        item.items.map((x) => (
          <PermissionRow
            key={x.id}
            item={x}
            depth={depth + 1}
            getParentIds={getNextParentIds}
          />
        ))}
    </React.Fragment>
  );
};

export default GroupPermissionsList;
