import React, { useState } from "react";
import { connect } from "react-redux";
import {
  FormBuilder,
  Form,
  Content,
  ErrorMessage,
  getAuthClaims,
} from "@redriver/cinnamon";
import { Button, Message, Grid } from "semantic-ui-react";
import { requestPasswordChange } from "./actions";

const passwordErrors = {
  911003: "Incorrect old password",
};

const PasswordChange = ({ userEmail }) => {
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  return (
    <FormBuilder
      submitAction={requestPasswordChange}
      submitParams={userEmail}
      onSubmit={() => setPasswordUpdated(false)}
      onSubmitted={() => setPasswordUpdated(true)}
      renderSubmitting={false}
      renderError={false}
      renderForm={(
        formProps,
        { submitting, slowSubmitting, error },
        events
      ) => (
        <Content>
          <Form {...formProps}>
            <Grid>
              <Grid.Column width={5}>
                <Form.Password
                  field="oldPassword"
                  label="Old Password"
                  disabled={submitting && slowSubmitting}
                  fluid
                  required
                />
              </Grid.Column>
              <Grid.Column width={5}>
                <Form.Password
                  field="newPassword"
                  label="New Password"
                  disabled={submitting && slowSubmitting}
                  fluid
                  required
                  minLength={8}
                />
              </Grid.Column>
              <Grid.Column width={6}>
                <Form.Password
                  field="confirmPassword"
                  label="Confirm New Password"
                  disabled={submitting && slowSubmitting}
                  fluid
                  required
                  confirmField="newPassword"
                />
              </Grid.Column>
            </Grid>
          </Form>
          <Content marginTop="large" style={{ textAlign: "right" }}>
            <ErrorMessage error={error} overrideMessages={passwordErrors} />
            {passwordUpdated && (
              <Message
                success
                header="Success"
                content="Your password has been updated"
              />
            )}
            <Button
              primary
              icon="check"
              content="Update Password"
              onClick={() => events.onSubmit({ reset: ["data"] })}
              disabled={submitting}
              loading={submitting && slowSubmitting}
            />
          </Content>
        </Content>
      )}
    />
  );
};

const mapStateToProps = (state) => {
  const { userEmail } = getAuthClaims(state);
  return { userEmail };
};

export default connect(mapStateToProps)(PasswordChange);
