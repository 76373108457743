import React from "react";
import { Progress } from "semantic-ui-react";

const UploadIndicator = ({ percent }) => (
  <div className="upload-indicator">
    <p>Files uploading. Do not close this page.</p>
    <Progress percent={percent} indicating progress />
  </div>
);

export default UploadIndicator;
