import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { getRole, updateRole } from "./actions";
import RoleForm from "./RoleForm";

const EditRole = ({ as: As = Button, role, onUpdated, ...props }) => (
  <ModalBuilder
    withForm
    loadAction={getRole}
    loadParams={role.id}
    submitAction={updateRole}
    submitParams={role.id}
    onSubmitted={onUpdated}
    renderTrigger={showModal => <As onClick={showModal} {...props} />}
    renderModal={(modalProps, formProps) => (
      <Modal.Edit {...modalProps} header="Edit Role">
        <RoleForm {...formProps} />
      </Modal.Edit>
    )}
  />
);

export default EditRole;
