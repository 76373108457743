import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal, Form, useFormState } from "@redriver/cinnamon";
import { getSite, updateSite } from "./actions";
import { checkValidSiteName } from "./actions";
import {
  getWorkspaceDirectories,
  setWorkspaceDirectories,
} from "features/System/WorkspaceMenu/actions";

const EditSite = ({ site, as: As = Button, onUpdated, ...props }) => {
  const dispatch = useDispatch();
  const formState = useFormState();

  const [checkingName, setCheckingName] = useState(false);
  const [isValidName, setIsValidName] = useState(true);
  const [formValid, setFormValid] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const setNameIsValidFromResponse = useCallback(
    (response) => {
      setIsValidName(
        !!response &&
          (!formState.errors.name || formState.errors.name.length == 0)
      );
    },
    [formState.errors]
  );

  return (
    <ModalBuilder
      withForm
      loadAction={getSite}
      loadParams={site.id}
      submitAction={updateSite}
      submitParams={site.id}
      onSubmitted={() => {
        dispatch(
          getWorkspaceDirectories(null, { pageSize: 15, pageNumber: 1 }, true)
        ).then((x) => dispatch(setWorkspaceDirectories(x, null, 1)));
        onUpdated();
      }}
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps) => (
        <Modal
          {...modalProps}
          header="Edit Site Name"
          submitLabel="Save"
          size="tiny"
        >
          <Form
            {...formProps}
            onValidated={(isValid) => setFormValid(isValid)}
            showErrors={showErrors}
          >
            {(fields, errors) => (
              <React.Fragment>
                <Form.Input
                  label="Name"
                  field="name"
                  fluid
                  required
                  showErrors={showErrors || (!isValidName && !!fields.name)}
                  customValidator={() => {
                    if (
                      formProps.value.name &&
                      formProps.value.name != site.name
                    ) {
                      setCheckingName(true);
                      dispatch(
                        checkValidSiteName(formProps.value.name, site.id)
                      )
                        .then((result) => {
                          if (result.success && result.response != null) {
                            setNameIsValidFromResponse(result.response);
                          }
                        })
                        .finally(() => setCheckingName(false));
                    }
                  }}
                  customErrors={
                    !checkingName && !isValidName && !!fields.name
                      ? ["Duplicate site name, please choose another"]
                      : errors.name
                  }
                />
              </React.Fragment>
            )}
          </Form>
        </Modal>
      )}
    />
  );
};

export default EditSite;
