import React from "react";
import { MaplesPage } from "components/pages";
import { WorkspaceDashboard } from "features/Workspace/WorkspaceDashboard";

const DashboardPage = () => (
  <MaplesPage headerItems={["My Workspace"]}>
    <WorkspaceDashboard />
  </MaplesPage>
);

export default DashboardPage;
