import React from "react";
import classNames from "classnames";
import { PageContent } from "@redriver/cinnamon";

const AppPageContent = ({ className, children }) => (
  <PageContent className={classNames("app-page-content", className)}>
    {children}
  </PageContent>
);

export default AppPageContent;
