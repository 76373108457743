import React from "react";
import { Segment, Loader } from "semantic-ui-react";
import classNames from "classnames";

const DashboardList = ({
  className,
  header,
  footer,
  items = [],
  renderItem,
  loading,
  keyName = "id",
  noDataText = "No items found",
}) => {
  return (
    <Segment className={classNames("dashboard-list", className)}>
      <header>{header}</header>
      {loading ? (
        <div className="loader-wrapper">
          <Loader active />
        </div>
      ) : (
        <React.Fragment>
          {items.map((item, idx) => (
            <div className="dashboard-item" key={item[keyName]}>
              {renderItem(item, idx)}
            </div>
          ))}
          {!Array.isArray(items) || items.length == 0 ? (
            <div className="dashboard-item">{noDataText}</div>
          ) : null}
        </React.Fragment>
      )}
      <footer>{footer}</footer>
    </Segment>
  );
};

export default DashboardList;
