import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import { restore, containsLegacy } from "./actions";
import { sitesTypeAhead } from "../lookups";

const RestoreFiles = ({
  as: As = Button,
  selection,
  onSubmitted = () => {},
  totalSelected = 1,
  fileName,
  ...props
}) => {
  const dispatch = useDispatch();
  const [siteRequired, setSiteRequired] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <React.Fragment>
      <ModalBuilder
        withForm
        renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
        submitAction={restore}
        submitParams={{ ...selection }}
        onSubmitted={onSubmitted}
        onLoad={() => {
          setSiteRequired(false);
          setLoading(true);

          dispatch(containsLegacy({ ...selection })).then((result) => {
            setLoading(false);
            setSiteRequired(result.response);
          });
        }}
        renderModal={(modalProps, formProps, state, events) => (
          <Modal
            {...modalProps}
            header={"Restore File" + (totalSelected > 1 ? "s" : "")}
            submitLabel="Yes"
            cancelLabel="No"
            size="tiny"
            loading={modalProps.loading || loading}
          >
            <Form {...formProps}>
              <p>
                Are you sure you wish to restore{" "}
                <b>
                  {fileName ||
                    totalSelected + " file" + (totalSelected > 1 ? "s" : "")}
                  ?
                </b>
              </p>
              {siteRequired && (
                <Form.TypeAhead
                  field="siteId"
                  label="Site"
                  placeholder="Select a site"
                  lookup={sitesTypeAhead}
                  required={siteRequired}
                />
              )}
            </Form>
          </Modal>
        )}
      />
    </React.Fragment>
  );
};

export default RestoreFiles;
