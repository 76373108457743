import FileSaver from "file-saver";

const convertToBlob = async (data) => {
  if (data instanceof Blob) {
    return data;
  } else if (data instanceof Response) {
    return await data.blob();
  } else if (typeof data === "string") {
    return new Blob([data]);
  }
  return null;
};

export const saveFile = async (data, filename) => {
  const blob = await convertToBlob(data);
  if (!blob) {
    console.warn(`Unable to download ${filename} (file type not supported)`);
    return null;
  }
  FileSaver.saveAs(blob, filename);
};

export const openFile = async (data, filename) => {
  const blob = await convertToBlob(data);
  if (!blob) {
    console.warn(`Unable to open ${filename} (file type not supported)`);
    return null;
  }
  const fileURL = URL.createObjectURL(blob);
  window.open(fileURL);
};

export const getFileExtension = (fileName) => {
  const match = (fileName || "").trim().match(/(?:\.([^.]+))?$/);
  return match && match.length > 1 ? (match[1] || "").toLowerCase() : undefined;
};

export const stripFileExtension = (fileName) => {
  return (fileName || "").trim().replace(/(?:\.([^.]+))?$/, "");
};
