import React from "react";
import { useHistory } from "react-router-dom";
import { ListBuilder, PageSize, Format } from "@redriver/cinnamon";
import { MaplesPageNumber } from "components/pagination";
import { getActivityLogs } from "./actions";
import DashboardList from "./DashboardList";
import {
  FileIcon,
  DirectoryIcon,
  ShareIcon,
  SitesIcon,
} from "components/icons";
import { stripFileExtension } from "modules/helpers";
import { AppRoutes, SiteRoutes } from "constants/routes";
import { Actions, Targets } from "constants/permissions";
import { EntityType } from "constants/enums";
import { withPermissions } from "components/auth";

const DashboardActivity = ({ hasPermission }) => {
  const history = useHistory();
  const hasSitePermission = hasPermission(Targets.Site, Actions.View);
  return (
    <section className="dashboard-activity">
      <ListBuilder
        loadAction={getActivityLogs}
        initialPagination={{ pageSize: 10, pageNumber: 1 }}
        dataTransform={(data) => data.results}
        totalTransform={(data) => data.totalResults}
        renderLoading={false}
        renderList={(data, state, events) => (
          <React.Fragment>
            <DashboardList
              loading={state.loading}
              header={
                <div className="header-wrapper">
                  <h2 className="medium-header" style={{ marginBottom: 0 }}>
                    Recent Activity
                  </h2>
                  <div className="dashboard-page-size ">
                    <span style={{ marginRight: "0.5rem" }}>Viewing </span>
                    <PageSize
                      value={state.pagination.pageSize}
                      onChange={(x) =>
                        events.onChangePagination({
                          pageSize: x,
                          pageNumber: 1,
                        })
                      }
                    />
                  </div>
                </div>
              }
              items={data}
              renderItem={(item) => (
                <div
                  key={item.id}
                  className="content-item pointer"
                  onClick={() => {
                    if (item.entityType == EntityType.Site) {
                      history.push(
                        hasSitePermission
                          ? SiteRoutes.Site(item.contentId)
                          : AppRoutes.SiteRedirect(item.contentId)
                      );
                    } else if (item.entityType == EntityType.Directory) {
                      history.push(AppRoutes.DirectoryRedirect(item.contentId));
                    } else {
                      history.push(AppRoutes.FileRedirect(item.contentId));
                    }
                  }}
                >
                  {item.auditType == "Shared" ? (
                    <ShareIcon style={{ width: 24 }} />
                  ) : item.entityType == EntityType.Directory ? (
                    <DirectoryIcon />
                  ) : item.entityType == EntityType.Site ? (
                    <SitesIcon className="site-icon" />
                  ) : (
                    <FileIcon fileName={item.name} />
                  )}
                  <div className="content-details">
                    <span className="content-name">
                      {item.entityType == EntityType.Directory ||
                      item.entityType == EntityType.Site
                        ? item.name
                        : stripFileExtension(item.name)}
                    </span>
                    <span className="content-activity">
                      {item.auditType} -{" "}
                      <Format.Date
                        value={item.timestampUtc}
                        format="DD MMM YYYY HH:mm"
                      />
                    </span>
                  </div>
                </div>
              )}
              footer={
                data && data.length > 0 ? (
                  <div
                    className="table-pagination"
                    style={{ justifyContent: "center" }}
                  >
                    <MaplesPageNumber
                      ellipsisItem={null}
                      siblingRange={1}
                      value={state.pagination.pageNumber}
                      onChange={(x) =>
                        events.onChangePagination({
                          pageSize: state.pagination.pageSize,
                          pageNumber: x,
                        })
                      }
                      pageSize={state.pagination.pageSize}
                      totalItems={state.total}
                      prevItem={null}
                      nextItem={null}
                      className="mini-pagination"
                    />
                  </div>
                ) : null
              }
            />
          </React.Fragment>
        )}
      />
    </section>
  );
};

export default withPermissions(DashboardActivity);
