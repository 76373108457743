import { ActionTypes } from "./actions";
import { produce } from "immer";

const initialState = {
  data: { totalResults: 0, results: [] },
  pagesLoaded: 0,
  loadingIds: [],
};

const populateDirectories = function (directory, newDirs, rootDirectoryId) {
  directory.directories.forEach((d) => {
    populateDirectories(d, newDirs, rootDirectoryId);
  });
  if (directory.id === rootDirectoryId) {
    directory.directories = newDirs.results[0].directories;
  }
};
export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ResetWorkspaceDirectories:
      return {
        ...state,
        ...initialState,
      };
    case ActionTypes.GetWorkspaceDirectories:
      return {
        ...state,
        data: {
          totalResults: action.customData.reset ? 0 : state.data.totalResults,
          results: action.customData.reset ? [] : state.data.results,
        },
        loadingIds: [...state.loadingIds, action.customData.rootDirectoryId],
      };

    case ActionTypes.SetWorkspaceDirectories:
      if (!action.success) {
        return state;
      }

      const data = produce(state.data, (draft) => {
        if (!action.root) {
          draft.results.forEach((d) =>
            populateDirectories(d, action.response, action.rootDirectoryId)
          );
        } else {
          draft.results = draft.results.concat(action.response.results);
          draft.totalResults = action.response.totalResults;
        }
      });

      return {
        ...state,
        data: data,
        pagesLoaded: action.rootDirectoryId
          ? state.pagesLoaded
          : action.pageNumber,
        loadingIds: state.loadingIds.filter((x) => x != action.rootDirectoryId),
      };
    default:
      return state;
  }
};
