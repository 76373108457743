import React from "react";
import { PublicPageContent } from "components/pages";
import { SharedFileDisplay } from "features/System/SharedFileDisplay";

const SharedFilePage = ({ match }) => {
  const token = match.params.token || "";

  return (
    <PublicPageContent>
      <SharedFileDisplay token={token} />
    </PublicPageContent>
  );
};

export default SharedFilePage;
