import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal, Form, lookupReset } from "@redriver/cinnamon";
import { addUserGroup } from "./actions";
import { useDispatch } from "react-redux";
import { groupsLookup } from "modules/lookups";

const AddGroupToUser = ({ as: As = Button, onAdded, userId, ...props }) => {
  const dispatch = useDispatch();

  return (
    <ModalBuilder
      withForm
      initialData={{
        groupId: null,
      }}
      submitAction={addUserGroup}
      submitParams={userId}
      onSubmitted={() => {
        if (onAdded) onAdded();
        dispatch(lookupReset(groupsLookup));
      }}
      renderTrigger={(showModal) => (
        <As onClick={showModal} {...props} content="Add Group" />
      )}
      renderModal={(modalProps, formProps, state, events) => (
        <Modal
          {...modalProps}
          header="Add Group to User"
          submitLabel="Add"
          size="tiny"
        >
          <Form {...formProps}>
            {(fields, errors) => (
              <React.Fragment>
                <Form.TypeAhead
                  label="Group"
                  field="groupId"
                  fluid
                  required
                  lookup={groupsLookup}
                  placeholder="Search for group"
                  lookupParams={{ userId: userId }}
                />
              </React.Fragment>
            )}
          </Form>
        </Modal>
      )}
    />
  );
};

export default AddGroupToUser;
