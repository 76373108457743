import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { deleteRole } from "./actions";

const DeleteRole = ({ as: As = Button, role, onDeleted, ...props }) => (
  <ModalBuilder
    submitAction={deleteRole}
    submitParams={role.id}
    onSubmitted={onDeleted}
    renderTrigger={showModal => <As onClick={showModal} {...props} />}
    renderModal={modalProps => (
      <Modal.Confirmation {...modalProps} header="Delete Role">
        Are you sure you wish to delete {role.name}?
      </Modal.Confirmation>
    )}
  />
);

export default DeleteRole;
