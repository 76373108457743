import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { TriggerBuilder } from "@redriver/cinnamon-core";
import { downloadUsers } from "./actions";
import FileSaver from "file-saver";

const DownloadUsers = ({ as: As = Button, selection, filters, ...props }) => {
  return (
    <TriggerBuilder
      submitAction={downloadUsers}
      submitParams={{
        ...selection,
        ...filters,
      }}
      onSubmitted={(data) =>
        FileSaver.saveAs(new Blob([data], { type: "text/csv" }), `users.csv`)
      }
      renderTrigger={(fireTrigger, state, events) => (
        <As onClick={fireTrigger} loading={state.submitting} {...props} />
      )}
    />
  );
};

export default DownloadUsers;
