import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWorkspaceDirectories, setWorkspaceDirectories } from "./actions";
import { getWorkspaceMenuState } from "../selectors";
import { MenuTree } from "features/System/TreeMenu";

const WorkspaceMenu = () => {
  const treeData = useSelector(getWorkspaceMenuState);

  const dispatch = useDispatch();

  useEffect(() => {
    getTreeData();
  }, []);

  const getTreeData = function (rootDirectoryId, pageNumber = 1) {
    if (
      treeData.loadingIds.some((x) => x.id == rootDirectoryId) ||
      (!rootDirectoryId && pageNumber == 1 && !!treeData.data.totalResults)
    ) {
      return;
    }
    dispatch(
      getWorkspaceDirectories(rootDirectoryId, { pageSize: 15, pageNumber })
    ).then((x) =>
      dispatch(setWorkspaceDirectories(x, rootDirectoryId, pageNumber))
    );
  };

  return (
    <React.Fragment>
      <MenuTree
        sites={treeData.data.results}
        onNodeExpanded={(x) => {
          if (!!x.directoriesCount && !x.directories.length) {
            getTreeData(x.id);
          }
        }}
        totalNodes={treeData.data.totalResults}
        onLoadMore={() => getTreeData(null, treeData.pagesLoaded + 1)}
        loadingIds={treeData.loadingIds}
        className="workspace-item"
      />
    </React.Fragment>
  );
};

export default WorkspaceMenu;
