import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { AppRoutes, GroupRoutes } from "constants/routes";
import GroupsListPage from "./GroupsListPage";
import GroupDetailsPage from "./GroupDetailsPage";

const GroupsRouter = () => (
  <Switch>
    <Route exact path={AppRoutes.Groups} component={GroupsListPage} />
    <Route path={GroupRoutes.Group()} component={GroupDetailsPage} />
    <Route render={() => <Redirect to={AppRoutes.Groups} />} />
  </Switch>
);

export default GroupsRouter;
