import React from "react";
import { MaplesPage } from "components/pages";
import { SiteDetails } from "features/Sites/SiteDetails";

const SiteDetailsPage = ({ match }) => {
  const siteId = match.params.id;
  return (
    <MaplesPage headerItems={["Sites"]}>
      <SiteDetails siteId={siteId} />
    </MaplesPage>
  );
};

export default SiteDetailsPage;
