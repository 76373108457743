import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import {
  checkValidDirectoryName,
  checkValidFileName,
  updateDirectory,
  updateFile,
} from "./actions";
import { stripFileExtension, getFileExtension } from "modules/helpers";

const EditWorkspaceItem = ({
  as: As = Button,
  onUpdated,
  item,
  directoryId,
  ...props
}) => {
  const dispatch = useDispatch();
  const [checkingName, setCheckingName] = useState(false);
  const [isValidName, setIsValidName] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  return (
    <ModalBuilder
      withForm
      submitAction={item.directory ? updateDirectory : updateFile}
      submitParams={item.id}
      onSubmitted={() => {
        if (onUpdated) onUpdated();
        setShowErrors(false);
        setIsValidName(false);
      }}
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      initialData={{
        id: item.id,
        name: item.directory ? item.name : stripFileExtension(item.name),
        fileExtension: item.directory ? null : getFileExtension(item.name),
      }}
      renderModal={(modalProps, formProps, state, events) => (
        <Modal
          {...modalProps}
          header={`Edit ${item.directory ? "Folder" : "File"}`}
          submitLabel="Update"
          size="tiny"
          onSubmit={() => {
            setShowErrors(true);
            if (formValid && isValidName && !checkingName) {
              events.onSubmit();
            }
          }}
        >
          <Form
            {...formProps}
            onValidated={(isValid) => setFormValid(isValid)}
            showErrors={showErrors}
          >
            {(fields, errors) => (
              <React.Fragment>
                <Form.Input
                  label="Name"
                  field="name"
                  fluid
                  required
                  showErrors={showErrors || (!isValidName && !!fields.name)}
                  customValidator={() => {
                    if (formProps.value.name) {
                      setCheckingName(true);
                      dispatch(
                        item.directory
                          ? checkValidDirectoryName(
                              directoryId,
                              formProps.value.name,
                              formProps.value.id
                            )
                          : checkValidFileName(
                              directoryId,
                              formProps.value.name +
                                "." +
                                formProps.value.fileExtension,
                              formProps.value.id
                            )
                      )
                        .then((result) => {
                          if (result.success && result.response != null) {
                            setIsValidName(!!result.response);
                          }
                        })
                        .finally(() => setCheckingName(false));
                    }
                  }}
                  customErrors={
                    !checkingName && !isValidName && !!fields.name
                      ? [
                          `Duplicate ${
                            item.directory ? "folder" : "file"
                          } name, please choose another`,
                        ]
                      : errors.name
                  }
                />
              </React.Fragment>
            )}
          </Form>
        </Modal>
      )}
    />
  );
};

export default EditWorkspaceItem;
