import React from "react";

const Avatar = ({ initials, ...props }) => {
  if (!initials) return null;

  const backgroundColour = selectAvatarColour(initials);

  return (
    <div
      className="avatar"
      {...props}
      style={{ backgroundColor: backgroundColour }}
    >
      {initials}
    </div>
  );
};

function selectAvatarColour(initials) {
  const colours = ["#C9BDDB", "#DBD7BD", "#BDDBCF", "#DBC4BD"];
  // look at first 2 chars and select from available colours based on char code
  const num =
    [...initials.trim().toLowerCase()].reduce(
      (acc, x, i) => (i <= 1 ? acc + x.charCodeAt(0) : acc),
      0
    ) % colours.length;
  return colours[num];
}

export default Avatar;
