import { registerLookup, apiGet } from "@redriver/cinnamon";

const NAMESPACE = "BIN/LOOKUPS";

const ActionTypes = {
  Sites: `${NAMESPACE}/SITES`,
};

export const sitesTypeAhead = registerLookup(
  "sitesTypeAhead",
  ({ search }) => apiGet(ActionTypes.Sites, "sites", { search, pageSize: 10 }),
  {
    transform: (data) =>
      data.results.map((x) => ({ value: x.id, text: x.name })),
  }
);
