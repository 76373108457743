import React from "react";
import { ListBuilder, Table, ActionBar } from "@redriver/cinnamon";
import { TablePanel } from "components/containers";
import { TablePagination } from "components/pagination";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { getRoles } from "./actions";
import FilterRoles from "./FilterRoles";
import AddRole from "./AddRole";
import RoleActions from "./RoleActions";

const ListRoles = () => (
  <ListBuilder
    withTable
    loadAction={getRoles}
    dataTransform={(data) => data.results}
    totalTransform={(data) => data.totalResults}
    renderList={(tableProps, state, events) => (
      <TablePanel>
        <ActionBar>
          <ActionBar.Item>
            <FilterRoles
              value={state.filters}
              onChange={events.onChangeFilters}
            />
          </ActionBar.Item>
          <ActionBar.Item fluid align="right">
            <PermissionCheck action={Actions.Create} target={Targets.Role}>
              <AddRole
                primary
                icon="plus"
                content="Add Role"
                onAdded={events.onRefresh}
              />
            </PermissionCheck>
          </ActionBar.Item>
        </ActionBar>

        <Table
          {...tableProps}
          dataKey="id"
          emptyMessage="No users found"
          selectable
        >
          <Table.Column field="name" title="Name" width="25%" />
          <Table.Column field="description" title="Description" width="45%" />
          <Table.Column field="totalUsers" title="Total Users" width="20%" />
          <Table.Column
            hoverable
            width="10%"
            align="right"
            render={(role) => (
              <RoleActions role={role} onRefresh={events.onRefresh} />
            )}
          />
        </Table>

        <TablePagination
          value={state.pagination}
          onChange={events.onChangePagination}
          totalItems={state.total}
        />
      </TablePanel>
    )}
  />
);

export default ListRoles;
