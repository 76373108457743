import React from "react";
import {
  ListBuilder,
  Table,
  Format,
  ActionBar,
  ActionMenu,
} from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { getUserSites } from "./actions";
import { Sort } from "constants/enums";
import { SortHeader, RunOnChange } from "components/misc";
import { MaplesLabel, DisplayDataSize } from "components/containers";
import { TablePagination } from "components/pagination";
import FilterSites from "features/Sites/ListSites/FilterSites";
import { Actions, Targets } from "constants/permissions";
import { PermissionCheck } from "components/auth";
import { useHistory } from "react-router-dom";
import { SiteRoutes } from "constants/routes";
import { TableActionMenu } from "components/misc";

const UserSitesList = ({ userId }) => {
  const history = useHistory();
  return (
    <ListBuilder
      withTable
      loadAction={getUserSites}
      loadParams={userId}
      initialFilters={{ sortName: "name", sortDirection: Sort.Asc }}
      initialPagination={{ pageSize: 25, pageNumber: 1 }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      renderList={(tableProps, state, events) => {
        return (
          <div>
            <RunOnChange
              value={state.total}
              func={() => events.onChangeSelection({})}
            />
            <ActionBar>
              <ActionBar.Item>
                <FilterSites
                  value={state.filters}
                  onChange={events.onChangeFilters}
                />
              </ActionBar.Item>
            </ActionBar>
            <Table
              {...tableProps}
              dataKey="id"
              emptyMessage="No sites found"
              onRowClick={(site) => history.push(SiteRoutes.Site(site.id))}
            >
              <Table.Column
                header={
                  <SortHeader
                    title="Name"
                    field="name"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                field="name"
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Status"
                    field="status"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                render={(site) =>
                  site.isActive ? (
                    <MaplesLabel type="primary">Active</MaplesLabel>
                  ) : (
                    <MaplesLabel type="default">Inactive</MaplesLabel>
                  )
                }
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Size"
                    field="size"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                render={(site) => <DisplayDataSize value={site.size} />}
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Groups"
                    field="groups"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                field="groupsCount"
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Users"
                    field="users"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                field="userCount"
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Created"
                    field="created"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                render={(site) => (
                  <Format.DateTime
                    value={site.createdUtc}
                    format="DD MMM YYYY HH:mm"
                  />
                )}
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Modified"
                    field="modified"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                render={(site) =>
                  site.modifiedUtc ? (
                    <Format.DateTime
                      value={site.modifiedUtc}
                      format="DD MMM YYYY HH:mm"
                    />
                  ) : (
                    "-"
                  )
                }
              />
              <Table.Column
                width="5%"
                align="right"
                render={(site) => (
                  <TableActionMenu>
                    <Table.MenuItem
                      onClick={() => history.push(SiteRoutes.Site(site.id))}
                    >
                      Go To Site
                    </Table.MenuItem>
                  </TableActionMenu>
                )}
              />
            </Table>
            <TablePagination
              value={state.pagination}
              onChange={events.onChangePagination}
              totalItems={state.total}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>
        );
      }}
    />
  );
};

export default UserSitesList;
