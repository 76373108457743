import React from "react";
import { ListRoles } from "features/Admin";
import { PageHeader } from "@redriver/cinnamon";
import { AppPageContent } from "components/pages";

const RoleListPage = () => (
  <AppPageContent>
    <PageHeader>Roles</PageHeader>
    <ListRoles />
  </AppPageContent>
);

export default RoleListPage;
