import React from "react";
import Colours from "constants/colours";

const OrganisationsIcon = ({ white = false }) => {
  const colour = white ? Colours.White : Colours.PrimaryBlue;
  return (
    <svg
      id="tree-02"
      xmlns="http://www.w3.org/2000/svg"
      width="12.833"
      height="14"
      viewBox="0 0 12.833 14"
    >
      <path
        id="Path"
        d="M10.429,5.3a2.811,2.811,0,0,0,.071-.628A2.921,2.921,0,0,0,8.019,1.782a2.62,2.62,0,0,0-5.1.843,2.237,2.237,0,0,0,.019.289A2.9,2.9,0,0,0,1.75,5.25c0,.079,0,.159.012.24a2.916,2.916,0,0,0,1.155,5.594H5.833v1.75H3.5A.583.583,0,0,0,3.5,14H9.333a.583.583,0,1,0,0-1.167H7v-1.75H9.917A2.917,2.917,0,0,0,10.429,5.3Z"
        fill={colour}
      />
    </svg>
  );
};

export default OrganisationsIcon;
