import React from "react";
import { useAuthClaims, DataList } from "@redriver/cinnamon";

const UserDetails = () => {
  const { userName, userEmail } = useAuthClaims();
  return (
    <DataList>
      <DataList.Item title="Name">{userName}</DataList.Item>
      <DataList.Item title="Email">{userEmail}</DataList.Item>
    </DataList>
  );
};

export default UserDetails;
