import React from "react";
import { Grid } from "semantic-ui-react";
import { Form } from "@redriver/cinnamon";
import { PermissionsTree } from "../lookups";

const RoleForm = props => (
  <Form {...props}>
    <Grid>
      <Grid.Column width={8}>
        <Form.Input field="name" label="Name" fluid required />
        <Form.Input field="description" label="Description" fluid />
      </Grid.Column>
      <Grid.Column width={8}>
        <PermissionsTree field="permissions" label="Permissions" required />
      </Grid.Column>
    </Grid>
  </Form>
);

export default RoleForm;
