import React from "react";
import { Grid, Button } from "semantic-ui-react";
import { Form, ModalBuilder, Modal, DataList } from "@redriver/cinnamon";
import { getUser, updateUser } from "./actions";
import { RoleDropdown } from "../lookups";
import { OrganisationTypeAhead } from "features/Organisations/OrganisationTypeAhead";

const EditUser = ({ userId, as: As = Button, onUpdated, ...props }) => (
  <ModalBuilder
    withForm
    loadAction={getUser}
    loadParams={userId}
    loadTransform={(user) => {
      user.status = user.isDisabled
        ? "Inactive"
        : user.isVerified
        ? "Active"
        : "Unverified";
      return user;
    }}
    submitAction={updateUser}
    submitParams={userId}
    onSubmitted={onUpdated}
    renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
    renderModal={(modalProps, formProps) => {
      const user = formProps.value;
      return (
        <Modal {...modalProps} header="Edit User" submitLabel="Save">
          <Form {...formProps}>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Form.Input
                    placeholder="First Name"
                    field="firstName"
                    fluid
                    required
                    label="First Name"
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    placeholder="Last Name"
                    field="lastName"
                    fluid
                    required
                    label="Last Name"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <OrganisationTypeAhead
                    field="organisationId"
                    textField="organisationName"
                    label="Organisation"
                    placeholder="No selected organisation"
                    clearable
                    fluid
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Checkbox
                    field="isSystemAdmin"
                    label="System Admin?"
                    className="admin-checkbox"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Form.Input label="Email" field="email" readOnly fluid />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input label="Status" field="status" readOnly fluid />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Modal>
      );
    }}
  />
);

export default EditUser;
