import { apiGet, apiPut, apiDelete, apiPost } from "@redriver/cinnamon";

const NAMESPACE = "SITES/SITE_DETAILS";

const ActionTypes = {
  GetSiteDetails: `${NAMESPACE}/GET_SITE_DETAILS`,
};

export const getSiteDetails = (id) => {
  return apiGet(ActionTypes.GetSiteDetails, `sites/${id}`);
};
