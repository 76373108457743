import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { addRole } from "./actions";
import RoleForm from "./RoleForm";

const AddRole = ({ as: As = Button, onAdded, ...props }) => (
  <ModalBuilder
    withForm
    submitAction={addRole}
    onSubmitted={onAdded}
    renderTrigger={showModal => <As onClick={showModal} {...props} />}
    renderModal={(modalProps, formProps) => (
      <Modal.Add {...modalProps} header="Add Role">
        <RoleForm {...formProps} />
      </Modal.Add>
    )}
  />
);

export default AddRole;
