import { ActionTypes } from "./actions";
import { produce } from "immer";
import {
  requestIsBusy,
  requestResponse,
  requestIsSuccess,
} from "@redriver/cinnamon";
import { SitePermission, SitePermissionItemType } from "constants/enums";
import SiteActions from "features/Sites/ListSites/SiteActions";

const initialState = {
  groupId: null,
  permissionData: [],
  loading: false,
  expandedIds: {},
  loadingIds: {},
  originalValues: {},
  customisedValues: {},
  saving: false,
};

const getNode = (id, parentIds, defaultItems) => {
  const ids = [...parentIds, id];
  let item = null;
  while (ids.length > 0) {
    item =
      (item == null ? defaultItems : item.items).find((x) => x.id == ids[0]) ||
      item;
    ids.shift();
  }
  return item;
};

const copyOriginalValues = (
  item,
  depth = 0,
  override = null,
  parentValue = null
) => {
  let result = {
    [item.id]: {
      access: override || item.access,
      // partial access flags
      hasPartialChildViewAccess:
        !!item.hasPartialChildViewAccess &&
        (override || item.access) != SitePermission.None,
      hasPartialChildEditAccess: !!item.hasPartialChildEditAccess,

      depth: depth || 0,
      type: item.type,
    },
  };

  const nextParentValue = result[item.id];
  const nextDepth = result[item.id].depth + 1;

  if (Array.isArray(item.items) && item.items.length > 0) {
    result = item.items.reduce(
      (acc, x) => ({
        ...acc,
        ...copyOriginalValues(x, nextDepth, override, nextParentValue),
      }),
      result
    );
  }
  return result;
};

const calcCustomAccess = (
  item,
  draft,
  access,
  isDowngrade,
  shouldPropagate = false,
  markDirty = false
) => {
  if (shouldPropagate) {
    item.items.forEach((x) => {
      calcCustomAccess(x, draft, access, isDowngrade, true);
      draft[x.id].shouldPropagate = true;
      if (markDirty) draft[x.id].isDirty = true;
    });
  }

  let targetAccess = access;

  if (
    !isDowngrade &&
    draft[item.id].access == SitePermission.Edit &&
    access == SitePermission.View
  ) {
    targetAccess = draft[item.id].access;
  }

  draft[item.id] = {
    access: targetAccess,

    hasPartialChildViewAccess:
      item.type != SitePermissionItemType.File &&
      item.hasChildItems &&
      item.items.length > 0
        ? (item.items.some(
            (x) =>
              draft[x.id].access != SitePermission.None ||
              !!draft[x.id].hasPartialChildViewAccess
          ) ||
            item.items.every(
              (x) =>
                access != SitePermission.None &&
                draft[x.id].access == SitePermission.None
            )) &&
          !item.items.every(
            (x) =>
              draft[x.id].access != SitePermission.None &&
              !draft[x.id].hasPartialChildViewAccess
          )
        : false,
    hasPartialChildEditAccess:
      item.type != SitePermissionItemType.File &&
      item.hasChildItems &&
      item.items.length > 0
        ? access == SitePermission.Edit
          ? !item.items.every(
              (x) =>
                draft[x.id].access == SitePermission.Edit &&
                !draft[x.id].hasPartialChildEditAccess
            )
          : item.items.some(
              (x) =>
                draft[x.id].access == SitePermission.Edit ||
                draft[x.id].hasPartialChildEditAccess
            )
        : false,

    depth: draft[item.id].depth,
    type: draft[item.id].type,
    shouldPropagate: shouldPropagate || !!draft[item.id].shouldPropagate,
  };

  if (markDirty) {
    draft[item.id].isDirty = true;
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ListGroupPermissions: {
      const response = requestResponse(action);
      const loading = requestIsBusy(action);

      const originalValues = !loading
        ? response.reduce(
            (acc, x) => ({ ...acc, ...copyOriginalValues(x, 0) }),
            {}
          )
        : {};
      return {
        ...state,
        loading: loading,
        permissionData: !loading ? response : state.permissionData,
        groupId: action.customData.groupId,
        expandedIds: {},
        loadingIds: {},
        originalValues: originalValues,
        customisedValues: originalValues,
      };
    }
    case ActionTypes.SetExpandedPermissionNode:
      return {
        ...state,
        expandedIds: {
          ...state.expandedIds,
          [action.nodeId]: action.value,
        },
      };

    // go get the children of a permission item and insert it into the existing data
    case ActionTypes.ListGroupPermissionChildren: {
      const response = requestResponse(action);
      const loading = requestIsBusy(action);
      const nodeId = action.customData.nodeId;
      const parentIds = action.customData.parentIds || [];
      const ids = [...parentIds, nodeId];
      const nextPermissionData =
        loading || !response
          ? state.permissionData
          : produce(state.permissionData, (draft) => {
              let item = null;
              // move through list of ids to identify where the item we are interested in unpdating
              while (ids.length > 0) {
                item =
                  (item == null ? draft : item.items).find(
                    (x) => x.id == ids[0]
                  ) || item;
                ids.shift();
              }

              if (
                item &&
                item.items.length == 0 &&
                Array.isArray(response) &&
                response.length > 0
              ) {
                item.items = response[0].items || [];
              }

              return draft;
            });

      const parentValueHasChanged =
        !loading && !!response
          ? state.customisedValues[response[0].id].access !=
            state.originalValues[response[0].id].access
          : false;
      const overrideValue = parentValueHasChanged
        ? state.customisedValues[response[0].id].access
        : null;

      return {
        ...state,
        permissionData: nextPermissionData,
        loadingIds: {
          ...state.loadingIds,
          [nodeId]: loading,
        },
        originalValues:
          !loading && !!response
            ? response[0].items.reduce(
                (acc, x) => {
                  const depth = state.originalValues[response[0].id].depth + 1;
                  return {
                    ...acc,
                    ...copyOriginalValues(x, depth),
                  };
                },

                state.originalValues
              )
            : state.originalValues,
        customisedValues:
          !loading && !!response
            ? response[0].items.reduce((acc, x) => {
                const depth = state.customisedValues[response[0].id].depth + 1;
                const shouldPropagate = !!state.customisedValues[response[0].id]
                  .shouldPropagate;

                const copiedValues = copyOriginalValues(
                  x,
                  depth,
                  overrideValue
                );
                if (shouldPropagate) {
                  // pass propagation flag down to new children
                  Object.keys(copiedValues).forEach((k) => {
                    copiedValues[k].shouldPropagate = true;
                  });
                }

                return {
                  ...acc,
                  ...copiedValues,
                };
              }, state.customisedValues)
            : state.customisedValues,
      };
    }

    case ActionTypes.SetAccessForPermissionNode: {
      const nodeId = action.nodeId;
      const parentIds = action.parentIds || [];
      const ids = [...parentIds, nodeId];

      // find current item
      let item = null;
      while (ids.length > 0) {
        item =
          (item == null ? state.permissionData : item.items).find(
            (x) => x.id == ids[0]
          ) || item;
        ids.shift();
      }

      if (!item) return state;

      const nextCustomValues = produce(state.customisedValues, (draft) => {
        // update item's own value record and propagate to children
        const draftAccess = draft[item.id].access;

        const isDowngrade =
          (draftAccess == SitePermission.Edit &&
            action.value != SitePermission.Edit) ||
          (draftAccess == SitePermission.View &&
            action.value == SitePermission.None);

        calcCustomAccess(item, draft, action.value, isDowngrade, true, true);

        // update parents
        [...parentIds].reverse().forEach((id, index) => {
          const isUnselected = draft[id].access == SitePermission.None;

          const node = getNode(
            id,
            parentIds.slice(0, parentIds.length - index),
            state.permissionData
          );

          calcCustomAccess(
            node,
            draft,
            draft[id].access == SitePermission.None
              ? SitePermission.View
              : draft[id].access,
            isDowngrade,
            false,
            true
          );
        });
      });

      return {
        ...state,
        customisedValues: nextCustomValues,
      };
    }

    case ActionTypes.UpdatePermissions: {
      const saving = requestIsBusy(action);
      return {
        ...state,
        saving: saving,
      };
    }

    default:
      return state;
  }
};
