import { apiGet, apiPost, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "BIN/BIN_CONTENT";

const ActionTypes = {
  GetContent: `${NAMESPACE}/GET_CONTENT`,
  Restore: `${NAMESPACE}/RESTORE_FILES`,
  ContainsLegacy: `${NAMESPACE}/CONTAINS_LEGACY`,
};

export const getContent = ({ filters, pagination }) => {
  const { pageSize, pageNumber } = pagination;
  const { search, sortName, sortDirection } = filters;
  return apiGet(ActionTypes.GetContent, "workspace/deleted", {
    pageSize,
    pageNumber,
    search,
    sortName,
    sortDirection,
  });
};

export const restore = (data, { ...request }) =>
  apiPut(ActionTypes.Restore, `workspace/deleted/restore`, {
    ...data,
    ...request,
    selected: (request.selected || []).map((x) => JSON.parse(x)),
    deselected: (request.deselected || []).map((x) => JSON.parse(x)),
  });

export const containsLegacy = ({ ...request }) =>
  apiPost(ActionTypes.ContainsLegacy, `workspace/deleted/contains-legacy`, {
    ...request,
    selected: (request.selected || []).map((x) => JSON.parse(x)),
    deselected: (request.deselected || []).map((x) => JSON.parse(x)),
  });
