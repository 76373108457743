import React from "react";
import { Segment } from "semantic-ui-react";
import classNames from "classnames";

const DetailsPanel = ({ leftContent, rightContent, className, ...props }) => (
  <Segment className={classNames("details-panel", className)} {...props}>
    <header>{leftContent}</header>
    <footer>{rightContent}</footer>
  </Segment>
);

export default DetailsPanel;
