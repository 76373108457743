import {
  apiGet,
  apiPut,
  apiDelete,
  apiPost,
  mockSuccess,
} from "@redriver/cinnamon";
import { SitePermission } from "constants/enums";
import { getGroupPermissionsDetails } from "./selectors";

const NAMESPACE = "GROUPS/GROUP_DETAILS";

export const ActionTypes = {
  GetGroupDetails: `${NAMESPACE}/GET_GROUP_DETAILS`,
  AddGroupUsers: `${NAMESPACE}/ADD_GROUP_USERS`,
  GetGroupUsers: `${NAMESPACE}/GET_GROUP_USERS`,
  RemoveGroupUser: `${NAMESPACE}/REMOVE_SINGLE_GROUP_USER`,
  ListGroupPermissions: `${NAMESPACE}/LIST_GROUP_PERMISSIONS`,
  SetExpandedPermissionNode: `${NAMESPACE}/SET_EXPANDED_PERMISSION_NODE`,
  ListGroupPermissionChildren: `${NAMESPACE}/LIST_GROUP_PERMISSION_CHILDREN`,
  SetAccessForPermissionNode: `${NAMESPACE}/SET_ACCESS_FOR_PERMISSION_NODE`,
  UpdatePermissions: `${NAMESPACE}/UPDATE_PERMISSIONS`,
};

export const getGroupDetails = (id) => {
  return apiGet(ActionTypes.GetGroupDetails, `groups/${id}`);
};

export const addGroupUsers = (data, groupId) => {
  return apiPost(ActionTypes.AddGroupUsers, `groups/${groupId}/users`, data);
};

export const getGroupUsers = ({ filters, pagination }, groupId) => {
  const { pageSize, pageNumber } = pagination;
  const { search, sortName, sortDirection } = filters;
  return apiGet(ActionTypes.GetGroupUsers, `groups/${groupId}/users`, {
    pageSize,
    pageNumber,
    search,
    sortName,
    sortDirection,
  });
};

export const removeGroupUser = ({ groupId, ...params }) => {
  return apiDelete(
    ActionTypes.RemoveGroupUser,
    `groups/${groupId}/users`,
    params
  );
};

export const listGroupPermissions = (groupId) => {
  return apiGet(
    ActionTypes.ListGroupPermissions,
    `groups/${groupId}/site-permissions`,
    {},
    {
      actionCustomData: { groupId },
    }
  );
};

export const listGroupPermissionChildren = (groupId, nodeId, parentIds) => {
  return apiGet(
    ActionTypes.ListGroupPermissionChildren,
    `groups/${groupId}/site-permissions`,
    { rootDirectoryId: nodeId },
    {
      actionCustomData: { nodeId, parentIds },
    }
  );
};

export const toggleExpandedNode = (node, parentIds) => (dispatch, getState) => {
  const currentState = getGroupPermissionsDetails(getState());

  const isAlreadyExpanded = currentState.expandedIds[node.id];

  if (!isAlreadyExpanded && node.hasChildItems && node.items.length == 0) {
    const groupId = currentState.groupId;
    dispatch(listGroupPermissionChildren(groupId, node.id, parentIds));
  }

  dispatch({
    type: ActionTypes.SetExpandedPermissionNode,
    nodeId: node.id,
    value: !isAlreadyExpanded,
  });
};

export const setAccessForPermissionNode = (node, parentIds, value) => (
  dispatch,
  getState
) => {
  dispatch({
    type: ActionTypes.SetAccessForPermissionNode,
    nodeId: node.id,
    parentIds,
    value,
  });
};

export const updateSitePermissions = () => (dispatch, getState) => {
  const currentDetails = getGroupPermissionsDetails(getState());
  const groupId = currentDetails.groupId;

  const changed = Object.keys(currentDetails.customisedValues)
    .filter((x) => {
      const matching = currentDetails.originalValues[x];
      const next = currentDetails.customisedValues[x];
      return (
        matching &&
        (matching.access != next.access ||
          matching.hasPartialChildViewAccess !=
            next.hasPartialChildViewAccess ||
          matching.hasPartialChildEditAccess !=
            next.hasPartialChildEditAccess ||
          next.isDirty)
      );
    })
    .map((x) => ({
      id: x,
      access: currentDetails.customisedValues[x].access,
      hasPartialChildViewAccess:
        currentDetails.customisedValues[x].hasPartialChildViewAccess,
      hasPartialChildEditAccess:
        currentDetails.customisedValues[x].hasPartialChildEditAccess,
      depth: currentDetails.customisedValues[x].depth,
      type: currentDetails.customisedValues[x].type,
      shouldPropagate: currentDetails.customisedValues[x].shouldPropagate,
    }));

  // NOTE: need to send up propagate flag to indicate if value should be applied to children

  const request = { updates: changed };

  dispatch(
    apiPut(
      ActionTypes.UpdatePermissions,
      `groups/${groupId}/site-permissions`,
      request
    )
  );
};
