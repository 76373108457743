import React from "react";
import { ListBuilder, Table, ActionBar, ActionMenu } from "@redriver/cinnamon";
import { AddUsers } from "features/Admin/AddUsers";
import { addGroupUsers, getGroupUsers, removeGroupUser } from "./actions";
import FilterUsers from "./FilterUsers";
import { Sort } from "constants/enums";
import { TablePagination } from "components/pagination";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { Avatar, SortHeader } from "components/misc";
import { getInitials } from "modules/helpers";
import { UserActions } from "features/Admin/UserActions";
import { MaplesLabel } from "components/containers";
import { UserSortFields } from "constants/sortFields";
import BulkActionsModal from "components/modals/BulkActionsModal";
import ActionsModal from "components/modals/ActionsModal";
import { Button } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { UserRoutes } from "constants/routes";

const GroupUsersList = ({ groupId }) => {
  const history = useHistory();
  return (
    <ListBuilder
      withTable
      loadAction={getGroupUsers}
      loadParams={groupId}
      initialFilters={{ sortName: "name", sortDirection: Sort.Asc }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      initialPagination={{ pageSize: 25, pageNumber: 1 }}
      renderList={(tableProps, state, events) => {
        const totalSelected = state.selection.allSelected
          ? state.total - (state.selection.deselected?.length || 0)
          : state.selection.selected?.length || 0;
        const visibleRowCount = tableProps?.data?.length;
        return (
          <div>
            <ActionBar>
              <ActionBar.Item>
                <FilterUsers
                  value={state.filters}
                  onChange={events.onChangeFilters}
                />
              </ActionBar.Item>
              <ActionBar.Item fluid align="right"></ActionBar.Item>
              <ActionBar.Item fluid align="right">
                <ActionMenu
                  openOnHover={false}
                  disabled={totalSelected == 0}
                  trigger={<Button compact> Actions ({totalSelected}) </Button>}
                >
                  <BulkActionsModal
                    actionName="remove"
                    header="Remove Users"
                    submitParams={{
                      groupId,
                      selected: state.selection.selected,
                      deselected: state.selection.deselected,
                      allSelected: state.selection.allSelected,
                      search: state.filters.search,
                    }}
                    onRefresh={events.onRefresh}
                    submitAction={removeGroupUser}
                    totalSelected={totalSelected}
                    as={ActionMenu.Item}
                    entityName="user"
                  >
                    Remove
                  </BulkActionsModal>
                </ActionMenu>
                <PermissionCheck action={Actions.Create} target={Targets.User}>
                  <AddUsers
                    compact
                    content="Add Users"
                    onAdded={events.onRefresh}
                    submitAction={addGroupUsers}
                    submitParams={groupId}
                    searchParams={{ groupId }}
                    title="Add Users to Group"
                    allowExistingUsers
                  />
                </PermissionCheck>
              </ActionBar.Item>
            </ActionBar>
            <Table
              {...tableProps}
              dataKey="id"
              emptyMessage="No users found"
              selectable
              className="users-table"
              onRowClick={(user) => history.push(UserRoutes.User(user.id))}
            >
              <Table.SelectableColumn
                value={state.selection}
                onChange={events.onChangeSelection}
                totalItems={state.total}
              />
              <Table.Column
                width="5%"
                render={(user) => {
                  const initials = getInitials([user.firstName, user.lastName]);
                  return <Avatar initials={initials} />;
                }}
              />
              <Table.Column
                header={
                  <SortHeader
                    title="User"
                    field={UserSortFields.Name}
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                render={(user) => (
                  <div className="stacked-column">
                    <span className="name">
                      {user.firstName + " " + user.lastName}
                    </span>
                    <span>{user.email}</span>
                  </div>
                )}
                width="22%"
              />

              <Table.Column
                field="role"
                width="22%"
                header="Type"
                render={(user) =>
                  user.isSystemAdmin ? (
                    <MaplesLabel type="primary">System Admin</MaplesLabel>
                  ) : (
                    <MaplesLabel type="default">Standard User</MaplesLabel>
                  )
                }
              />

              <Table.Column
                header={
                  <SortHeader
                    title="Organisation"
                    field={UserSortFields.Organisation}
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                render={(user) => user.organisationName || "-"}
              />

              <Table.Column
                width="5%"
                align="right"
                render={(user, colState) => (
                  <UserActions
                    user={user}
                    onRefresh={events.onRefresh}
                    itemIndex={colState.itemIndex}
                    visibleRowCount={visibleRowCount}
                  >
                    <ActionsModal
                      as={Table.MenuItem}
                      submitAction={removeGroupUser}
                      id={user.id}
                      name={`${user.firstName} ${user.lastName}`}
                      textEnding="from this group"
                      actionName="remove"
                      onRefresh={events.onRefresh}
                      header="Remove User"
                      submitParams={{
                        selected: [user.id],
                        groupId,
                      }}
                    >
                      Remove User
                    </ActionsModal>
                  </UserActions>
                )}
              />
            </Table>
            <TablePagination
              value={state.pagination}
              onChange={events.onChangePagination}
              totalItems={state.total}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>
        );
      }}
    />
  );
};

export default GroupUsersList;
