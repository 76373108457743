import React from "react";
import { Table } from "@redriver/cinnamon";
import RestoreFiles from "./RestoreFiles";
import { stripFileExtension } from "modules/helpers";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { TableActionMenu } from "components/misc";

const BinItemActions = ({ item, onRefresh }) => {
  return (
    <PermissionCheck target={Targets.Bin} action={Actions.Edit}>
      <React.Fragment>
        <TableActionMenu>
          <React.Fragment>
            <RestoreFiles
              onSubmitted={onRefresh}
              as={Table.MenuItem}
              selection={{
                selected: [item.dataKey],
              }}
              fileName={stripFileExtension(item.name)}
            >
              Restore
            </RestoreFiles>
          </React.Fragment>
        </TableActionMenu>
      </React.Fragment>
    </PermissionCheck>
  );
};

export default BinItemActions;
