import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { getAuthState } from "@redriver/cinnamon";
import { PasswordForgotten } from "features/System";
import { SystemRoutes } from "constants/routes";
import { SystemPageContent, PublicPageContent } from "components/pages";

const PasswordForgottenPage = ({ loggedIn }) => {
  if (loggedIn) {
    return <Redirect to={SystemRoutes.ChangePassword} />;
  }

  return (
    <PublicPageContent>
      <PasswordForgotten />
    </PublicPageContent>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: getAuthState(state).loggedIn,
});

export default connect(mapStateToProps)(PasswordForgottenPage);
