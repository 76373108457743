import React, { useContext } from "react";
import { BreadcrumbContext } from "components/pages";
import { ContentBuilder, Format } from "@redriver/cinnamon";
import { getUser } from "./actions";
import { AppRoutes } from "constants/routes";
import {
  MaplesTabs,
  DetailsField,
  DetailsPanel,
  MaplesLabel,
  DisplayDataSize,
} from "components/containers";
import UserGroupsList from "./UserGroupsList";
import UserSitesList from "./UserSitesList";
import * as BreadcrumbText from "constants/breadcrumbs";
import { UserActions } from "features/Admin/UserActions";

const UserDetails = ({ userId }) => {
  const [_, setBreadcrumbs] = useContext(BreadcrumbContext);
  return (
    <ContentBuilder
      loadAction={getUser}
      loadParams={userId}
      onLoaded={(data) => {
        setBreadcrumbs([
          { content: BreadcrumbText.Users, to: AppRoutes.Users },
          !!data && `${data.firstName} ${data.lastName}`,
        ]);
      }}
      renderContent={(data, state, events) => {
        return (
          <React.Fragment>
            <div>
              <DetailsPanel
                loading={state.loading}
                leftContent={
                  <React.Fragment>
                    <DetailsField title="Name">
                      <span style={{ marginRight: "1rem" }}>
                        {data.firstName}
                      </span>
                      {data.isDisabled ? (
                        <MaplesLabel type="default">Inactive</MaplesLabel>
                      ) : data.isVerified ? (
                        <MaplesLabel type="primary">Active</MaplesLabel>
                      ) : (
                        <MaplesLabel type="secondary">Unverified</MaplesLabel>
                      )}
                    </DetailsField>
                    {data.organisationName && (
                      <DetailsField title="Organisation">
                        {data.organisationName}
                      </DetailsField>
                    )}
                  </React.Fragment>
                }
                rightContent={
                  state.loading ? null : (
                    <React.Fragment>
                      <DetailsField title="Invited">
                        <Format.DateTime
                          value={data.createdUtc}
                          format="DD MMM YYYY HH:mm"
                        />
                      </DetailsField>
                      <DetailsField title="Last Login">
                        {data.lastLoginUtc ? (
                          <Format.DateTime
                            value={data.lastLoginUtc}
                            format="DD MMM YYYY HH:mm"
                          />
                        ) : (
                          "-"
                        )}
                      </DetailsField>
                      <UserActions
                        user={data}
                        onRefresh={events.onRefresh}
                        hideView
                        className="header-actions"
                      />
                    </React.Fragment>
                  )
                }
              />

              <MaplesTabs
                tabs={[
                  {
                    title: "Groups",
                    content: <UserGroupsList userId={userId} />,
                  },
                  {
                    title: "Sites",
                    content: <UserSitesList userId={userId} />,
                  },
                ]}
              />
            </div>
          </React.Fragment>
        );
      }}
    />
  );
};

export default UserDetails;
