import React from "react";
import { Modal } from "semantic-ui-react";
import { UploadIndicator } from "components/workspace";

const UploadProgressModal = ({ open, progress }) => {
  return (
    <Modal open={open}>
      <Modal.Header>Uploading files</Modal.Header>
      <Modal.Content>
        <UploadIndicator percent={progress} />
      </Modal.Content>
    </Modal>
  );
};

export default UploadProgressModal;
