import React from "react";
import {
  ActionBar,
  ActionMenu,
  notifyInfo,
  useApiAction,
} from "@redriver/cinnamon";
import { Button, Popup } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { addWorkspaceDirectory } from "features/System/WorkspaceMenu/actions";
import { useHistory, Link } from "react-router-dom";
import { matchPath } from "react-router";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import FilterWorkspaceContent from "./FilterWorkspaceContent";
import AddDirectory from "./AddDirectory";
import UploadFileModal from "./UploadFileModal";
import { getDirectorySlug } from "modules/helpers";
import { SiteRoutes } from "constants/routes";
import BulkActionsModal from "components/modals/BulkActionsModal";
import { deleteContent, downloadSelection } from "./actions";
import MoveWorkspaceItems from "./MoveWorkspaceItems";
import { useDownloadStreaming } from "modules/files";
import moment from "moment";
import {
  getWorkspaceDirectories,
  setWorkspaceDirectories,
} from "features/System/WorkspaceMenu/actions";

const WorkspaceContentActionBar = ({
  path,
  directoryId,
  onChangeFilters,
  onRefresh,
  totalSelected,
  selection,
  filters,
  canEdit,
  canEditContent,
  skipRoot,
  rootSource = "",
  siteId,
  siteName,
  siteRootDirectoryId,
  canReorder,
  reordering,
  setReordering,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [download, beginDownload] = useApiAction(downloadSelection);

  useDownloadStreaming(
    download,
    `${siteName} ${moment().local().format("DD-MM-YY HH-mm")}.zip`
  );

  return (
    <ActionBar>
      <ActionBar.Item>
        <FilterWorkspaceContent
          value={filters}
          onChange={onChangeFilters}
          disabled={reordering}
        />
      </ActionBar.Item>
      <ActionBar.Item fluid align="right">
        <ActionMenu
          openOnHover={false}
          disabled={totalSelected == 0 || reordering}
          trigger={<Button compact> Actions ({totalSelected}) </Button>}
        >
          <ActionMenu.Item
            onClick={() => {
              const toastExpiry = 10000;
              dispatch(
                notifyInfo(`Downloading files`, { expiry: toastExpiry })
              );
              beginDownload({ directoryId, selection });
            }}
          >
            Download
          </ActionMenu.Item>
          {canEditContent && (
            <PermissionCheck action={Actions.Edit} target={Targets.File}>
              <BulkActionsModal
                actionName="delete"
                header="Delete"
                submitParams={{
                  selected: selection.selected,
                  deselected: selection.deselected,
                  allSelected: selection.allSelected,
                  search: filters.search,
                  directoryId: directoryId,
                }}
                onRefresh={onRefresh}
                submitAction={deleteContent}
                totalSelected={totalSelected}
                as={ActionMenu.Item}
                entityName="item"
              >
                Delete
              </BulkActionsModal>
              <MoveWorkspaceItems
                directoryId={directoryId}
                siteRootDirectoryId={siteRootDirectoryId}
                selection={{
                  selected: selection.selected,
                  deselected: selection.deselected,
                  allSelected: selection.allSelected,
                  search: filters.search,
                }}
                as={ActionMenu.Item}
                onClose={() => {
                  onRefresh();
                  dispatch(
                    getWorkspaceDirectories(siteRootDirectoryId, {
                      pageSize: 15,
                      pageNumber: 1,
                    })
                  ).then((x) =>
                    dispatch(
                      setWorkspaceDirectories(x, siteRootDirectoryId, 1, true)
                    )
                  );
                }}
              >
                Move
              </MoveWorkspaceItems>
            </PermissionCheck>
          )}
        </ActionMenu>

        {canEdit && (
          <PermissionCheck action={Actions.Create} target={Targets.File}>
            <UploadFileModal
              compact
              content="Upload File"
              directoryId={directoryId}
              onClose={(added) => (added ? onRefresh() : null)}
              disabled={reordering}
            />
            <AddDirectory
              compact
              content="Add Folder"
              onAdded={(folder) => {
                const targetPaths = path.filter((_, i) =>
                  skipRoot ? i != 0 : true
                );
                const target =
                  rootSource +
                  (targetPaths.length > 0 ? "/" : "") +
                  targetPaths.join("/") +
                  "/" +
                  getDirectorySlug(folder.reference, folder.name);
                history.push(target);
                dispatch(
                  addWorkspaceDirectory(
                    directoryId,
                    folder.id,
                    folder.name,
                    folder.reference
                  )
                );
              }}
              directoryId={directoryId}
              disabled={reordering}
            />
          </PermissionCheck>
        )}
        <PermissionCheck action={Actions.Reorder} target={Targets.File}>
          {canReorder ? (
            <Button compact onClick={() => setReordering(!reordering)}>
              {reordering ? "Save Order" : "Reorder"}
            </Button>
          ) : (
            <Popup
              content="Remove all filters and sorting to reorder"
              trigger={
                <span>
                  <Button disabled compact>
                    Reorder
                  </Button>
                </span>
              }
            />
          )}
        </PermissionCheck>
        {!matchPath(history.location.pathname, SiteRoutes.Site(siteId)) && (
          <PermissionCheck action={Actions.View} target={Targets.Site}>
            <Link to={SiteRoutes.Site(siteId)}>
              <Button compact>Go to Site</Button>
            </Link>
          </PermissionCheck>
        )}
      </ActionBar.Item>
    </ActionBar>
  );
};

export default WorkspaceContentActionBar;
