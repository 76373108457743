import React, { useRef } from "react";
import {
  ListBuilder,
  Table,
  ActionBar,
  Format,
  ActionMenu,
} from "@redriver/cinnamon";
import { TablePagination } from "components/pagination";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { getUsers, addUsers, updateUserStatus, downloadUsers } from "./actions";
import FilterUsers from "./FilterUsers";
import { MaplesLabel } from "components/containers";
import { Avatar, SortHeader } from "components/misc";
import { getInitials } from "modules/helpers";
import { Sort } from "constants/enums";
import { AddUsers } from "features/Admin/AddUsers";
import { UserActions } from "features/Admin/UserActions";
import { UserSortFields } from "constants/sortFields";
import { Button } from "semantic-ui-react";
import BulkActionsModal from "components/modals/BulkActionsModal";
import { sendPasswordReset } from "features/Admin/UserActions/actions";
import { useHistory, useLocation } from "react-router-dom";
import { UserRoutes } from "constants/routes";
import { UserStatus } from "constants/enums";
import DownloadUsers from "./DownloadUsers";

const ListUsers = ({ organisationId = null, organisationName }) => {
  const history = useHistory();
  const location = useLocation();
  return (
    <ListBuilder
      withTable
      loadAction={getUsers}
      onLoaded={() => {
        if (organisationId || organisationName) {
          // clean up the displayed url
          history.replace(location.pathname);
        }
      }}
      initialFilters={{
        sortName: "name",
        sortDirection: Sort.Asc,
        organisationId: organisationId,
        organisationName: organisationName,
        status: [UserStatus.Active],
      }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      initialPagination={{ pageSize: 25, pageNumber: 1 }}
      renderList={(tableProps, state, events) => {
        const totalSelected = state.selection.allSelected
          ? state.total - (state.selection.deselected?.length || 0)
          : state.selection.selected?.length || 0;

        const visibleRowCount = tableProps?.data?.length;
        return (
          <div>
            <ActionBar>
              <ActionBar.Item>
                <FilterUsers
                  value={state.filters}
                  onChange={events.onChangeFilters}
                />
              </ActionBar.Item>
              <ActionBar.Item fluid align="right">
                <ActionMenu
                  openOnHover={false}
                  disabled={totalSelected == 0}
                  trigger={<Button compact> Actions ({totalSelected}) </Button>}
                >
                  <BulkActionsModal
                    actionName="activate"
                    header="Activate"
                    submitParams={{
                      isDisabled: false,
                      selected: state.selection.selected,
                      deselected: state.selection.deselected,
                      allSelected: state.selection.allSelected,
                      search: state.filters.search,
                    }}
                    onRefresh={events.onRefresh}
                    submitAction={updateUserStatus}
                    totalSelected={totalSelected}
                    as={ActionMenu.Item}
                    entityName="user"
                  >
                    Activate
                  </BulkActionsModal>
                  <BulkActionsModal
                    actionName="lock out"
                    header="Lock Out"
                    submitParams={{
                      isDisabled: true,
                      selected: state.selection.selected,
                      deselected: state.selection.deselected,
                      allSelected: state.selection.allSelected,
                      search: state.filters.search,
                    }}
                    onRefresh={events.onRefresh}
                    submitAction={updateUserStatus}
                    totalSelected={totalSelected}
                    as={ActionMenu.Item}
                    entityName="user"
                  >
                    Lock Out
                  </BulkActionsModal>
                  <BulkActionsModal
                    actionName="send password reset email to"
                    header="Send password Reset"
                    submitParams={{
                      selected: state.selection.selected,
                      deselected: state.selection.deselected,
                      allSelected: state.selection.allSelected,
                      search: state.filters.search,
                    }}
                    submitAction={sendPasswordReset}
                    totalSelected={totalSelected}
                    as={ActionMenu.Item}
                    entityName="user"
                  >
                    Send Password Reset
                  </BulkActionsModal>
                </ActionMenu>
                <PermissionCheck action={Actions.Create} target={Targets.User}>
                  <AddUsers
                    compact
                    content="Add Users"
                    onAdded={events.onRefresh}
                    submitAction={addUsers}
                    title="Add Users"
                    allowExistingUsers={false}
                  />
                </PermissionCheck>
                <PermissionCheck action={Actions.View} target={Targets.User}>
                  <DownloadUsers
                    selection={state.selection}
                    filters={state.filters}
                    compact
                    disabled={state.total == 0}
                  >
                    Download{" "}
                    {totalSelected == 0 || totalSelected == state.total
                      ? "All"
                      : "Selected"}{" "}
                    ({totalSelected == 0 ? state.total : totalSelected})
                  </DownloadUsers>
                </PermissionCheck>
              </ActionBar.Item>
            </ActionBar>
            <Table
              {...tableProps}
              dataKey="id"
              emptyMessage="No users found"
              selectable
              className="users-table"
              onRowClick={(user) => history.push(UserRoutes.User(user.id))}
            >
              <Table.SelectableColumn
                value={state.selection}
                onChange={events.onChangeSelection}
                totalItems={state.total}
              />
              <Table.Column
                width="5%"
                render={(user) => {
                  const initials = getInitials([user.firstName, user.lastName]);
                  return <Avatar initials={initials} />;
                }}
              />
              <Table.Column
                header={
                  <SortHeader
                    title="User"
                    field={UserSortFields.Name}
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                render={(user) => (
                  <div className="stacked-column">
                    <span className="name">
                      {user.firstName + " " + user.lastName}
                    </span>
                    <span>{user.email}</span>
                  </div>
                )}
                width="22%"
              />

              <Table.Column
                field="role"
                width="11%"
                header="Type"
                render={(user) =>
                  user.isSystemAdmin ? (
                    <MaplesLabel type="primary">System Admin</MaplesLabel>
                  ) : (
                    <MaplesLabel type="default">Standard User</MaplesLabel>
                  )
                }
              />

              <Table.Column
                header={
                  <SortHeader
                    title="Organisation"
                    field={UserSortFields.Organisation}
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                field="organisationName"
                render={(user) => user.organisationName || "-"}
              />

              <Table.Column
                header={
                  <SortHeader
                    title="Status"
                    field={UserSortFields.Status}
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                width="10%"
                render={(user) =>
                  user.isDisabled ? (
                    <MaplesLabel type="default">Inactive</MaplesLabel>
                  ) : user.isVerified ? (
                    <MaplesLabel type="primary">Active</MaplesLabel>
                  ) : (
                    <MaplesLabel type="secondary">Unverified</MaplesLabel>
                  )
                }
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Groups"
                    field={UserSortFields.Groups}
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                field="groupsCount"
              />

              <Table.Column
                header={
                  <SortHeader
                    title="Invited"
                    field={UserSortFields.Created}
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                width="13%"
                render={(user) => (
                  <Format.DateTime
                    value={user.createdUtc}
                    format="DD MMM YYYY HH:mm"
                  />
                )}
              />

              <Table.Column
                header={
                  <SortHeader
                    title="Last Login"
                    field={UserSortFields.Login}
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                width="13%"
                render={(user) =>
                  user.lastLoginUtc ? (
                    <Format.DateTime
                      value={user.lastLoginUtc}
                      format="DD MMM YYYY HH:mm"
                    />
                  ) : (
                    <MaplesLabel type="primary">Not Logged In</MaplesLabel>
                  )
                }
              />

              <Table.Column
                width="5%"
                align="right"
                render={(user, colState) => (
                  <UserActions
                    user={user}
                    onRefresh={events.onRefresh}
                    itemIndex={colState.itemIndex}
                    visibleRowCount={visibleRowCount}
                  />
                )}
              />
            </Table>
            <TablePagination
              value={state.pagination}
              onChange={events.onChangePagination}
              totalItems={state.total}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>
        );
      }}
    />
  );
};

export default ListUsers;
