import React from "react";
import { Tab } from "semantic-ui-react";
import PropTypes from "prop-types";
import classNames from "classnames";

const MaplesTabs = ({ tabs = [], className, ...props }) => {
  const panes = tabs.map((t) => ({
    menuItem: t.title,
    render: () => <Tab.Pane>{t.content}</Tab.Pane>,
  }));
  return (
    <Tab
      panes={panes}
      className={classNames("maples-tabs", className)}
      {...props}
    />
  );
};

MaplesTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.any,
    })
  ),
};

export default MaplesTabs;
