import React from "react";
import Colours from "constants/colours";

const SitesIcon = ({ white = false, ...props }) => {
  const colour = white ? Colours.White : Colours.PrimaryBlue;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="11"
      viewBox="0 0 11 11"
      {...props}
    >
      <g id="business-and-trade" transform="translate(0 -0.178)">
        <path
          id="Path_1258"
          data-name="Path 1258"
          d="M5.972.959.935.188a.808.808,0,0,0-.651.18A.828.828,0,0,0,0,.987v9.729a.461.461,0,0,0,.458.462h1.49V8.754a.8.8,0,0,1,.8-.808H3.9a.8.8,0,0,1,.8.808v2.424H6.646V1.758a.807.807,0,0,0-.674-.8ZM2.521,6.907H1.833a.346.346,0,0,1,0-.693h.687a.346.346,0,0,1,0,.693Zm0-1.385H1.833a.346.346,0,0,1,0-.693h.687a.346.346,0,0,1,0,.693Zm0-1.385H1.833a.346.346,0,0,1,0-.693h.687a.346.346,0,0,1,0,.693Zm0-1.385H1.833a.346.346,0,0,1,0-.693h.687a.346.346,0,0,1,0,.693ZM4.813,6.907H4.125a.346.346,0,0,1,0-.693h.688a.346.346,0,0,1,0,.693Zm0-1.385H4.125a.346.346,0,0,1,0-.693h.688a.346.346,0,0,1,0,.693Zm0-1.385H4.125a.346.346,0,0,1,0-.693h.688a.346.346,0,0,1,0,.693Zm0-1.385H4.125a.346.346,0,0,1,0-.693h.688a.346.346,0,0,1,0,.693Z"
          transform="translate(0 0)"
          fill={colour}
        />
        <path
          id="Path_1259"
          data-name="Path 1259"
          d="M18.763,10.034,15.5,9.351v6.714h3.094a.8.8,0,0,0,.8-.8V10.818A.8.8,0,0,0,18.763,10.034ZM17.677,14.69H16.99a.344.344,0,0,1,0-.687h.688a.344.344,0,0,1,0,.688Zm0-1.375H16.99a.344.344,0,0,1,0-.687h.688a.344.344,0,0,1,0,.688Zm0-1.375H16.99a.344.344,0,0,1,0-.687h.688a.344.344,0,0,1,0,.688Z"
          transform="translate(-8.396 -4.887)"
          fill={colour}
        />
      </g>
    </svg>
  );
};

export default SitesIcon;
