import { apiGet, apiPost, apiDelete, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "ADMIN/LIST_ROLES";

const ActionTypes = {
  GetRoles: `${NAMESPACE}/GET_ROLES`,
  GetRole: `${NAMESPACE}/GET_ROLE`,
  AddRole: `${NAMESPACE}/ADD_ROLE`,
  UpdateRole: `${NAMESPACE}/UPDATE_ROLE`,
  DeleteRole: `${NAMESPACE}/DELETE_ROLE`,
};

export const getRoles = ({ filters, pagination }) => {
  const { pageSize, pageNumber } = pagination;
  const { search } = filters;

  return apiGet(ActionTypes.GetRoles, `roles`, {
    pageNumber,
    pageSize,
    search,
  });
};

export const getRole = (roleId) => {
  return apiGet(ActionTypes.GetRole, `roles/${roleId}`);
};

export const addRole = (data) => apiPost(ActionTypes.AddRole, "roles", data);

export const updateRole = (data, roleId) => {
  return apiPut(ActionTypes.UpdateRole, `roles/${roleId}`, data);
};

export const deleteRole = (roleId) => {
  return apiDelete(ActionTypes.DeleteRole, `roles/${roleId}`);
};
