import React, { useRef } from "react";
import { Input as SInput, Button } from "semantic-ui-react";
import classNames from "classnames";

const CopyBox = ({ content, label = "Copy", className, ...props }) => {
  const copyRef = useRef();

  return (
    <div className={classNames("copy-box", className)}>
      <input
        ref={copyRef}
        style={{ width: "100%" }}
        value={content}
        onChange={() => {}}
        onFocus={(e) => e.target.select()}
      />
      <Button
        onClick={() => {
          copyRef.current.select();
          document.execCommand("copy");
        }}
      >
        {label}
      </Button>
    </div>
  );

  return (
    <SInput
      ref={copyRef}
      style={{ width: "100%" }}
      value={content}
      onFocus={(e) => e.target.select()}
      action={{
        content: label,
        onClick: () => {
          copyRef.current.select();
          document.execCommand("copy");
        },
      }}
      {...props}
    />
  );
};

export default CopyBox;
