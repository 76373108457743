import React from "react";
import { Segment, Header, Divider } from "semantic-ui-react";
import { PageHeader } from "@redriver/cinnamon";
import { UserDetails, PasswordChange } from "features/System";
import { AppPageContent, MaplesPage } from "components/pages";

const ChangeMyPasswordPage = () => (
  <MaplesPage headerItems={["Header Items"]}>
    <Segment padded>
      <PasswordChange />
    </Segment>
  </MaplesPage>
);

export default ChangeMyPasswordPage;
