import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { FormBuilder, Form, ErrorMessage } from "@redriver/cinnamon";
import { SystemRoutes } from "constants/routes";
import { validateSetupToken, requestUserSetup } from "./actions";

const UserSetup = ({ token }) => {
  const [tokenValid, setTokenValid] = useState(null);
  const [setupSuccess, setSetupSuccess] = useState(false);

  if (!token || tokenValid === false) {
    return (
      <p>
        The invite is invalid. Please contact Maples Teesdale to arrange for
        another invite to be sent.
      </p>
    );
  }

  if (setupSuccess) {
    return (
      <div>
        <p>Your account has been setup</p>
        <div style={{ marginTop: 20 }}>
          <Button primary as={Link} to={SystemRoutes.Login} fluid>
            Sign In
          </Button>
        </div>
      </div>
    );
  }

  return (
    <FormBuilder
      loadAction={validateSetupToken}
      loadParams={token}
      onLoaded={(response) => setTokenValid(!!response && response.ok)}
      submitAction={requestUserSetup}
      submitParams={token}
      onSubmitted={() => setSetupSuccess(true)}
      renderLoading={false}
      renderSubmitting={false}
      renderError={false}
      renderForm={(
        formProps,
        { loading, slowLoading, submitting, slowSubmitting, error },
        events
      ) => {
        if (loading && slowLoading) return <p>Validating setup token...</p>;
        if (!tokenValid) return null;

        return (
          <Form {...formProps} className="public-form">
            <Form.Password
              field="newPassword"
              label="New Password"
              placeholder="Enter new password"
              disabled={submitting && slowSubmitting}
              autoFocus
              required
              minLength={8}
              fluid
            />
            <Form.Password
              field="confirmPassword"
              label="Confirm Password"
              placeholder="Confirm new password"
              disabled={submitting && slowSubmitting}
              required
              confirmField="newPassword"
              fluid
            />
            <Button
              primary
              onClick={events.onSubmit}
              disabled={submitting}
              loading={submitting && slowSubmitting}
              fluid
            >
              Submit
            </Button>
            <ErrorMessage error={error} />
          </Form>
        );
      }}
    />
  );
};

UserSetup.propTypes = {
  token: PropTypes.string.isRequired,
};

export default UserSetup;
