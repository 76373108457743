import React from "react";
import { useState } from "react";
import DashboardSites from "./DashboardSites";
import DashboardActivity from "./DashboardActivity";

const WorkspaceDashboard = () => {
  const [favsTimestamp, setFavsTimestamp] = useState(new Date().getTime());
  return (
    <section className="dashboard">
      <DashboardSites
        setFavsTimestamp={setFavsTimestamp}
        timestamp={favsTimestamp}
        favourites
      />
      <DashboardSites
        setFavsTimestamp={setFavsTimestamp}
        timestamp={favsTimestamp}
      />
      <DashboardActivity />
    </section>
  );
};

export default WorkspaceDashboard;
