import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "WORKSPACE/REDIRECT";

const ActionTypes = {
  GetReferenceLink: `${NAMESPACE}/GET_REFERENCE_LINK`,
};

export const getReferenceLink = ({ itemId, entityType }) => {
  const request = {
    itemId,
    entityType,
  };

  return apiGet(
    ActionTypes.GetReferenceLink,
    `workspace/content-link`,
    request
  );
};
