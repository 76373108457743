import React, { useState, useRef, useEffect, useMemo } from "react";
import {
  connectForm,
  FormUtils,
  FormValidators,
  Field,
} from "@redriver/cinnamon";
import classNames from "classnames";
import {
  Input as SInput,
  Icon,
  Button,
  Dropdown as SDropdown,
} from "semantic-ui-react";
import { checkTagEmailValid, searchUsers } from "./actions";
import { useDispatch } from "react-redux";

// same as one used in cinnamon
const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const TagInput = ({
  allowExistingUsers,
  label,
  onUpdated = () => {},
  customError,
  className,
  searchParams,
  ...props
}) => {
  const dispatch = useDispatch();
  const [selections, setSelections] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [manualOptions, setManualOptions] = useState([]);
  const [search, setSearch] = useState("");
  const [checkingEmail, setCheckingEmail] = useState(false);
  const [loadingEmails, setLoadingEmails] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    // report the current users to the parent component
    const items = manualOptions
      .filter((x) => !!x && selections.some((s) => s == x.value))
      .map((x) => ({
        id: x.value,
        email: x.text,
        firstName: x.firstName || "",
        lastName: x.lastName || "",
        isNew: x.value.includes("@"),
      }));
    onUpdated(items);
  }, [selections, manualOptions]);

  const onAdd = (e, { value }) => {
    const email = value.trim().toLowerCase();
    const isEmail = emailRegex.test(email);
    if (email) {
      if (isEmail) {
        setCheckingEmail(true);
        dispatch(checkTagEmailValid(email))
          .then(({ response, success }) => {
            if (success && response == true) {
              const next = [...selections, email];
              setSelections(next);
              setManualOptions([
                ...manualOptions,
                { text: email, value: email },
              ]);
              setSearch("");
              setErrors([]);
            } else {
              setErrors(["Email address is already in use"]);
            }
          })
          .finally(() => setCheckingEmail(false));
      } else {
        setErrors(["Not a valid email address"]);
      }
    }
  };

  const onChange = (e, { value }) => {
    if (checkingEmail) {
      return;
    }

    const diff = value.length - selections.length;

    if (diff > 0) {
      // adding new selection - only intested in selections of existing users (new emails are handled in onAdd)
      const item = value.filter((x) => !selections.some((y) => y == x))[0];
      if (!item.includes("@")) {
        setSelections(value);
        setSearch("");
        setErrors([]);
        setManualOptions([
          ...manualOptions.filter((x) => !!x),
          userOptions.find((x) => x.value == item),
        ]);
      }
    } else if (diff < 0) {
      //removing selection
      setManualOptions(
        manualOptions.filter((x) => !!x && value.some((y) => y == x.value))
      );
      setSelections(value);
    }
  };

  const onSearch = (e, { searchQuery }) => {
    setSearch(searchQuery);
    if (allowExistingUsers) {
      setLoadingEmails(true);
      dispatch(searchUsers(searchQuery, searchParams))
        .then(({ success, response }) => {
          if (success) {
            setUserOptions(
              response.results.map((x) => ({
                value: x.id,
                text: x.email,
                firstName: x.firstName,
                lastName: x.lastName,
              }))
            );
          }
        })
        .finally(() => setLoadingEmails(false));
    }
  };

  const allOptions = useMemo(() => {
    return [
      ...userOptions,
      ...manualOptions.filter(
        (x) => !!x && userOptions.every((o) => o.value != x.value)
      ),
    ].map((x) => ({ value: x.value, text: x.text }));
  }, [userOptions, manualOptions]);

  return (
    <section
      className={classNames(
        "tag-container",
        className,
        customError || errors.length > 0 ? "has-error" : null
      )}
      {...props}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <label>{label}</label>
        <span className="error">
          {errors.length > 0 ? errors[0] : customError}
        </span>
      </div>
      <SDropdown
        options={allOptions}
        placeholder="Enter email"
        search
        selection
        fluid
        multiple
        allowAdditions={userOptions.length == 0 && !loadingEmails}
        value={selections}
        onAddItem={onAdd}
        onChange={onChange}
        onSearchChange={onSearch}
        searchQuery={search}
        loading={loadingEmails || checkingEmail}
      />
    </section>
  );
};

export default TagInput;
