import React, { useState, useEffect } from "react";
import PageHeader from "./PageHeader";
import classNames from "classnames";
import PropTypes from "prop-types";

export const BreadcrumbContext = React.createContext([]);

const MaplesPage = ({ headerItems = [], className, children }) => {
  const breadcrumbHook = useState(headerItems);
  // setBreadcrumb is exposed via context. any sub component can access this via useContext to update the breadcrumbs.
  // a feature is responsible for controlling the breadcrumbs as it will have access to the required data
  const [breadcrumbValue, setBreadcrumb] = breadcrumbHook;
  return (
    <BreadcrumbContext.Provider value={breadcrumbHook}>
      <section className={classNames("maples-page", className)}>
        <PageHeader items={breadcrumbValue} />
        <div className="maples-content">{children}</div>
      </section>
    </BreadcrumbContext.Provider>
  );
};

MaplesPage.propTypes = {
  headerItems: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        content: PropTypes.any,
        to: PropTypes.string,
      }),
    ])
  ),
};

export default MaplesPage;
