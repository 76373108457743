import {
  Form,
  Modal,
  ModalBuilder,
  AJAX_SUCCESS,
  AJAX_ERROR,
  refreshJwt,
  notifySuccess,
  AJAX_START,
} from "@redriver/cinnamon";
import UploadIcon from "assets/icons/upload-folder.svg";
import { MultipleFileUpload } from "components/forms";
import { UploadIndicator } from "components/workspace";
import { FileIcon } from "components/icons";
import { getFileExtension, stripFileExtension } from "modules/helpers";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import { createFiles } from "../actions";
import { AJAX_PROGRESS } from "modules/axios";

const UploadFileModal = ({
  directoryId,
  onClose = () => {},
  as: As = Button,
  ...props
}) => {
  const initialFormData = { files: [] };

  const [visible, setVisible] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [modalSubmitting, setModalSubmitting] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [filesAdded, setFilesAdded] = useState(0);
  const [sizeErrors, setSizeErrors] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  const [uploadingState, setUploadingState] = useState({});

  const dispatch = useDispatch();

  const onFormChange = (field, data, applyChanges) => {
    setFormData(applyChanges(formData));
  };

  return (
    <React.Fragment>
      <As
        {...props}
        onClick={() => {
          setVisible(true);
        }}
      />
      <ModalBuilder
        onSubmit={() => {
          if (!formValid || formData.files.some((f) => !!sizeErrors[f.key])) {
            setShowErrors(true);
            return;
          }

          setModalSubmitting(true);
          setShowErrors(false);
          const data = {
            ...formData,
            files: formData.files.map((f) => {
              f.file = new File(
                [f.file],
                f.name + "." + getFileExtension(f.file.name),
                { type: f.file.type }
              );
              return f;
            }),
          };
          dispatch(refreshJwt()).then((jwt) =>
            createFiles(
              jwt,
              data,
              directoryId,
              false,
              ({ state, progress, response }) => {
                setUploadingState({ state, progress, response });
                if (state == AJAX_SUCCESS) {
                  data.files = data.files.filter((f) =>
                    response.data.some((x) => x.key === f.file.name)
                  );
                  data.files.map((f) => {
                    f.error = response.data.find(
                      (x) => x.key === f.file.name
                    ).value;
                  });
                  data.uploaded = data.files.map((x) => x.file);
                  if (data.files.length < formData.files.length) {
                    const nextFilesAdded =
                      filesAdded + (formData.files.length - data.files.length);
                    setFilesAdded(nextFilesAdded);
                    if (!data.files.length) {
                      const toastExpiry = 60000 * 60 * 24;
                      dispatch(
                        notifySuccess(
                          `${
                            nextFilesAdded > 1 ? nextFilesAdded + " f" : "F"
                          }ile${nextFilesAdded > 1 ? "s" : ""} uploaded`,
                          { expiry: toastExpiry }
                        )
                      );
                      setFormData(initialFormData);
                      onClose(true);
                      setVisible(false);
                      setFilesAdded(0);
                    } else {
                      setFormData(data);
                    }
                  } else {
                    setFormData(data);
                  }
                }
                if (state == AJAX_SUCCESS || state == AJAX_ERROR) {
                  setModalSubmitting(false);
                }
              }
            )
          );
        }}
        onCancel={() => {
          onClose(filesAdded > 0);
          setVisible(false);
          setShowErrors(false);
        }}
        onCancelled={() => {
          setFormData({
            ...initialFormData,
          });
          setFilesAdded(0);
        }}
        visible={visible}
        renderModal={(modalProps, formProps) => {
          return (
            <Modal.Edit
              className="upload-modal"
              {...modalProps}
              header="Upload File"
              submitting={modalSubmitting}
              cancelDisabled={modalSubmitting}
              submitLabel="Upload"
            >
              <Form
                {...formProps}
                value={formData}
                onChange={onFormChange}
                onValidated={setFormValid}
                className="upload-wrapper"
              >
                <MultipleFileUpload
                  field="upload"
                  label="Upload File"
                  fluid
                  uploadIcon={<img src={UploadIcon} />}
                  uploadText="Drag &amp; Drop Your Files Here"
                  replaceText="Drag &amp; Drop Your Files Here"
                />
                <UploadedFiles
                  field="files"
                  uploadField="upload"
                  sizeErrors={sizeErrors}
                  setSizeErrors={setSizeErrors}
                  formData={formData}
                  setFormData={setFormData}
                  modalSubmitting={modalSubmitting}
                />
                {showErrors && !formData.files.length && (
                  <p className="cin form-error">Upload a file</p>
                )}
                {(uploadingState.state == AJAX_START ||
                  uploadingState.state == AJAX_PROGRESS) && (
                  <UploadIndicator percent={uploadingState.progress} />
                )}
              </Form>
            </Modal.Edit>
          );
        }}
      />
    </React.Fragment>
  );
};

const UploadedFiles = ({
  field,
  uploadField,
  sizeErrors,
  setSizeErrors,
  formData,
  setFormData,
  modalSubmitting,
}) => {
  return (
    <div className="uploaded-files">
      <Form.Trigger
        field={uploadField}
        event="onChange"
        target={field}
        action="change"
        value={({ fields }) => {
          if (!fields[uploadField] || fields[uploadField].length === 0) {
            return fields[field];
          }
          return [
            ...(fields[field] || []),
            ...fields[uploadField].map((f, i) => ({
              key: Date.now() + i,
              name: stripFileExtension(f.name),
              file: f,
            })),
          ];
        }}
      />
      <Form.Array
        field={field}
        arrayKey="key"
        propagateUpdates="always"
        minLength={1}
      >
        {({ fields, parentFields, errors }) => {
          const sizeError = sizeErrors[fields.key];
          return (
            <React.Fragment>
              <div className="file-row">
                <FileIcon fileName={fields.file.name} />
                <Form.Input
                  field="name"
                  placeholder="Name"
                  fluid
                  disabled={getFileExtension(fields.file.name) == "zip"}
                  required
                  showErrors
                  customErrors={
                    sizeError
                      ? [sizeError]
                      : fields.error
                      ? [fields.error]
                      : errors.name
                  }
                />
                <Form.ArrayRemover
                  as={Icon}
                  name="trash alternate"
                  size="large"
                  className="pointer"
                  disabled={modalSubmitting}
                />
                <Form.Trigger
                  field="name"
                  action={() => {
                    const fileIndex = formData.files.findIndex(
                      (f) => f.key == fields.key
                    );
                    const nextFiles = [...formData.files];
                    if (fileIndex != -1) {
                      nextFiles[fileIndex].error = null;
                    }
                    setFormData({ ...formData, files: nextFiles });
                  }}
                />
              </div>
            </React.Fragment>
          );
        }}
      </Form.Array>
    </div>
  );
};

export default UploadFileModal;
