import React from "react";
import PropTypes from "prop-types";
import { Button, Transition } from "semantic-ui-react";

const LoadMoreButton = ({
  hasMore,
  loadingMore,
  onLoadMore,
  content,
  children,
  ...props
}) => {
  if (!hasMore) return null;
  return (
    <Transition
      transitionOnMount
      visible={hasMore}
      animation="fade"
      duration={500}
    >
      <div className="load-more-button">
        <Button
          {...props}
          loading={loadingMore}
          disabled={loadingMore}
          onClick={onLoadMore}
        >
          {children || content || "Load More"}
        </Button>
      </div>
    </Transition>
  );
};

LoadMoreButton.propTypes = {
  hasMore: PropTypes.bool,
  loadingMore: PropTypes.bool,
  onLoadMore: PropTypes.func
};

export default LoadMoreButton;
