import React from "react";
import { UserSetup } from "features/System";
import { PublicPageContent } from "components/pages";

const UserSetupPage = ({ match }) => (
  <PublicPageContent title="User Setup">
    <UserSetup token={match.params.token || ""} />
  </PublicPageContent>
);

export default UserSetupPage;
