import React, { useContext, useEffect, useState } from "react";
import { ContentBuilder, Format } from "@redriver/cinnamon";
import { getSiteDetails } from "./actions";
import { BreadcrumbContext } from "components/pages";
import * as Breadcrumbs from "constants/breadcrumbs";
import { AppRoutes, SiteRoutes } from "constants/routes";
import {
  DisplayDataSize,
  MaplesLabel,
  DetailsPanel,
  DetailsField,
  MaplesTabs,
} from "components/containers";
import { ListSiteUsers } from "../SiteUsers";
import { ListSiteGroups } from "../SiteGroups";
import { WorkspaceContent } from "features/Workspace/WorkspaceContent";
import { getDirectorySlug } from "modules/helpers";
import { useHistory } from "react-router";
import { extractWorkspaceIds } from "features/System/TreeMenu";
import { SiteActions } from "features/Sites/ListSites";

const SiteDetails = ({ siteId }) => {
  const [breadcrumbValue, setBreadcrumbs] = useContext(BreadcrumbContext);
  const [path, setPath] = useState([]);
  const history = useHistory();
  const [rootInfo, setRootInfo] = useState({
    siteId: null,
    siteName: null,
    rootDirectoryRef: null,
  });

  const selectedDirectoryRef =
    path.length > 0
      ? path[path.length - 1].substring(0, path[path.length - 1].indexOf("-"))
      : null;

  useEffect(() => {
    if (rootInfo.siteId) {
      // calc and store the path of directory being viewed
      const contentPath = history.location.pathname.replace(
        `${AppRoutes.Sites}/${siteId}`,
        ""
      );
      let targetPath = [rootInfo.rootDirectoryRef];
      const ids = extractWorkspaceIds(contentPath);
      if (ids.length > 0) {
        targetPath = [...targetPath, ...ids];
      }

      // update the breadcrumbs if the last one does not match the current path
      const matchingBreadcrumbIndex = breadcrumbValue.findIndex(
        (x) => x.to == history.location.pathname
      );
      setBreadcrumbs(
        breadcrumbValue.filter((_, i) => i <= matchingBreadcrumbIndex)
      );
      setPath(targetPath);
    }
  }, [history.location, rootInfo]);

  return (
    <ContentBuilder
      loadAction={getSiteDetails}
      loadParams={siteId}
      onLoaded={(data) => {
        setBreadcrumbs([
          { content: Breadcrumbs.Sites, to: AppRoutes.Sites },
          data?.name,
        ]);
        if (data) {
          setRootInfo({
            siteId: data.id,
            siteName: data.name,
            rootDirectoryRef: getDirectorySlug(
              data.rootDirectoryRef,
              data.name
            ),
          });
        }
      }}
      renderContent={(data, state, events) => {
        return (
          <React.Fragment>
            <DetailsPanel
              loading={state.loading}
              leftContent={
                <DetailsField title="Name">
                  <span style={{ marginRight: "1rem" }}>{data.name}</span>
                  {data.isActive ? (
                    <MaplesLabel type="primary">Active</MaplesLabel>
                  ) : (
                    <MaplesLabel type="default">Inactive</MaplesLabel>
                  )}
                </DetailsField>
              }
              rightContent={
                state.loading ? null : (
                  <React.Fragment>
                    <DetailsField title="Size">
                      <DisplayDataSize value={data.size} />
                    </DetailsField>
                    <DetailsField title="Created">
                      <Format.DateTime
                        value={data.createdUtc}
                        format="DD MMM YYYY HH:mm"
                      />
                    </DetailsField>
                    <DetailsField title="Modified">
                      {data.modifiedUtc ? (
                        <Format.DateTime
                          value={data.modifiedUtc}
                          format="DD MMM YYYY HH:mm"
                        />
                      ) : (
                        "-"
                      )}
                    </DetailsField>
                    <SiteActions
                      site={data}
                      hideView
                      onRefresh={({ isDeleted }) => {
                        if (isDeleted) {
                          history.push(AppRoutes.Sites);
                        } else {
                          events.onRefresh();
                        }
                      }}
                      className="header-actions"
                    />
                  </React.Fragment>
                )
              }
            />
            {!state.loading && path.length > 0 && rootInfo.siteId && (
              <MaplesTabs
                tabs={[
                  {
                    title: "Folder Structure",
                    content: (
                      <WorkspaceContent
                        path={path}
                        skipRoot
                        rootSource={SiteRoutes.Site(siteId)}
                        rootBreadcrumbs={[
                          { content: Breadcrumbs.Sites, to: AppRoutes.Sites },
                          {
                            content: rootInfo.siteName,
                            to: SiteRoutes.Site(siteId),
                          },
                        ]}
                      />
                    ),
                  },
                  {
                    title: "Users",
                    content: (
                      <ListSiteUsers
                        siteId={siteId}
                        directoryRef={selectedDirectoryRef}
                      />
                    ),
                  },
                  {
                    title: "Groups",
                    content: (
                      <ListSiteGroups
                        siteId={siteId}
                        directoryRef={selectedDirectoryRef}
                      />
                    ),
                  },
                ]}
              />
            )}
          </React.Fragment>
        );
      }}
    />
  );
};

export default SiteDetails;
