import React from "react";
import { Dropdown } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { Table } from "@redriver/cinnamon";
import { withPermissions } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { updateUserStatus } from "features/Admin/ListUsers/actions";
import SendPasswordReset from "./SendPasswordReset";
import ResendVerification from "./ResendVerification";
import EditUser from "./EditUser";
import { UserRoutes } from "constants/routes";
import ActionsModal from "components/modals/ActionsModal";
import classNames from "classnames";
import { TableActionMenu } from "components/misc";

const UserActions = ({
  user,
  onRefresh,
  hasPermission,
  children,
  hideView = false,
  className,
  itemIndex,
  visibleRowCount,
  ...props
}) => {
  const history = useHistory();
  const { isDisabled, isVerified } = user;
  const hasEditPermission = hasPermission(Targets.User, Actions.Edit);

  const access = {
    canEdit: hasEditPermission,
    canActivate: isDisabled && hasEditPermission,
    canDeactivate: !isDisabled && hasEditPermission,
    canSendPasswordReset: !isDisabled && isVerified && hasEditPermission,
    canResendVerification: !isDisabled && !isVerified && hasEditPermission,
  };

  if (!Object.values(access).some((x) => x)) return null;

  return (
    <TableActionMenu
      className={className}
      {...props}
      itemIndex={itemIndex}
      visibleRowCount={visibleRowCount}
    >
      {!hideView && (
        <Table.MenuItem onClick={() => history.push(UserRoutes.User(user.id))}>
          View User
        </Table.MenuItem>
      )}
      {access.canEdit && (
        <EditUser as={Table.MenuItem} onUpdated={onRefresh} userId={user.id}>
          Edit User
        </EditUser>
      )}
      {access.canEdit && <Dropdown.Divider />}
      {access.canSendPasswordReset && (
        <SendPasswordReset
          user={user}
          as={Table.MenuItem}
          submitParams={{ selected: [user.id] }}
        >
          Send Password Reset
        </SendPasswordReset>
      )}
      {access.canResendVerification && (
        <ResendVerification user={user} as={Table.MenuItem}>
          Resend Verification
        </ResendVerification>
      )}
      {
        <ActionsModal
          as={Table.MenuItem}
          submitAction={updateUserStatus}
          id={user.id}
          name={`${user.firstName} ${user.lastName}`}
          onRefresh={onRefresh}
          actionName={!user.isDisabled ? "lock out" : "activate"}
          header={!user.isDisabled ? "Lock Out User" : "Activate User"}
          submitParams={{
            isDisabled: !user.isDisabled,
            selected: [user.id],
          }}
        >
          {access.canActivate ? "Activate Account" : "Lock Out"}
        </ActionsModal>
      }
      {children}
    </TableActionMenu>
  );
};

export default withPermissions(UserActions);
