import React from "react";
import { Table } from "@redriver/cinnamon";
import { useHistory } from "react-router-dom";
import { UserRoutes } from "constants/routes";
import { TableActionMenu } from "components/misc";

const SiteUserActions = ({ user }) => {
  const history = useHistory();

  return (
    <TableActionMenu>
      <Table.MenuItem onClick={() => history.push(UserRoutes.User(user.id))}>
        Go To User
      </Table.MenuItem>
    </TableActionMenu>
  );
};

export default SiteUserActions;
