import React from "react";
import { ListBuilder, Table, ActionBar, Format } from "@redriver/cinnamon";
import { TablePagination } from "components/pagination";
import FilterSiteUsers from "./FilterSiteUsers";
import { MaplesLabel } from "components/containers";
import { Avatar, SortHeader } from "components/misc";
import { getInitials } from "modules/helpers";
import { Sort } from "constants/enums";
import { UserSortFields } from "constants/sortFields";
import { getSiteUsers } from "./actions";
import SiteUserActions from "./SiteUserActions";
import { useHistory } from "react-router-dom";
import { UserRoutes } from "constants/routes";

const ListSiteUsers = ({ siteId, directoryRef }) => {
  const history = useHistory();
  return (
    <ListBuilder
      withTable
      loadAction={getSiteUsers}
      loadParams={{ siteId, directoryRef }}
      initialFilters={{ sortName: "name", sortDirection: Sort.Asc }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      initialPagination={{ pageSize: 25, pageNumber: 1 }}
      renderList={(tableProps, state, events) => {
        return (
          <div>
            <ActionBar>
              <ActionBar.Item>
                <FilterSiteUsers
                  value={state.filters}
                  onChange={events.onChangeFilters}
                />
              </ActionBar.Item>
            </ActionBar>
            <Table
              {...tableProps}
              dataKey="id"
              emptyMessage="No users found"
              className="users-table"
              onRowClick={(user) => history.push(UserRoutes.User(user.id))}
            >
              <Table.Column
                width="5%"
                render={(user) => {
                  const initials = getInitials([user.firstName, user.lastName]);
                  return <Avatar initials={initials} />;
                }}
              />
              <Table.Column
                header={
                  <SortHeader
                    title="User"
                    field={UserSortFields.Name}
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                render={(user) => (
                  <div className="stacked-column">
                    <span className="name">
                      {user.firstName + " " + user.lastName}
                    </span>
                    <span>{user.email}</span>
                  </div>
                )}
                width="22%"
              />

              <Table.Column
                field="role"
                width="22%"
                header="Type"
                render={(user) =>
                  user.isSystemAdmin ? (
                    <MaplesLabel type="primary">System Admin</MaplesLabel>
                  ) : (
                    <MaplesLabel type="default">Standard User</MaplesLabel>
                  )
                }
              />

              <Table.Column
                header={
                  <SortHeader
                    title="Groups"
                    field={UserSortFields.Groups}
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                field="groupsCount"
              />

              <Table.Column
                header={
                  <SortHeader
                    title="Invited"
                    field={UserSortFields.Created}
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                width="13%"
                render={(user) => (
                  <Format.DateTime
                    value={user.createdUtc}
                    format="DD MMM YYYY HH:mm"
                  />
                )}
              />

              <Table.Column
                header={
                  <SortHeader
                    title="Last Login"
                    field={UserSortFields.Login}
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                width="13%"
                render={(user) =>
                  user.lastLoginUtc ? (
                    <Format.DateTime
                      value={user.lastLoginUtc}
                      format="DD MMM YYYY HH:mm"
                    />
                  ) : (
                    <MaplesLabel type="primary">Not Logged In</MaplesLabel>
                  )
                }
              />

              {directoryRef && (
                <Table.Column
                  header={"Access"}
                  render={(user) => user.access || "None"}
                />
              )}

              <Table.Column
                width="5%"
                align="right"
                render={(user) => <SiteUserActions user={user} />}
              />
            </Table>
            <TablePagination
              value={state.pagination}
              onChange={events.onChangePagination}
              totalItems={state.total}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>
        );
      }}
    />
  );
};

export default ListSiteUsers;
