import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";

const ActionsModal = ({
  as: As = Button,
  name,
  textEnding,
  onRefresh,
  header,
  submitAction,
  submitParams,
  actionName,
  ...props
}) => {
  return (
    <ModalBuilder
      submitAction={submitAction}
      submitParams={{
        ...submitParams,
      }}
      onSubmitted={onRefresh}
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      renderModal={(modalProps) => (
        <Modal.Confirmation {...modalProps} header={header}>
          Are you sure you wish to {actionName} <b>{name}</b>
          {textEnding ? " " + textEnding : ""}?
        </Modal.Confirmation>
      )}
    />
  );
};

export default ActionsModal;
