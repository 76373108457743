import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";

const BulkActionsModal = ({
  as: As = Button,
  submitAction,
  submitParams,
  actionName,
  header,
  totalSelected,
  entityName,
  onRefresh,
  ...props
}) => {
  return (
    <ModalBuilder
      submitAction={submitAction}
      submitParams={{
        ...submitParams,
      }}
      onSubmitted={onRefresh}
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      renderModal={(modalProps) => (
        <Modal.Confirmation {...modalProps} header={header}>
          Are you sure you wish to {actionName} {totalSelected} {entityName}
          {totalSelected > 1 ? "s" : ""}?
        </Modal.Confirmation>
      )}
    />
  );
};

export default BulkActionsModal;
