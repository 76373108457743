import React from "react";
import { Image, Icon } from "semantic-ui-react";
import {
  NavMenu,
  useResponsive,
  SidePanel,
  useAuthClaims,
} from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { AppRoutes, SystemRoutes } from "constants/routes";
import Logo from "assets/logo.svg";
import {
  BinIcon,
  UsersIcon,
  SitesIcon,
  GroupsIcon,
  OrganisationsIcon,
} from "components/icons";
import classNames from "classnames";
import WorkspaceMenu from "features/System/WorkspaceMenu";
import { Link } from "react-router-dom";
import { claimExists } from "components/auth/utils";

const MaplesTeesdaleMenu = () => {
  const { screenSize } = useResponsive();
  const claims = useAuthClaims();
  const permissions = claims ? claims.permissions : {};

  const canViewUsers = claimExists(permissions, Targets.User, Actions.View);
  const canViewSites = claimExists(permissions, Targets.Site, Actions.View);
  const canViewGroups = claimExists(permissions, Targets.Group, Actions.View);

  const isDesktop = screenSize === "desktop";

  const menu = (
    <NavMenu vertical className="app-nav-menu standard">
      <WorkspaceMenu />
      <PermissionCheck action={Actions.View} target={Targets.Bin}>
        <NavMenu.Item link={AppRoutes.Bin}>
          {({ active }) => (
            <React.Fragment>
              <BinIcon white={active} />
              <span className="item-text">Bin</span>
            </React.Fragment>
          )}
        </NavMenu.Item>
      </PermissionCheck>
    </NavMenu>
  );

  const adminMenu = (
    <NavMenu vertical className="app-nav-menu admin">
      {canViewSites || canViewUsers || canViewGroups ? (
        <NavMenu.Item className="divider"></NavMenu.Item>
      ) : null}
      <PermissionCheck action={Actions.View} target={Targets.User}>
        <NavMenu.Item link={AppRoutes.Users}>
          {({ active }) => (
            <React.Fragment>
              <UsersIcon white={active} />
              <span className="item-text">Users</span>
            </React.Fragment>
          )}
        </NavMenu.Item>
      </PermissionCheck>

      <PermissionCheck action={Actions.View} target={Targets.Site}>
        <NavMenu.Item link={AppRoutes.Sites}>
          {({ active }) => (
            <React.Fragment>
              <SitesIcon white={active} />
              <span className="item-text">Sites</span>
            </React.Fragment>
          )}
        </NavMenu.Item>
      </PermissionCheck>
      <PermissionCheck action={Actions.View} target={Targets.Group}>
        <NavMenu.Item link={AppRoutes.Groups}>
          {({ active }) => (
            <React.Fragment>
              <GroupsIcon white={active} />
              <span className="item-text">Groups</span>
            </React.Fragment>
          )}
        </NavMenu.Item>
      </PermissionCheck>
      <PermissionCheck action={Actions.View} target={Targets.Organisation}>
        <NavMenu.Item link={AppRoutes.Organisations}>
          {({ active }) => (
            <React.Fragment>
              <OrganisationsIcon white={active} />
              <span className="item-text">Organisations</span>
            </React.Fragment>
          )}
        </NavMenu.Item>
      </PermissionCheck>
      {!isDesktop && (
        <React.Fragment>
          <NavMenu.Item link={SystemRoutes.Account}>Account</NavMenu.Item>
          <NavMenu.Item link={SystemRoutes.ChangePassword}>
            Change Password
          </NavMenu.Item>
          <NavMenu.Item link={SystemRoutes.Logout}>Sign Out</NavMenu.Item>
        </React.Fragment>
      )}
    </NavMenu>
  );

  return isDesktop ? (
    <nav className="navbar-container">
      <Link to={AppRoutes.Workspace}>
        <Image src={Logo} alt="MaplesTeesdale" className="logo" />
      </Link>
      {menu}
      {adminMenu}
    </nav>
  ) : (
    <SidePanel
      position="left"
      closeIcon
      trigger={
        <div className="burger">
          <Icon name="sidebar" size="large" />
        </div>
      }
      className={classNames("navbar-container side")}
    >
      {menu}
      {adminMenu}
    </SidePanel>
  );
};

export default MaplesTeesdaleMenu;
