import React from "react";
import { ListUsers } from "features/Admin";
import { PageHeader } from "@redriver/cinnamon";
import { AppPageContent, MaplesPage } from "components/pages";
import * as BreadcrumbText from "constants/breadcrumbs";
import queryString from "query-string";

const UserListPage = ({ location }) => {
  const queryParams = queryString.parse(location.search);
  const organisationId = queryParams.organisationId || null;
  const organisationName = queryParams.organisationName || null;
  return (
    <MaplesPage headerItems={[BreadcrumbText.Users]}>
      <ListUsers
        organisationId={organisationId}
        organisationName={organisationName}
      />
    </MaplesPage>
  );
};

export default UserListPage;
