import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Pagination, Icon } from "semantic-ui-react";

/**
 * A set of navigation buttons for changing the page number
 */
class MaplesPageNumber extends React.Component {
  onPageChange = (e, { activePage }) => this.props.onChange(activePage);

  render() {
    const {
      value,
      pageSize,
      totalItems,
      className,
      ...excessProps
    } = this.props;
    const totalPages = totalItems > 0 ? Math.ceil(totalItems / pageSize) : 0;

    return (
      <Pagination
        activePage={value}
        onPageChange={this.onPageChange}
        totalPages={totalPages}
        size="tiny"
        firstItem={null}
        lastItem={null}
        prevItem={{
          "aria-label": "Previous item",
          content: <Icon name="angle left" />,
          disabled: value <= 1,
        }}
        nextItem={{
          "aria-label": "Next item",
          content: <Icon name="angle right" />,
          disabled: value >= totalPages,
        }}
        className={classNames("cin page-number", className)}
        {...excessProps}
      />
    );
  }
}

MaplesPageNumber.propTypes = {
  /**
   * The currently selected page
   */
  value: PropTypes.number,
  /**
   * Event handler called when the page number is changed
   */
  onChange: PropTypes.func,
  /**
   * The currently selected page size (used to calculate the total number of pages)
   */
  pageSize: PropTypes.number,
  /**
   * The total number of items to navigate (used to calculate the total number of pages)
   */
  totalItems: PropTypes.number,
  /**
   * Additional classes for styling
   */
  className: PropTypes.string,
};

export default MaplesPageNumber;
