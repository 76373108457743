import React from "react";
import classNames from "classnames";

const DetailsField = ({ title, className, children }) => (
  <div className={classNames("details-field", className)}>
    <label>{title}</label>
    <div className="details-content">{children}</div>
  </div>
);

export default DetailsField;
