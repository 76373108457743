import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import { moveContent, validMoveContent } from "./actions";
import {
  getWorkspaceDirectories,
  setWorkspaceDirectories,
  resetWorkspaceDirectories,
} from "./actions";
import FolderIcon from "assets/files/folder.png";
import SelectedFolderIcon from "assets/icons/selected-folder.svg";

import { getWorkspaceMenuState } from "./selectors";
import { MenuTree } from "features/System/TreeMenu";
import classNames from "classnames";

const MoveWorkspaceItems = ({
  as: As = Button,
  item,
  directoryId,
  siteRootDirectoryId,
  selection,
  onClose = () => {},
  ...props
}) => {
  const dispatch = useDispatch();
  const initialFormData = {};

  const [visible, setVisible] = useState(false);

  const [formValid, setFormValid] = useState(null);
  const [checkingTarget, setCheckingTarget] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [modalSubmitting, setModalSubmitting] = useState(false);

  const onFormChange = (field, data, applyChanges) => {
    setFormData(applyChanges(formData));
  };

  const treeData = useSelector(getWorkspaceMenuState);

  const getTreeData = function (rootDirectoryId, pageNumber = 1) {
    dispatch(
      getWorkspaceDirectories(rootDirectoryId, { pageSize: 15, pageNumber })
    ).then((x) =>
      dispatch(
        setWorkspaceDirectories(
          x,
          rootDirectoryId,
          pageNumber,
          rootDirectoryId == siteRootDirectoryId
        )
      )
    );
  };

  return (
    <React.Fragment>
      <As
        {...props}
        onClick={() => {
          setVisible(true);
        }}
      />
      <ModalBuilder
        withForm
        onLoad={() => {
          getTreeData(siteRootDirectoryId);
        }}
        onSubmit={() => {
          if (!formValid || checkingTarget) {
            return;
          }

          setModalSubmitting(true);
          dispatch(moveContent(formData, { directoryId, ...selection })).then(
            (result) => {
              if (result.success) {
                setFormData(initialFormData);
                setVisible(false);
                onClose();
                setFormValid(null);
                dispatch(resetWorkspaceDirectories());
              }
              setModalSubmitting(false);
            }
          );
        }}
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        onCancelled={() => {
          setFormData({
            ...initialFormData,
          });
          setFormValid(null);
          dispatch(resetWorkspaceDirectories());
        }}
        renderModal={(modalProps, formProps, state, events) => (
          <Modal
            {...modalProps}
            header="Select Destination Folder"
            submitLabel="Move"
            size="tiny"
            slowSubmitting={modalSubmitting}
            submitting={modalSubmitting}
            cancelDisabled={modalSubmitting}
            submitDisabled={
              formValid != true ||
              formData.targetDirectoryId == null ||
              checkingTarget
            }
          >
            <Form {...formProps} onChange={onFormChange}>
              <div className="folder-selector">
                <MenuTree
                  sites={
                    treeData.data.results[0] != null
                      ? treeData.data.results[0].directories
                      : []
                  }
                  onNodeExpanded={(x) => {
                    if (!!x.directoriesCount && !x.directories.length) {
                      getTreeData(x.id);
                    }
                  }}
                  totalNodes={treeData.data.totalResults}
                  onLoadMore={() => getTreeData(null, treeData.pagesLoaded + 1)}
                  loadingIds={treeData.loadingIds}
                  className="workspace-item"
                  rootId={siteRootDirectoryId}
                  rootName={treeData.data.results[0]?.name}
                  onClick={(id) => {
                    if (modalSubmitting) return;
                    const nextFormData = { ...formData, targetDirectoryId: id };
                    setFormData(nextFormData);
                    setCheckingTarget(true);
                    dispatch(
                      validMoveContent(nextFormData, {
                        directoryId,
                        ...selection,
                      })
                    ).then((result) => {
                      setCheckingTarget(false);
                      setFormValid(result.response);
                    });
                  }}
                  indent={12}
                  expandRoot
                  renderCustomContent={(node) => {
                    return (
                      <div
                        className={classNames(
                          "folder-selector-item",
                          node.id == formData.targetDirectoryId
                            ? "selected"
                            : null
                        )}
                      >
                        <img
                          className="folder-icon"
                          src={
                            node.id === formData.targetDirectoryId
                              ? SelectedFolderIcon
                              : FolderIcon
                          }
                        ></img>
                        <p>{node.name}</p>
                      </div>
                    );
                  }}
                />
              </div>
              {formValid == false && !checkingTarget && !modalSubmitting && (
                <p className="error-text">Destination not valid</p>
              )}
            </Form>
          </Modal>
        )}
      />
    </React.Fragment>
  );
};

export default MoveWorkspaceItems;
