import React, { useRef, useEffect } from "react";

// run a function when the value has changed (assumes simple values - will not work for objects/arrays)
const RunOnChange = ({ value, func }) => {
  const previousValue = useRef(null);
  useEffect(() => {
    if (previousValue != value) {
      func();
    }
    previousValue.current = value;
  }, [value]);
  return null;
};

export default RunOnChange;
