import React from "react";
import {
  ListBuilder,
  Table,
  Format,
  ActionBar,
  ActionMenu,
} from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import FilterOrganisations from "./FilterOrganisations";
import { TablePagination } from "components/pagination";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import { SortHeader, RunOnChange } from "components/misc";
import { Sort } from "constants/enums";
import { getOrganisations } from "./actions";
import AddOrganisation from "./AddOrganisation";
import OrganisationActions from "./OrganisationActions";

const ListOrganisations = ({}) => {
  return (
    <ListBuilder
      withTable
      loadAction={getOrganisations}
      initialFilters={{ sortName: "name", sortDirection: Sort.Asc }}
      initialPagination={{ pageSize: 25, pageNumber: 1 }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      renderList={(tableProps, state, events) => {
        const visibleRowCount = tableProps?.data?.length;
        return (
          <div>
            <ActionBar>
              <ActionBar.Item>
                <FilterOrganisations
                  value={state.filters}
                  onChange={events.onChangeFilters}
                />
              </ActionBar.Item>
              <ActionBar.Item fluid align="right">
                <PermissionCheck
                  action={Actions.Create}
                  target={Targets.Organisation}
                >
                  <AddOrganisation
                    align="right"
                    compact
                    content="Add Organisation"
                    onAdded={events.onRefresh}
                  />
                </PermissionCheck>
              </ActionBar.Item>
            </ActionBar>
            <Table
              {...tableProps}
              dataKey="id"
              emptyMessage="No organisations found"
            >
              <Table.Column
                header={
                  <SortHeader
                    title="Name"
                    field="name"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                field="name"
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Users"
                    field="userCount"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                field="userCount"
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Created"
                    field="createdUtc"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                render={(org) => (
                  <Format.DateTime
                    value={org.createdUtc}
                    format="DD MMM YYYY HH:mm"
                  />
                )}
              />
              <Table.Column
                header={
                  <SortHeader
                    title="Modified"
                    field="modified"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
                render={(org) =>
                  org.modifiedUtc ? (
                    <Format.DateTime
                      value={org.modifiedUtc}
                      format="DD MMM YYYY HH:mm"
                    />
                  ) : (
                    "-"
                  )
                }
              />
              <Table.Column
                width="5%"
                align="right"
                render={(org, colState) => (
                  <OrganisationActions
                    organisation={org}
                    onRefresh={events.onRefresh}
                    itemIndex={colState.itemIndex}
                    visibleRowCount={visibleRowCount}
                  />
                )}
              />
            </Table>
            <TablePagination
              value={state.pagination}
              onChange={events.onChangePagination}
              totalItems={state.total}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </div>
        );
      }}
    />
  );
};

export default ListOrganisations;
