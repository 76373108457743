import React from "react";
import classNames from "classnames";
import { useApiGet } from "@redriver/cinnamon";
import { useDownloadStreaming } from "modules/files";

const FileLink = ({
  file,
  shareToken,
  name,
  className,
  As = BaseLink,
  ...props
}) => {
  const url = file ? `files/${file?.id}` : `share/${shareToken}/download`;
  const [download, beginDownload] = useApiGet(url, null, {
    lazy: true,
    auth: !!file,
    preventResponseHandling: true,
  });

  useDownloadStreaming(download, file ? file.name : name);

  const renderLink = (linkProps = {}) => (
    <As
      {...props}
      {...linkProps}
      className={classNames("file-link", { busy: download.busy }, className)}
    />
  );

  return renderLink({
    onClick: () => {
      if (download.busy) return;
      beginDownload();
    },
  });
};

const BaseLink = ({ children, ...props }) => <a {...props}>{children}</a>;

export default FileLink;
