import React, { useEffect } from "react";
import { PageLoader } from "@redriver/cinnamon";
import { useHistory } from "react-router-dom";
import { getReferenceLink } from "./actions";
import { useDispatch } from "react-redux";
import { AppRoutes } from "constants/routes";

const WorkspaceItemRedirect = ({ itemId, entityType }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(getReferenceLink({ itemId, entityType })).then(
      ({ success, response }) => {
        if (success) {
          const url = AppRoutes.Workspace + response.itemLink;
          history.replace(url);
        }
      }
    );
  }, []);

  return <PageLoader />;
};

export default WorkspaceItemRedirect;
