import React from "react";
import { Table } from "@redriver/cinnamon";
import { useHistory } from "react-router-dom";
import { GroupRoutes } from "constants/routes";
import ActionsModal from "components/modals/ActionsModal";
import { updateGroupStatus, deleteGroup } from "./actions";
import { GroupStatus } from "constants/enums";
import classNames from "classnames";
import EditGroup from "./EditGroup";
import { TableActionMenu } from "components/misc";

const GroupActions = ({
  group,
  onRefresh,
  hideView = false,
  className,
  itemIndex,
  visibleRowCount,
  ...props
}) => {
  const history = useHistory();

  return (
    <TableActionMenu
      className={className}
      itemIndex={itemIndex}
      visibleRowCount={visibleRowCount}
      {...props}
    >
      {!hideView && (
        <Table.MenuItem
          onClick={() => history.push(GroupRoutes.Group(group.id))}
        >
          Go To Group
        </Table.MenuItem>
      )}

      <ActionsModal
        as={Table.MenuItem}
        submitAction={updateGroupStatus}
        id={group.id}
        name={group.name}
        onRefresh={onRefresh}
        actionName={group.isActive ? "deactivate" : "activate"}
        header={group.isActive ? "Deactivate Group" : "Activate Group"}
        submitParams={{
          status: group.isActive ? GroupStatus.Inactive : GroupStatus.Active,
          selected: [group.id],
        }}
      >
        {group.isActive ? "Deactivate Group" : "Activate Group"}
      </ActionsModal>

      <EditGroup as={Table.MenuItem} onUpdated={onRefresh} group={group}>
        Edit Group Name
      </EditGroup>

      <ActionsModal
        as={Table.MenuItem}
        submitAction={deleteGroup}
        id={group.id}
        name={group.name}
        actionName="delete"
        onRefresh={() => onRefresh({ isDeleted: true })}
        header="Delete group"
        submitParams={{
          selected: [group.id],
        }}
      >
        Delete
      </ActionsModal>
    </TableActionMenu>
  );
};

export default GroupActions;
