import React from "react";
import { Table as STable, Loader } from "semantic-ui-react";
import WorkspaceContentHeader from "./WorkspaceContentHeader";
import WorkspaceContentRow from "./WorkspaceContentRow";
import { produce } from "immer";
import { Droppable, DragDropContext } from "react-beautiful-dnd";

const WorkspaceContentTable = ({
  tableProps,
  state,
  selectedLength,
  onChangeFilters,
  reordering,
  tableData,
  path,
  skipRoot,
  rootSource,
  events,
  setTableData,
}) => (
  <STable>
    <WorkspaceContentHeader
      tableData={tableData}
      totalResults={tableProps.totalResults}
      state={state}
      selectedLength={selectedLength}
      onChangeFilters={onChangeFilters}
      reordering={reordering}
      events={events}
    />
    <DragDropContext
      onDragEnd={({ source, destination }) => {
        const sourceIndex = source.index;
        const destinationIndex = destination.index;
        if (sourceIndex !== destinationIndex) {
          const nextData = produce(tableData, (draft) => {
            const deleted = draft.splice(sourceIndex, 1);
            if (deleted.length) {
              draft.splice(destinationIndex, 0, deleted[0]);
            }
          });
          setTableData(nextData);
        }
      }}
    >
      <Droppable droppableId="workspaceDroppable">
        {(provided, snapshot) => (
          <tbody {...provided.droppableProps} ref={provided.innerRef}>
            {!(tableData || []).length ? (
              <STable.Row>
                <STable.Cell colSpan={8} textAlign="center">
                  No files found
                </STable.Cell>
              </STable.Row>
            ) : state.loading || state.slowLoading ? (
              <STable.Row>
                <STable.Cell
                  colSpan={8}
                  style={{
                    minHeight: "80px",
                    textAlign: "center",
                  }}
                >
                  <Loader size="large" active inline="centered" />
                </STable.Cell>
              </STable.Row>
            ) : (
              (tableData || []).map((item, i) => {
                return (
                  <WorkspaceContentRow
                    key={i}
                    index={i}
                    item={item}
                    path={path}
                    skipRoot={skipRoot}
                    rootSource={rootSource}
                    reordering={reordering}
                    state={state}
                    events={events}
                    tableProps={tableProps}
                  />
                );
              })
            )}
            {provided.placeholder}
          </tbody>
        )}
      </Droppable>
    </DragDropContext>
  </STable>
);

export default WorkspaceContentTable;
