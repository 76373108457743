import { apiGet, apiPut, apiDelete, apiPost } from "@redriver/cinnamon";

const NAMESPACE = "SITES/LIST_SITES";

const ActionTypes = {
  GetSites: `${NAMESPACE}/GET_SITES`,
  AddSite: `${NAMESPACE}/ADD_SITE`,
  CheckIsSiteNameValid: `${NAMESPACE}/CHECK_VALID_SITE_NAME`,
  UpdateSiteStatus: `${NAMESPACE}/UPDATE_SITE_STATUS`,
  DeleteSite: `${NAMESPACE}/DELETE_SITE`,
  GetSite: `${NAMESPACE}/GET_SITE`,
  UpdateSite: `${NAMESPACE}/UPDATE_SITE`,
  ExportDownloadHistory: `${NAMESPACE}/EXPORT`,
};

export const getSites = ({ filters, pagination }) => {
  const { pageSize, pageNumber } = pagination;
  const { search, sortName, sortDirection } = filters;
  return apiGet(ActionTypes.GetSites, "sites", {
    pageSize,
    pageNumber,
    search,
    sortName,
    sortDirection,
  });
};

export const updateSite = (formData, siteId) => {
  return apiPut(ActionTypes.UpdateSite, `sites/${siteId}`, formData);
};

export const getSite = (siteId) =>
  apiGet(ActionTypes.GetSite, `sites/${siteId}`);

export const addSite = (data) => apiPost(ActionTypes.AddSite, "sites", data);

export const checkValidSiteName = (name, currentSiteId = null) =>
  apiGet(
    ActionTypes.CheckIsSiteNameValid,
    "sites/valid-name",
    {
      name,
      currentSiteId,
    },
    {
      debounce: 300,
    }
  );

export const updateSiteStatus = (submitParams) => {
  return apiPut(ActionTypes.UpdateSiteStatus, "sites/status", submitParams);
};

export const deleteSite = (submitParams) => {
  return apiDelete(ActionTypes.DeleteSite, "sites", submitParams);
};
export const exportDownloadHistory = (submitParams) =>
  apiGet(ActionTypes.ExportDownloadHistory, "audits/download", {
    startDateUtc: submitParams.datesUtc.start,
    endDateUtc: submitParams.datesUtc.end,
    auditTypes: submitParams.auditTypes,
  });
