import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import { Button, Input as SInput } from "semantic-ui-react";
import { shareFile } from "./actions";
import { AppRoutes } from "constants/routes";
import classNames from "classnames";
import { CopyBox } from "components/misc";

const shareOptions = [
  { value: "Link", text: "By Link" },
  { value: "Email", text: "By Email" },
];

const ShareModal = ({ as: As = Button, file, onLinkShared, ...props }) => {
  const dispatch = useDispatch();
  const [modalVisisble, setModalVisible] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [linkToken, setLinkToken] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const shareUrl = `${process.env.BASE_URL}${AppRoutes.SharedFile(
    linkToken
  ).replace(/^\//, "")}`;

  return (
    <React.Fragment>
      <As onClick={() => setModalVisible(true)} {...props} />
      <ModalBuilder
        visible={modalVisisble}
        withForm
        initialData={{
          shareType: null,
          emails: [],
          daysDuration: null,
        }}
        onLoaded={() => {
          setLinkToken(null);
        }}
        onCancel={() => {
          if (linkToken) onLinkShared();
          setModalVisible(false);
        }}
        onSubmit={(formData, fileId) => {
          if (!linkToken) {
            const isLinkShare = formData.shareType == "Link";
            setSubmitting(true);
            dispatch(shareFile(formData, fileId))
              .then(({ success, response }) => {
                if (success) {
                  if (!isLinkShare) {
                    setModalVisible(false);
                  } else {
                    setLinkToken(response);
                  }
                }
              })
              .finally(() => setSubmitting(false));
          }
        }}
        submitParams={file.id}
        renderModal={(modalProps, formProps, state) => {
          const emailOptions = formProps.value.emails.map((x) => ({
            text: x,
            value: x,
          }));

          return (
            <Modal.Add
              {...modalProps}
              header={`Share File: ${file.name}`}
              className={classNames(
                "share-modal",
                linkToken ? "displaying-link" : ""
              )}
              submitting={submitting}
              slowSubmitting={submitting}
              cancelLabel={linkToken ? "Close" : "Cancel"}
            >
              {linkToken ? (
                <div>
                  <p>
                    This file is now available for share for{" "}
                    {formProps.value.daysDuration}{" "}
                    {formProps.value.daysDuration == 1 ? "day" : "days"} at the
                    following link.
                  </p>
                  <CopyBox content={shareUrl} label="Copy Link" />
                </div>
              ) : (
                <Form {...formProps}>
                  <div className="radio-list">
                    <Form.RadioGroup
                      field="shareType"
                      label={`How do you want to share ${file.name}?`}
                      options={shareOptions}
                      inline
                      required
                      requiredError="Share type is required"
                    />
                  </div>

                  <br />

                  <Form.Numeric
                    field="daysDuration"
                    label="Days duration"
                    maxValue={30}
                    minValue={1}
                    placeholder="Enter days duration"
                    required
                  />

                  <br />

                  <Form.If
                    condition={({ fields }) => fields.shareType == "Email"}
                  >
                    <div className="email-input">
                      <Form.Dropdown
                        field="emails"
                        label="Email Addresses"
                        placeholder="Enter email"
                        required
                        search
                        fluid
                        multiple
                        allowAdditions
                        options={emailOptions}
                        customErrors={
                          invalidEmail ? ["Invalid email address"] : null
                        }
                        showErrors={invalidEmail}
                        onAddItem={(e, { value }) => {
                          const email = value.trim().toLowerCase();

                          if (
                            email &&
                            formProps.value.emails.every((e) => e != email)
                          ) {
                            if (email.includes("@")) {
                              const items = [...formProps.value.emails, email];
                              formProps.onChange("emails", items, () => ({
                                ...formProps.value,
                                emails: items,
                              }));
                              setInvalidEmail(false);
                            } else {
                              formProps.onChange(
                                "emails",
                                formProps.value.emails,
                                () => formProps.value
                              );
                              setInvalidEmail(true);
                            }
                          }
                        }}
                      />
                    </div>
                  </Form.If>
                </Form>
              )}
            </Modal.Add>
          );
        }}
      />
    </React.Fragment>
  );
};

export default ShareModal;
