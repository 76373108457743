import { apiGet, registerLookup } from "@redriver/cinnamon";

const NAMESPACE = "ORGANISATIONS/ORGANISATION_LOOKUP";

const ActionTypes = {
  GetDropdownOrganisations: `${NAMESPACE}/GET_DROPDOWN_ORGANISATIONS`,
};

export const dropdownOrganisationsLookup = registerLookup(
  "DropdownOrganisationsLookup",
  ({ search }) =>
    apiGet(ActionTypes.GetDropdownOrganisations, `organisations/lookup`, {
      search,
    }),
  {
    cacheExpiry: 10000,
    transform: (data) =>
      Array.isArray(data)
        ? data.map((x) => ({ value: x.id, text: x.name }))
        : [],
  }
);
