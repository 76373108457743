import React from "react";
import { Table } from "@redriver/cinnamon";
import classNames from "classnames";

const TableActionMenu = ({
  className,
  hidden,
  children,
  itemIndex,
  visibleRowCount,
  ...props
}) => {
  let isNearBottom = false;
  if (typeof itemIndex === "number" && typeof visibleRowCount === "number") {
    isNearBottom = itemIndex > visibleRowCount - 3;
  }

  return (
    <Table.Menu
      className={classNames(
        "table-actions",
        hidden ? "hidden" : null,
        isNearBottom ? "flipped" : null,
        className
      )}
      {...props}
    >
      {children}
    </Table.Menu>
  );
};

export default TableActionMenu;
