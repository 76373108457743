import React from "react";
import { MaplesPage } from "components/pages";
import { NotFound } from "@redriver/cinnamon";

const NotFoundPage = () => (
  <MaplesPage>
    <NotFound />
  </MaplesPage>
);

export default NotFoundPage;
