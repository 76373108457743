import React from "react";
import { MaplesPage } from "components/pages";
import { BinContent } from "features/Bin/BinContent";

const BinConentPage = () => {
  return (
    <MaplesPage headerItems={["Bin"]}>
      <BinContent />
    </MaplesPage>
  );
};

export default BinConentPage;
