import React from "react";
import {
  getAuthClaim,
  ActionMenu,
  useAuthClaims,
  Format,
} from "@redriver/cinnamon";
import { Icon } from "semantic-ui-react";
import { AppRoutes, SystemRoutes } from "constants/routes";
import { Avatar } from "components/misc";
import { getInitials } from "modules/helpers";
import moment from "moment";

const ProfileControl = () => {
  const { userName } = useAuthClaims();

  const initials = getInitials(userName);

  return (
    <ActionMenu
      className="profile-control"
      trigger={
        <React.Fragment>
          <div className="details">
            <span className="welcome">Welcome</span>
            <span className="name">{userName}</span>
            <span className="welcome">
              <Format.Date value={moment().local()} format="DD MMM YYYY" />
            </span>
          </div>
          <Avatar initials={initials} />
          <Icon name="caret down" />
        </React.Fragment>
      }
    >
      <ActionMenu.Item text="My Account" link={SystemRoutes.Account} />
      <ActionMenu.Item
        text="Change Password"
        link={SystemRoutes.ChangePassword}
      />
      <ActionMenu.Item text="Sign Out" link={SystemRoutes.Logout} />
    </ActionMenu>
  );
};

export default ProfileControl;
