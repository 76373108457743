import React from "react";
import { MaplesPage } from "components/pages";
import { ListGroups } from "features/Groups/ListGroups";

const GroupsListPage = () => {
  return (
    <MaplesPage headerItems={["Groups"]}>
      <ListGroups />
    </MaplesPage>
  );
};

export default GroupsListPage;
