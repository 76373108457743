import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Label } from "semantic-ui-react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import { exportDownloadHistory } from "./actions";
import moment from "moment";
import { AuditType, AuditTypeLabels } from "constants/enums";
import FileSaver from "file-saver";

const auditTypes = Object.keys(AuditType).map((type) => ({
  value: AuditType[type],
  text: AuditTypeLabels[AuditType[type]],
}));

const DownloadDownloadHistory = ({ as: As = Button, ...props }) => {
  return (
    <ModalBuilder
      withForm
      submitAction={exportDownloadHistory}
      initialData={{
        datesUtc: {
          start: moment.utc().add(-1, "month").toISOString(),
          end: moment.utc().add(1, "day").toISOString(),
        },
      }}
      onSubmitted={(data) =>
        FileSaver.saveAs(
          new Blob([data], { type: "text/csv" }),
          `download-history.csv`
        )
      }
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps, state, events) => (
        <Modal
          {...modalProps}
          header="Export Download History"
          submitLabel="Export"
          size={"tiny"}
        >
          <Form {...formProps}>
            <Form.DateRange
              instant
              field="datesUtc"
              label="Dates"
              maxDate={moment.utc()}
              inclusiveEnd
              required
            />
            <Form.CheckboxGroup
              field="auditTypes"
              label="What to include?"
              options={auditTypes}
              required
            />
          </Form>
        </Modal>
      )}
    />
  );
};

export default DownloadDownloadHistory;
