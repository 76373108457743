import { apiGet, apiPut, apiDelete, apiPost } from "@redriver/cinnamon";

const NAMESPACE = "TAGINPUT";

const ActionTypes = {
  CheckTagEmailValid: `${NAMESPACE}/CHECK_TAG_EMAIL_VALID`,
  SearchUsers: `${NAMESPACE}/SEARCH_USERS`,
};

export const checkTagEmailValid = (email, userId = null) => {
  return apiGet(
    ActionTypes.CheckTagEmailValid,
    "users/valid-email",
    {
      email,
      userId,
    },
    {
      debounce: 300,
    }
  );
};

export const searchUsers = (search, other = {}) => {
  return apiGet(
    ActionTypes.SearchUsers,
    `users/available`,
    {
      pageSize: 10,
      pageNumber: 1,
      search,
      ...other,
    },
    {
      debounce: 100,
    }
  );
};
