import React from "react";
import { useDispatch } from "react-redux";
import { Table } from "@redriver/cinnamon";
import EditWorkspaceItem from "./EditWorkspaceItem";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import ActionsModal from "components/modals/ActionsModal";
import { deleteContent } from "./actions";
import ShareModal from "./ShareModal";
import ViewSharedLinks from "./ViewSharedLinks";
import MoveWorkspaceItems from "./MoveWorkspaceItems";
import { TableActionMenu } from "components/misc";
import {
  getWorkspaceDirectories,
  setWorkspaceDirectories,
} from "features/System/WorkspaceMenu/actions";
import { EntityType } from "constants/enums";

const WorkspaceItemActions = ({
  item,
  directoryId,
  siteRootDirectoryId,
  onRefresh,
  onDeleted,
  hidden,
  itemIndex,
  visibleRowCount,
}) => {
  const dispatch = useDispatch();

  return (
    <TableActionMenu
      hidden={hidden}
      itemIndex={itemIndex}
      visibleRowCount={visibleRowCount}
    >
      {!item.directory && (
        <ShareModal as={Table.MenuItem} file={item} onLinkShared={onRefresh}>
          Share
        </ShareModal>
      )}
      {!item.directory && item.hasActiveShares && (
        <ViewSharedLinks as={Table.MenuItem} file={item}>
          Shared Links
        </ViewSharedLinks>
      )}
      <PermissionCheck target={Targets.File} action={Actions.Edit}>
        <React.Fragment>
          <EditWorkspaceItem
            item={item}
            onUpdated={onRefresh}
            directoryId={directoryId}
            as={Table.MenuItem}
          >
            Edit
          </EditWorkspaceItem>
          <MoveWorkspaceItems
            directoryId={directoryId}
            siteRootDirectoryId={siteRootDirectoryId}
            selection={{
              selected: [
                JSON.stringify({
                  type: item.directory ? EntityType.Directory : EntityType.File,
                  id: item.id,
                }),
              ],
            }}
            as={Table.MenuItem}
            onClose={() => {
              onRefresh();
              dispatch(
                getWorkspaceDirectories(siteRootDirectoryId, {
                  pageSize: 15,
                  pageNumber: 1,
                })
              ).then((x) =>
                dispatch(
                  setWorkspaceDirectories(x, siteRootDirectoryId, 1, true)
                )
              );
            }}
          >
            Move
          </MoveWorkspaceItems>
        </React.Fragment>
      </PermissionCheck>

      <PermissionCheck target={Targets.File} action={Actions.Delete}>
        <ActionsModal
          as={Table.MenuItem}
          submitAction={deleteContent}
          name={item.name}
          actionName="delete"
          onRefresh={() => {
            onRefresh();
            onDeleted();
          }}
          header={`Delete ${item.directory ? "Folder" : "File"}`}
          submitParams={{
            selected: [
              JSON.stringify({
                type: item.directory ? EntityType.Directory : EntityType.File,
                id: item.id,
              }),
            ],
            directoryId,
          }}
        >
          Delete
        </ActionsModal>
      </PermissionCheck>
    </TableActionMenu>
  );
};

export default WorkspaceItemActions;
